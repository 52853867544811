// Layout.js

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom"; // Assuming you're using React Router

const Layout = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    const pageTitle = getPageTitle(path); // Get title based on path
    document.title = pageTitle;
  }, [location]);

  const getPageTitle = (path) => {
    if (path.startsWith("/EditUser/")) {
      const id = path.substring("/EditUser/".length); // Extract id from path
      return `Edit User`;
    }
    if (path.startsWith("/EditNail/")) {
      const id = path.substring("/EditNail/".length); // Extract id from path
      return `Edit Nail`;
    }
    if (path.startsWith("/EditMaterials/")) {
      const id = path.substring("/EditMaterials/".length); // Extract id from path
      return `Edit Materials`;
    }
    if (path.startsWith("/EditTile/")) {
      const id = path.substring("/EditTile/".length); // Extract id from path
      return `Edit Tile`;
    }
    switch (path) {
      case "/login":
        return "Login";
      case "/home":
        return "Home";
      case "/customerquotes":
        return "Customer Quotes";
      case "/register":
        return "Register";
      case "/passwordRecovery":
        return "Password Recovery";
      case "/verificationCode":
        return "Verification Code";
      case "/newPassword":
        return "New Password";
      case "/nails":
        return "Nails";
      case "/addNails":
        return "Add Nails";
      case "/contactus":
        return "Contact Us";
      case "/addMaterials":
        return "Add Materials";
      case "/materials":
        return "Materials";
      case "/settings":
        return "Settings";
      case "/addUser":
        return "Add User";
      case "/users":
        return "Users";
      case "/homes":
        return "Homes";
      case "/materialquotes":
        return "Material Quotes";
      case "/under-construction":
        return "Under Construction";
      case "/navbar":
        return "Navbar";
      case "/setyourrates":
        return "Set Your Rates";
      case "/createaquote":
        return "Create a Quote";
      case "/tiles":
        return "Tiles";
      case "/addTile":
        return "Add Tile";
      case "/manageaccount":
        return "Manage Account";
      case "/security":
        return "Security";
      case "/Admindashboard":
        return "Admin Dashboard";
      case "/tilesuggestion":
        return "Tile Suggestion";
      case path.startsWith("/EditUser/"):
        return "Edit User";

      default:
        return "RoofPal "; // Default title for unknown paths
    }
  };

  return <div>{children}</div>;
};

export default Layout;
