import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
const SideBar = (props) => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const handleLogout = () => {
    localStorage.removeItem("userData");
    setUserData(null);
    // Redirect to the login page
    navigate("/login");
  };
  useEffect(() => {
    // Retrieve data from local storage when the component mounts
    const storedUserData = localStorage.getItem("userData");

    if (storedUserData) {
      // Parse the JSON string to get back the original object
      const parsedUserData = JSON.parse(storedUserData);

      // Set the user data in the component state
      setUserData(parsedUserData);
    } else {
      console.log("No user data found in local storage.");
    }
  }, []);
  const handleLinkClick = (props) => {
    navigate("/under-construction");
  };
  console.log("props.isMobileMenuOpen3", props.isMobileMenuOpen);
  return (
    <div>
      <div
        id="layoutSidenav_nav"
        className={props.isMobileMenuOpen === true ? "showMenuMobile" : ""}
        // style={{
        //   width: "220px",
        //   "@media (max-width: 450px)": {
        //     width: "300px",
        //   },
        // }}
      >
        <nav
          className="sidenav shadow-right sidenav-light"
          style={{ width: "200px" }}
        >
          <div className="sidenav-menu">
            <div className="nav accordion" id="accordionSidenav">
              {/* Sidenav Heading (Addons)*/}{" "}
              <div className="sidenav-menu-heading">User Menu</div>
              {/* Sidenav Link (Charts)*/}
              <a
                className="nav-link"
                onClick={() => {
                  navigate("/home");
                }}
              >
                <div className="nav-link-icon">
                  <i data-feather="bar-chart" />
                </div>
                Home
              </a>
              <a
                className="nav-link"
                onClick={() => {
                  props.setMobileMenuOpen(false);
                  navigate("/setyourrates");
                }}
              >
                <div className="nav-link-icon">
                  <i data-feather="bar-chart" />
                </div>
                Set Your Rates
              </a>
              {/* Sidenav Link (Tables)*/}{" "}
              <a
                className="nav-link"
                onClick={() => {
                  localStorage.removeItem("savedQoutesSelected");
                  props.setMobileMenuOpen(false);
                  navigate("/createaquote");
                }}
              >
                <div className="nav-link-icon">
                  <i data-feather="filter" />
                </div>
                Create A Quote
              </a>{" "}
              <a
                className="nav-link"
                onClick={() => {
                  localStorage.removeItem("savedQoutesSelected");
                  props.setMobileMenuOpen(false);
                  navigate("/customerquotes");
                }}
              >
                <div className="nav-link-icon">
                  <i data-feather="filter" />
                </div>
                Customer Quotes
              </a>
              <a
                className="nav-link"
                onClick={() => {
                  props.setMobileMenuOpen(false);
                  navigate("/materialquotes");
                }}
              >
                <div className="nav-link-icon">
                  <i data-feather="filter" />
                </div>
                Material Quotes
              </a>
              {/* Sidenav Link (Tables)*/}{" "}
              <a
                className="nav-link"
                onClick={() => {
                  props.setMobileMenuOpen(false);
                  navigate("/manageaccount");
                }}
              >
                <div className="nav-link-icon">
                  <i data-feather="user" />
                </div>
                Manage Account
              </a>{" "}
              <a
                className="nav-link"
                onClick={() => {
                  props.setMobileMenuOpen(false);
                  navigate("/contactus");
                }}
              >
                <div className="nav-link-icon">
                  <i data-feather="user" />
                </div>
                Contact Us
              </a>
              <a
                className="nav-link"
                onClick={() => {
                  props.setMobileMenuOpen(false);
                  navigate("/tilesuggestion");
                }}
              >
                <div className="nav-link-icon">
                  <i data-feather="user" />
                </div>
                Tile Suggestion
              </a>{" "}
              {userData && userData.role === "administrator" && (
                <>
                  <div className="sidenav-menu-heading">Admin</div>
                  <a
                    className="nav-link"
                    onClick={() => {
                      props.setMobileMenuOpen(false);
                      navigate("/Admindashboard");
                    }}
                  >
                    <div className="nav-link-icon"></div>
                    Home
                  </a>
                  <a
                    className="nav-link"
                    onClick={() => {
                      props.setMobileMenuOpen(false);
                      navigate("/users");
                    }}
                  >
                    <div className="nav-link-icon">
                      <i data-feather="user" />
                    </div>
                    Users
                  </a>
                  <a
                    className="nav-link"
                    onClick={() => {
                      props.setMobileMenuOpen(false);
                      navigate("/tiles");
                    }}
                  >
                    <div className="nav-link-icon">
                      <i data-feather="user" />
                    </div>
                    Tiles
                  </a>{" "}
                  <a
                    className="nav-link"
                    onClick={() => {
                      props.setMobileMenuOpen(false);
                      navigate("/materials");
                    }}
                  >
                    <div className="nav-link-icon">
                      <i data-feather="user" />
                    </div>
                    Materials
                  </a>{" "}
                  <a
                    className="nav-link"
                    onClick={() => {
                      props.setMobileMenuOpen(false);
                      navigate("/nails");
                    }}
                  >
                    <div className="nav-link-icon">
                      <i data-feather="user" />
                    </div>
                    Nails
                  </a>
                  <a
                    className="nav-link"
                    onClick={() => {
                      props.setMobileMenuOpen(false);
                      navigate("/settings");
                    }}
                  >
                    <div className="nav-link-icon">
                      <i data-feather="user" />
                    </div>
                    Settings
                  </a>
                </>
              )}
            </div>
          </div>
          {/* Sidenav Footer*/}
          <div className="sidenav-footer">
            <div className="sidenav-footer-content">
              <div className="sidenav-footer-subtitle">
                Logged in as:
                <div className="sidenav-footer-title">
                  {userData && (
                    <>
                      <b>
                        <span>{userData.firstName}</span>{" "}
                        <span>{userData.lastName}</span>
                      </b>
                    </>
                  )}
                </div>
              </div>
              <a className="btn btn-primary p-2" onClick={handleLogout}>
                Log out
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default SideBar;
