import "../PagesCss/Default.css";
import React, { useState } from "react";
import favicon from "../assets/img/favicon.png";
import axios from "axios";
import { useNavigate } from "react-router";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
const AddNails = (props) => {
  const [formData, setFormData] = useState({
    nailSize: "",
    costPerUnit: "",
  });
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form data:", formData);

    try {
      // Make a POST request to your backend API
      // const response = await axios.post('http://localhost:8801/api/nails/add', formData);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/nails/add`,
        formData
      );
      setSuccessMessage("Data submitted successfully!");
      // Handle the response, e.g., show a success message
      console.log("Server response:", response.data);

      // Optionally, you can reset the form data after a successful submission
      setFormData({
        nailSize: "",
        costPerUnit: "",
      });
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error("Error submitting data:", error.message);
    }
  };
  const currentYear = new Date().getFullYear();
  return (
    <>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="description" content="" />
      <meta name="author" content="" />
      <title>Add Nails</title>
      <link
        href="https://cdn.jsdelivr.net/npm/simple-datatables@latest/dist/style.css"
        rel="stylesheet"
      />
      <link href="css/styles.css" rel="stylesheet" />
      <link rel="icon" type="image/x-icon" href="assets/img/favicon.png" />
      <Navbar toggleMobileMenu={props.toggleMobileMenu} />
      <div id="layoutSidenav">
        <SideBar
          setMobileMenuOpen={props.setMobileMenuOpen}
          isMobileMenuOpen={props.isMobileMenuOpen}
        />
        <div id="layoutSidenav_content">
          <main>
            <header className="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
              <div className="container-xl px-4">
                <div className="page-header-content">
                  <div className="row align-items-center justify-content-between pt-3">
                    <div className="col-auto mb-3">
                      <h1 className="page-header-title">
                        <div className="page-header-icon">
                          <i data-feather="user" />
                        </div>
                        Add Nail&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;{" "}
                      </h1>
                    </div>
                    <div className="col-12 col-xl-auto mb-3">
                      <a
                        onClick={() => {
                          navigate("/nails");
                        }}
                        className="btn btn-sm btn-light text-primary"
                      >
                        <i className="me-1" data-feather="arrow-left" />
                        Back to Nails
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </header>

            {/* Main page content*/}
            <div className="container-xl px-4 mt-4">
              {/* Success message */}
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              <div className="row">
                <div className="col-xl-12">
                  {/* Account details card*/}
                  <div className="card mb-4">
                    <div className="card-header">Nail Details</div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        {/* Form Row*/}
                        <div className="row gx-3 mb-3">
                          {/* Form Group (first name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              Nail size
                            </label>
                            <input
                              className="form-control"
                              id="nailSize"
                              type="number"
                              placeholder="Nail size"
                              value={formData.nailSize}
                              onChange={handleChange}
                            />
                          </div>
                          {/* Form Group (last name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputLastName"
                            >
                              Cost Per Unit (per nail)
                            </label>
                            <input
                              className="form-control"
                              id="costPerUnit"
                              type="number"
                              placeholder="Enter cost per unit"
                              value={formData.costPerUnit}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        {/* Submit button*/}
                        <button className="btn btn-primary" type="submit">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <footer className="footer-admin mt-auto footer-light">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">
                  Copyright © Roof Pal {currentYear}
                </div>
                <div className="col-md-6 text-md-end small"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default AddNails;
