import "../PagesCss/Default.css";
import React, { useState, useEffect } from "react";
import favicon from "../assets/img/favicon.png";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import MaterialTable from "material-table";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { ThemeProvider, createTheme } from "@mui/material";
import { Margin } from "@mui/icons-material";
//  import "../js/scripts";
// import "../js/datatables/datatables-simple-demo";

const MaterialQoute = (props) => {
  const handleDelete = async (quotedata) => {
    try {
      // Assuming you have the correct endpoint for deleting data
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/qoute/deleteQoutationbyId/${quotedata.quoteId}`
      );

      console.log("Delete Response:", response);
      NotificationManager.success("Material deleted successfully", "Success");
      fetchData();
      // setTimeout(() => {
      //   // eslint-disable-next-line no-restricted-globals
      //   window.location.reload();
      // }, 2000);
      // You can handle the response as needed
      // For example, you can show a success message or update the table data

      // Fetch updated data after deletion
    } catch (error) {
      console.error("Error deleting data:", error.message);
      // You can handle errors, for example, display an error message to the user
    }
  };
  const defaultMaterialTheme = createTheme();
  // const [formData, setFormData] = useState({
  //     currentpassword: '',
  //     newpassword  : '',
  //     confirmpassword:'',

  //   });
  //   const handleChange = (e) => {
  //     const { id, value } = e.target;
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       [id]: value,
  //     }));
  //   };
  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     console.log('Form data:', formData);
  //   };
  const [supplier, setsupplier] = useState([]);
  const [qoute, setQoutes] = useState([]);
  const [qouteId, setQouteId] = useState(0);

  // console.log("UserSaad", JSON.parse(localStorage.getItem("userData")).userId);
  const columns = [
    // {
    //   title: "Supplier Name",
    //   cellStyle: { padding: "0px 10px" },
    //   field: "nameofSupplier",
    // },
    {
      title: "Quote #",
      field: "quoteId",
      headerStyle: { width: "10px" },
      cellStyle: { width: "10px" },
    },
    {
      title: "Client Address",
      cellStyle: { padding: "0px 10px" },
      field: "address",
    },
    {
      title: "Actions",
      field: "actions",
      render: (rowData) => (
        <div>
          <button
            id="viewquotebtnmat"
            onClick={() => {
              console.log("rowdata1212", rowData, rowData.tableData.id);
              const base = "Materials_Quotation.pdf?quoteid=" + rowData.quoteId;
              const jasper_username = process.env.REACT_APP_JASPER_USERNAME;
              const jasper_password = process.env.REACT_APP_JASPER_PASSWORD;
              const jasper_url = process.env.REACT_APP_JASPER_URL;
              console.log("JasperURL: ", jasper_url);

              return new Promise((resolve, reject) => {
                axios
                  .get(`${jasper_url}${base}`, {
                    responseType: "blob",
                    headers: {
                      Accept: "application/pdf",
                    },
                    auth: {
                      username: jasper_username,
                      password: jasper_password,
                    },
                  })
                  .then((response) => {
                    resolve(response);
                    const blob = new Blob([response.data], {
                      type: "application/pdf",
                    });
                    const objectUrl = window.URL.createObjectURL(blob);
                    var link = document.createElement("a");
                    link.href = objectUrl;
                    link.download = `materialQuote ${rowData.quoteId}.pdf`;
                    link.click();
                    setTimeout(function () {
                      window.URL.revokeObjectURL(objectUrl);
                    }, 100);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              });
            }}
            className="btn btn-primary p-2 mr-2"
            style={{ marginRight: "10px" }}
          >
            View Quotes
          </button>

          <button
            id="deletequotebtnmat"
            onClick={() => handleDelete(rowData)}
            className="btn btn-danger p-2 mr-2"
            style={{ marginLeft: "10px" }}
          >
            Delete Quotes
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/qoute/getSupplier`
      );

      // setsupplier(response.data);
      if (JSON.parse(localStorage.getItem("userData"))) {
        console.log(
          "cl55522",
          response.data,
          JSON.parse(localStorage.getItem("userData")).userId
        );
        const arr = response.data.filter(
          (a) => a.userId == JSON.parse(localStorage.getItem("userData")).userId
        );
        setsupplier(arr);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      NotificationManager.info("Error fetching  data.Contact Administrator.");
    }
    // try {
    //   const response = await axios.get(
    //     `${process.env.REACT_APP_BACKEND_URL}/api/qoute/getQoutes`
    //   );
    //   console.log("Qoutes3", response.data);
    //   setQoutes(response.data);
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    //   // NotificationManager.info("Error fetching rate data:", error);
    // }
  };
  const currentYear = new Date().getFullYear();
  return (
    <>
      <Navbar toggleMobileMenu={props.toggleMobileMenu} />
      <div id="layoutSidenav">
        <SideBar
          setMobileMenuOpen={props.setMobileMenuOpen}
          isMobileMenuOpen={props.isMobileMenuOpen}
        />

        <div id="layoutSidenav_content">
          <main>
            <header className="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
              <div className="container-xl px-4">
                <div className="page-header-content">
                  <div className="row align-items-center justify-content-between pt-3">
                    <div className="col-auto mb-3">
                      <h1 className="page-header-title">
                        <div className="page-header-icon">
                          <i data-feather="user" />
                        </div>
                        Material Quotes&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                      </h1>
                    </div>
                    <div className="col-12 col-xl-auto mb-3"></div>
                  </div>
                </div>
              </div>
            </header>
            {/* Main page content*/}
            {/* Main page content*/}
            <div className="container-xl px-4 mt-4">
              <div className="card">
                {/* <div className="card-body"> */}
                <ThemeProvider theme={defaultMaterialTheme}>
                  <MaterialTable
                    title="Material Quotes"
                    columns={columns}
                    data={supplier}
                    options={{
                      search: true,
                      sorting: true,
                      paging: true,
                      actionsColumnIndex: -1,
                    }}
                    // actions={[
                    //   {
                    //     icon: () => <Edit />,
                    //     tooltip: "Edit/Display",
                    //     onClick: (event, rowData) => {
                    //       console.log("MaterialTable Row Data:", rowData);
                    //       // Handle edit action
                    //       handleEdit(event, rowData);
                    //     },
                    //   },
                    // ]}
                    // editable={{
                    //   onRowDelete: (oldData) =>
                    //     new Promise((resolve) => {
                    //       // Display a confirmation dialog
                    //       // const shouldDelete = window.confirm('Are you sure you want to delete this nail?');

                    //       // Handle delete action
                    //       handleDelete(oldData);
                    //       resolve();
                    //     }),
                    // }}
                  />
                </ThemeProvider>
              </div>
              {/* </div> */}
            </div>
          </main>
          <footer className="footer-admin mt-auto footer-light">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">
                  Copyright © Roof Pal {currentYear}
                </div>
                <div className="col-md-6 text-md-end small"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete Quote
              </h5>
              <button
                className="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              Are you sure you wish to delete this quote?
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary"
                type="button"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button className="btn btn-danger" type="button">
                Delete Quote
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MaterialQoute;
