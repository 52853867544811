import "../PagesCss/Default.css";
import axios from "axios";
import "../dropdownoptions";
import Navbar from "./Navbar";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import React, { useState, useEffect } from "react";
import favicon from "../assets/img/favicon.png";
import SideBar from "./SideBar";
let tilesData = [];
let insertId = 0;
let zeroAcceptable = false;
const Quote = (props) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [clientView, setClientView] = useState(false);
  const [supplierView, setSupplierView] = useState(false);
  const [clientSend, setClientSend] = useState(false);
  const [supplierSend, setSupplierSend] = useState(false);
  const [rates, setRates] = useState({});
  const [nailsmm, setNailsmm] = useState([]);
  const [materialRates, setMaterialRates] = useState([]);
  const [tileValues, setTileValues] = useState([]);
  const [calculatedOnce, setCalculatedOnce] = useState(false);
  const [formData, setFormData] = useState({
    client: {},
    job: {
      leftHandVergeTileRequired: 0,
      ridgeDryFix: 0,
      valleyDryFix: 0,
      vergeDryFix: 0,
      hipsDryFix: 0,
      valleyTilesHips: 0,
      valleyTilesValleys: 0,
      hipBonnet: 0,
      workType: 1,
      userId: JSON.parse(localStorage.getItem("userData")).userId,
      tileTypeId: 1,
    },
  });
  console.log("formData,rates", formData.job.tileTypeId, formData, rates);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/qoute/tilesData/`
        );
        console.log("API Response:", response.data);
        //
        tilesData = response.data;
        if (tilesData.length !== 0) {
          setTileValues(
            tilesData.filter((a) => {
              return a.tileTypeId == 1;
            })
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        NotificationManager.error(
          "Error occured when fetching/saving data from database"
        );
        NotificationManager.info("Error fetching data");
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (localStorage.getItem("savedQoutesSelected")) {
      let clientComment;
      let supplierComment;
      const fetchData = async () => {
        const obj = { ...formData };
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/qoute/getQoutesbyId/${
              JSON.parse(localStorage.getItem("savedQoutesSelected")).quoteId
            }`
          );
          obj.job = response.data;
          obj.job.insertId = response.data.quoteId;
          obj.job.tileId = response.data.tileID;
          supplierComment = response.data.supplierComment;
          clientComment = response.data.clientComment;
          setTileValues(
            tilesData.filter((a) => {
              return a.tileTypeId == response.data.tileTypeId;
            })
          );
          console.log("r56", response.data);
        } catch (error) {
          console.error("Error fetching data:", error);
          NotificationManager.error(
            "Error occured when fetching/saving data from database"
          );
          // NotificationManager.info("Error fetching rate data:", error);
        }
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/qoute/getClientbyId/${
              JSON.parse(localStorage.getItem("savedQoutesSelected")).clientId
            }`
          );

          obj.client = response.data;
          obj.client.supplierComment = supplierComment;
          obj.client.clientComment = clientComment;
          console.log("r57", response.data);
        } catch (error) {
          console.error("Error fetching data:", error);
          NotificationManager.error(
            "Error occured when fetching/saving data from database"
          );
          // NotificationManager.info("Error fetching rate data:", error);
        }
        setFormData(obj);
      };

      fetchData();
    }
  }, []);
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      obj.job.matleftHandVergeCost = (
        obj.job.matleftHandVergeActReq *
        tilesData.filter((a) => a.tileId === obj.job.tileId)[0]
          .leftHandedTileCost
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId3(obj);

      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matleftHandVergeActReq]);
  // 2
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      obj.job.matslateCost = (
        obj.job.matSlateActReq *
        tilesData.filter((a) => a.tileId === obj.job.tileId)[0].costPerTile
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);

      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matSlateActReq]);
  // 3
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      obj.job.mateaveFeltCost = (
        obj.job.mateaveFeltActReq *
        materialRates.filter((a) => a.materialName === "Eave Felt")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.mateaveFeltActReq]);
  // 4
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      obj.job.eaveCombFillerCost = (
        materialRates.filter((a) => a.materialName === "Eave Comb Filler")[0]
          .costPerUnit * formData.job.eaveCombFillerActReq
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.eaveCombFillerActReq]);
  // 5
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      obj.job.matroofingMembraneCost = (
        obj.job.matroofingMembraneActReq *
        materialRates.filter((a) => a.materialName === "Roofing Membrane")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matroofingMembraneActReq]);
  // 6
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      obj.job.matbattenCost = (
        obj.job.matbattenActReq *
        materialRates.filter(
          (a) => a.materialName === "25 x 50mm Batten (big & slate)"
        )[0].costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matbattenActReq]);
  // 7
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      obj.job.matbattenNailsCost = (
        obj.job.matbattenNailsActReq *
        materialRates.filter((a) => a.materialName === "Batten Nails")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matbattenNailsActReq]);
  // 8
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      let mmmValue = tilesData.filter(
        (a) => a.tileId === formData.job.tileId
      )[0].nailSize;
      obj.job.matslateTileNailsCost = Number(
        nailsmm.filter((a) => a.nailSize == mmmValue.replace("mm", ""))[0]
          .costPerUnit * Number(obj.job.matslateTileNailsActReq)
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matslateTileNailsActReq]);
  // 9
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      obj.job.matleadFlashingsCost = (
        materialRates.filter((a) => a.materialName === "Lead Flashings")[0]
          .costPerUnit * obj.job.matleadFlashingsActReq
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matleadFlashingsActReq]);
  // 10
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      obj.job.matleadValleysCost = (
        materialRates.filter((a) => a.materialName === "Lead Valleys")[0]
          .costPerUnit * obj.job.matleadValleysActReq
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matleadValleysActReq]);
  // 11
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      obj.job.matdryValleyCost = Number(
        materialRates.filter((a) => a.materialName === "GRP Valley Tray")[0]
          .costPerUnit * obj.job.matdryValleyActReq
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matdryValleyActReq]);
  // 12
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      if (
        tilesData.filter((a) => a.tileId == obj.job.tileId)[0].ridgeRequired ==
        "Concrete"
      ) {
        obj.job.matridgeCost = (
          obj.job.matridgeMaterialActReq *
          materialRates.filter((a) => a.materialName == "Concrete Ridge")[0]
            .costPerUnit
        ).toFixed(2);
      } else {
        obj.job.matridgeCost = (
          obj.job.matridgeMaterialActReq *
          materialRates.filter((a) => a.materialName == "Clay Ridge")[0]
            .costPerUnit
        ).toFixed(2);
      }
      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matridgeMaterialActReq]);
  // 13
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      if (
        tilesData.filter((a) => a.tileId === obj.job.tileId)[0]
          .ridgeRequired === "Concrete"
      ) {
        obj.job.mathipsCost = (
          obj.job.mathipsMaterialActReq *
          materialRates.filter((a) => a.materialName === "Concrete Ridge")[0]
            .costPerUnit
        ).toFixed(2);
      } else {
        obj.job.mathipsCost = (
          obj.job.mathipsMaterialActReq *
          materialRates.filter((a) => a.materialName === "Clay Ridge")[0]
            .costPerUnit
        ).toFixed(2);
      }
      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.mathipsMaterialActReq]);
  // 14
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      obj.job.matsandCost = (
        obj.job.matsandActReq *
        materialRates.filter((a) => a.materialName === "Sand")[0].costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matsandActReq]);
  // 15
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      obj.job.matcementCost = (
        obj.job.matcementActReq *
        materialRates.filter((a) => a.materialName === "Cement")[0].costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matcementActReq]);
  // 16
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      obj.job.matSealantsCost = (
        obj.job.matSealantsActReq *
        materialRates.filter((a) => a.materialName === "Sealants")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matSealantsActReq]);
  // 17
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      obj.job.matdryRidgeKitCost = (
        obj.job.matdryRidgeKitActReq *
        materialRates.filter((a) => a.materialName === "Dry Ridge Kit")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matdryRidgeKitActReq]);
  // 18
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      obj.job.matHipSupportTraysCost = (
        obj.job.matHipSupportTraysActReq *
        materialRates.filter((a) => a.materialName === "HIP Supoort Tray")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matHipSupportTraysActReq]);
  // 19
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      obj.job.matUnderCloakCost = (
        obj.job.matUnderCloakActReq *
        materialRates.filter(
          (a) => a.materialName === "Cement Fibre Under Cloak"
        )[0].costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matUnderCloakActReq]);
  // 20
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      obj.job.matventTilescostCost = (
        obj.job.matventTilesMaterialActReq *
        materialRates.filter((a) => a.materialName === "Vent Tile")[0]
          .costPerUnit
      ).toFixed(2);

      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matventTilesMaterialActReq]);
  // 21
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      obj.job.matleadSlatecost = (
        obj.job.matleadslateActReq *
        materialRates.filter((a) => a.materialName === "Lead Saddle")[0]
          .costPerUnit
      ).toFixed(2);

      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matleadslateActReq]);
  // 22
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      obj.job.matboxGutterLeadCost = (
        obj.job.matboxGutterLeadActReq *
        materialRates.filter((a) => a.materialName == "Box Gutter Lead")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matboxGutterLeadActReq]);
  // 23
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 3) {
      const obj = { ...formData };
      obj.job.matdryVergeCost = (
        Number(obj.job.matdryVergeActReq) *
        Number(
          materialRates.filter(
            (a) => a.materialName === "Dry Verge Unit (Tile Only)"
          )[0].costPerUnit
        )
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId3(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matdryVergeActReq]);
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };

      obj.job.matslatenHalfCost = (
        obj.job.matslatenHalfActReq *
        tilesData.filter((a) => a.tileId === obj.job.tileId)[0].costPerTilenHalf
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matslatenHalfActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.matslateCost = (
        obj.job.matSlateActReq *
        tilesData.filter((a) => a.tileId === obj.job.tileId)[0].costPerTile
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matSlateActReq]);
  if (materialRates.filter((a) => a.materialName === "Eave Felt")[0]) {
    console.log(
      "c213",
      typeof materialRates.filter((a) => a.materialName === "Eave Felt")[0]
        .costPerUnit
    );
  }
  const handleKeyDown = (event) => {
    // Prevent backspace key
    if (event.key === "Backspace") {
      event.preventDefault();
    }
  };
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.mateaveFeltCost = (
        obj.job.mateaveFeltActReq *
        materialRates.filter((a) => a.materialName === "Eave Felt")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.mateaveFeltActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.matbattenCost = (
        obj.job.matbattenActReq *
        materialRates.filter(
          (a) => a.materialName === "25 x 38mm Batten (Small tile)"
        )[0].costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matbattenActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.matroofingMembraneCost = (
        obj.job.matroofingMembraneActReq *
        materialRates.filter((a) => a.materialName === "Roofing Membrane")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matroofingMembraneActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.matbattenNailsCost = (
        obj.job.matbattenNailsActReq *
        materialRates.filter((a) => a.materialName === "Batten Nails")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matbattenNailsActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      let mmmValue = tilesData.filter(
        (a) => a.tileId === formData.job.tileId
      )[0].nailSize;
      if (nailsmm.filter((a) => a.nailSize == mmmValue.replace("mm", ""))[0]) {
        obj.job.matslateTileNailsCost = Number(
          nailsmm.filter((a) => a.nailSize == mmmValue.replace("mm", ""))[0]
            .costPerUnit * Number(obj.job.matslateTileNailsActReq)
        ).toFixed(2);
      }
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matslateTileNailsActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.matleadFlashingsCost = (
        materialRates.filter((a) => a.materialName === "Roofing Membrane")[0]
          .costPerUnit * obj.job.matleadFlashingsActReq
      ).toFixed(2);
      formData.job.dryValleythreeM = 0;
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matleadFlashingsActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.matleadValleysCost = (
        materialRates.filter((a) => a.materialName === "Lead Valleys")[0]
          .costPerUnit * obj.job.matleadValleysActReq
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matleadValleysActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.matdryValleyCost = Number(
        materialRates.filter((a) => a.materialName === "GRP Valley Tray")[0]
          .costPerUnit * obj.job.matdryValleyActReq
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matdryValleyActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.matValleyTilesValleysCost = (
        tilesData.filter((a) => a.tileId === obj.job.tileId)[0]
          .costPerValleyTile * obj.job.matValleyTilesValleysActReq
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matValleyTilesValleysActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      if (
        tilesData.filter((a) => a.tileId == obj.job.tileId)[0].ridgeRequired ==
        "Concrete"
      ) {
        obj.job.matridgeCost = (
          obj.job.matridgeMaterialActReq *
          materialRates.filter((a) => a.materialName == "Concrete Ridge")[0]
            .costPerUnit
        ).toFixed(2);
      } else {
        obj.job.matridgeCost = (
          obj.job.matridgeMaterialActReq *
          materialRates.filter((a) => a.materialName == "Clay Ridge")[0]
            .costPerUnit
        ).toFixed(2);
      }

      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matridgeMaterialActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      if (
        tilesData.filter((a) => a.tileId === obj.job.tileId)[0]
          .ridgeRequired === "Concrete"
      ) {
        obj.job.mathipsCost = (
          obj.job.mathipsMaterialActReq *
          materialRates.filter((a) => a.materialName === "Concrete Ridge")[0]
            .costPerUnit
        ).toFixed(2);
      } else {
        obj.job.mathipsCost = (
          obj.job.mathipsMaterialActReq *
          materialRates.filter((a) => a.materialName === "Clay Ridge")[0]
            .costPerUnit
        ).toFixed(2);
      }
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.mathipsMaterialActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.matBonnetsCost = (
        obj.job.matBonnetsActReq *
        tilesData.filter((a) => a.tileId === obj.job.tileId)[0].costPerBonnet
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matBonnetsActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.matsandCost = (
        obj.job.matsandActReq *
        materialRates.filter((a) => a.materialName === "Sand")[0].costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matsandActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.matcementCost = (
        obj.job.matcementActReq *
        materialRates.filter((a) => a.materialName === "Cement")[0].costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matcementActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.matSealantsCost = (
        obj.job.matSealantsActReq *
        materialRates.filter((a) => a.materialName === "Sealants")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matSealantsActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.matdryRidgeKitCost = (
        obj.job.matdryRidgeKitActReq *
        materialRates.filter((a) => a.materialName === "Dry Ridge Kit")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matdryRidgeKitActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.matHipSupportTraysCost =
        obj.job.matHipSupportTraysActReq *
        materialRates
          .filter((a) => a.materialName === "HIP Supoort Tray")[0]
          .costPerUnit.toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matHipSupportTraysActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.matUnderCloakCost = (
        obj.job.matUnderCloakActReq *
        materialRates.filter(
          (a) => a.materialName === "Cement Fibre Under Cloak"
        )[0].costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matUnderCloakActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.matdryVergeCost = (
        Number(obj.job.matdryVergeActReq) *
        Number(
          materialRates.filter(
            (a) => a.materialName === "Dry Verge Unit (Tile Only)"
          )[0].costPerUnit
        )
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matdryVergeActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.matEaveTilesCost = (
        tilesData.filter((a) => a.tileId === obj.job.tileId)[0]
          .costPerEaveTile * obj.job.matEaveTilesActReq
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matEaveTilesActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.matventTilescostCost = (
        obj.job.matventTilesMaterialActReq *
        materialRates.filter((a) => a.materialName === "Vent Tile")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matventTilesMaterialActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.matleadSlatecost = (
        obj.job.matleadSlateMaterialActReq *
        materialRates.filter((a) => a.materialName === "Lead Saddle")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matleadSlateMaterialActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 2) {
      const obj = { ...formData };
      obj.job.matboxGutterLeadCost = (
        obj.job.matboxGutterLeadActReq *
        materialRates.filter((a) => a.materialName == "Box Gutter Lead")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId2(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matboxGutterLeadActReq]);

  // useEffect(() => {
  //   console.log("zz2");
  //   if (calculatedOnce == true && formData.job.tileTypeId == 2) {
  //     setCalculatedOnce(false);
  //     const obj = { ...formData };
  //     console.log("zz1");
  //     formData.job.matslatenHalfCost = (
  //       formData.job.matslatenHalfActReq *
  //       tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
  //         .costPerTilenHalf
  //     ).toFixed(2);
  //     formData.job.matslateCost = (
  //       formData.job.matSlateActReq *
  //       tilesData.filter((a) => a.tileId === formData.job.tileId)[0].costPerTile
  //     ).toFixed(2);
  //     formData.job.mateaveFeltCost = (
  //       formData.job.mateaveFeltActReq *
  //       materialRates.filter((a) => a.materialName === "Eave Felt")[0]
  //         .costPerUnit
  //     ).toFixed(2);
  //     formData.job.matbattenCost = (
  //       formData.job.matbattenActReq *
  //       materialRates.filter(
  //         (a) => a.materialName === "25 x 38mm Batten (Small tile)"
  //       )[0].costPerUnit
  //     ).toFixed(2);
  //     formData.job.matroofingMembraneCost = (
  //       formData.job.matroofingMembraneActReq *
  //       materialRates.filter((a) => a.materialName === "Roofing Membrane")[0]
  //         .costPerUnit
  //     ).toFixed(2);
  //     setFormData(obj);
  //     formData.job.matbattenNailsCost = (
  //       formData.job.matbattenNailsActReq *
  //       materialRates.filter((a) => a.materialName === "Batten Nails")[0]
  //         .costPerUnit
  //     ).toFixed(2);
  //     let mmmValue = tilesData.filter(
  //       (a) => a.tileId === formData.job.tileId
  //     )[0].nailSize;
  //     if (nailsmm.filter((a) => a.nailSize == mmmValue.replace("mm", ""))[0]) {
  //       formData.job.matslateTileNailsCost = Number(
  //         nailsmm.filter((a) => a.nailSize == mmmValue.replace("mm", ""))[0]
  //           .costPerUnit * Number(formData.job.matslateTileNailsActReq)
  //       ).toFixed(2);
  //     }

  //     formData.job.matleadFlashingsCost =
  //       materialRates.filter((a) => a.materialName === "Roofing Membrane")[0]
  //         .costPerUnit * formData.job.matleadFlashingsActReq;
  //     formData.job.dryValleythreeM = 0;

  //     formData.job.matleadValleysCost = (
  //       materialRates.filter((a) => a.materialName === "Lead Valleys")[0]
  //         .costPerUnit * formData.job.matleadValleysActReq
  //     ).toFixed(2);
  //     formData.job.matdryValleyCost = Number(
  //       materialRates.filter((a) => a.materialName === "GRP Valley Tray")[0]
  //         .costPerUnit * formData.job.matdryValleyActReq
  //     ).toFixed(2);
  //     formData.job.matValleyTilesValleysCost = (
  //       tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
  //         .costPerValleyTile * formData.job.matValleyTilesValleysActReq
  //     ).toFixed(2);
  //     formData.job.matridgeCost = (
  //       formData.job.matridgeMaterialActReq *
  //       materialRates.filter((a) => a.materialName === "Concrete Ridge")[0]
  //         .costPerUnit
  //     ).toFixed(2);
  //     formData.job.mathipsCost = (
  //       formData.job.mathipsMaterialActReq *
  //       materialRates.filter((a) => a.materialName === "Concrete Ridge")[0]
  //         .costPerUnit
  //     ).toFixed(2);
  //     formData.job.matBonnetsCost = (
  //       formData.job.matBonnetsActReq *
  //       tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
  //         .costPerBonnet
  //     ).toFixed(2);
  //     formData.job.matsandCost = (
  //       formData.job.matsandActReq *
  //       materialRates.filter((a) => a.materialName === "Sand")[0].costPerUnit
  //     ).toFixed(2);
  //     formData.job.matcementCost = (
  //       formData.job.matcementActReq *
  //       materialRates.filter((a) => a.materialName === "Cement")[0].costPerUnit
  //     ).toFixed(2);
  //     formData.job.matSealantsCost = (
  //       formData.job.matSealantsActReq *
  //       materialRates.filter((a) => a.materialName === "Sealants")[0]
  //         .costPerUnit
  //     ).toFixed(2);
  //     formData.job.matdryRidgeKitCost =
  //       formData.job.matdryRidgeKitActReq *
  //       materialRates
  //         .filter((a) => a.materialName === "Dry Ridge Kit")[0]
  //         .costPerUnit.toFixed(2);
  //     formData.job.matHipSupportTraysCost =
  //       formData.job.matHipSupportTraysActReq *
  //       materialRates
  //         .filter((a) => a.materialName === "HIP Supoort Tray")[0]
  //         .costPerUnit.toFixed(2);
  //     formData.job.matUnderCloakCost = (
  //       formData.job.matUnderCloakActReq *
  //       materialRates.filter(
  //         (a) => a.materialName === "Cement Fibre Under Cloak"
  //       )[0].costPerUnit
  //     ).toFixed(2);
  //     formData.job.matdryVergeCost = (
  //       Number(formData.job.matdryVergeActReq) *
  //       Number(
  //         materialRates.filter(
  //           (a) => a.materialName === "Dry Verge Unit (Tile Only)"
  //         )[0].costPerUnit
  //       )
  //     ).toFixed(2);
  //     formData.job.matEaveTilesCost = (
  //       tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
  //         .costPerEaveTile * formData.job.matEaveTilesActReq
  //     ).toFixed(2);
  //     formData.job.matventTilescostCost = (
  //       formData.job.matventTilesMaterialActReq *
  //       materialRates.filter((a) => a.materialName === "Vent Tile")[0]
  //         .costPerUnit
  //     ).toFixed(2);
  //     formData.job.matleadSlatecost = (
  //       formData.job.matleadSlateMaterialActReq *
  //       materialRates.filter((a) => a.materialName === "Lead Saddle")[0]
  //         .costPerUnit
  //     ).toFixed(2);
  //     formData.job.matboxGutterLeadCost = (
  //       formData.job.matboxGutterLeadActReq *
  //       materialRates.filter((a) => a.materialName == "Box Gutter Lead")[0]
  //         .costPerUnit
  //     ).toFixed(2);

  //   }
  // }, [formData]);
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      let mmValue = tilesData.filter((a) => a.tileId == formData.job.tileId)[0]
        .nailSize;
      console.log(
        "matslateTileNailsCost32",
        nailsmm.filter((a) => a.nailSize == mmValue.replace("mm", ""))[0]
          .costPerUnit
      );
      obj.job.matslateTileNailsCost = (
        nailsmm.filter((a) => a.nailSize == mmValue.replace("mm", ""))[0]
          .costPerUnit * obj.job.matslateTileNailsActReq
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matslateTileNailsActReq]);
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      obj.job.matdryRidgeKitCost = (
        obj.job.matdryRidgeKitActReq *
        materialRates.filter((a) => a.materialName == "Dry Ridge Kit")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matdryRidgeKitActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      obj.job.matsandCost = (
        obj.job.matsandActReq *
        materialRates.filter((a) => a.materialName === "Sand")[0].costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matsandActReq]);
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      console.log(
        "Vent Tile",
        materialRates.filter((a) => a.materialName == "Vent Tile")[0]
          .costPerUnit
      );
      const obj = { ...formData };
      obj.job.matventTilescostCost = (
        obj.job.matventTilesMaterialActReq *
        materialRates.filter((a) => a.materialName == "Vent Tile")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matventTilesMaterialActReq]);
  // 2
  useEffect(() => {
    console.log("matslatenHalfActReq3");
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      console.log(
        "matslatenHalfActReq2",
        tilesData.filter((a) => a.tileId == obj.job.tileId)[0].costPerSlatenHalf
      );
      obj.job.matslatenHalfCost = (
        obj.job.matslatenHalfActReq *
        tilesData.filter((a) => a.tileId == obj.job.tileId)[0].costPerSlatenHalf
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matslatenHalfActReq]);
  function removeNaNValues(obj) {
    const newObj = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key) && !isNaN(obj[key])) {
        newObj[key] = obj[key];
      }
    }

    return newObj;
  }
  const saveInDB = async () => {
    formData.job = removeNaNValues(formData.job);
    formData.job.supplierComment = formData.client.supplierComment;
    formData.job.clientComment = formData.client.clientComment;
    formData.job.totalCost20PctPremiumVat = (
      formData.job.totalCost * 0.2
    ).toFixed(2);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/qoute/`,
        formData
      );
      // console.log("insertId543 :", response.data);
      insertId = response.data.insertId;
      formData.job.insertId = insertId;
      formData.client.clientId = response.data.clientId;
      setFormData(formData);
      NotificationManager.success("Data Saved In Database Successfully");
      setSelectedTab(3);
    } catch (error) {
      console.error("Error fetching data:", error);
      NotificationManager.error(
        "Error occured when fetching/saving data from database"
      );
    }
    setSelectedTab(4);
  };
  const sendQoutesViaEmail = async (link, type) => {
    let toEmail;
    if (type == "Client") {
      toEmail = formData.client.clientEmail;
    }
    if (type == "Supplier") {
      toEmail = JSON.parse(localStorage.getItem("userData")).supplieremail1;
    }
    const blobUrl = link.href;
    try {
      const blobResponse = await fetch(blobUrl);
      const blobData = await blobResponse.blob();
      const upload = new FormData();
      upload.append("file", blobData);
      upload.append(
        "email",
        JSON.stringify({
          email: toEmail,
          type: type,
          insertId: insertId,
        })
      );

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/qoute/sendQoutesViaEmail`,
        upload
      );
      NotificationManager.success(`Email Sending Successfull !!`);
      setClientSend(false);
      setSupplierSend(false);
      console.log("Response from backend:", response.data);
    } catch (error) {
      if (error.response) {
        setClientSend(false);
        setSupplierSend(false);
        NotificationManager.error(
          `Email Sending Failed!!! ${error.response.data}`
        );
      } else {
        NotificationManager.error(`Email Sending Failed!!! `);
      }
      console.error("Error sending email:", error);
    }
  };

  useEffect(() => {
    console.log("matSlateActReq");
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      console.log(
        "matSlateActReq2",
        tilesData.filter((a) => a.tileId == obj.job.tileId)[0]
      );
      obj.job.matslateCost = (
        obj.job.matSlateActReq *
        tilesData.filter((a) => a.tileId == obj.job.tileId)[0].costPerTile
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matSlateActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      obj.job.mateaveFeltCost = (
        obj.job.mateaveFeltActReq *
        materialRates.filter((a) => a.materialName == "Eave Felt")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.mateaveFeltActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      obj.job.matroofingMembraneCost = (
        obj.job.matroofingMembraneActReq *
        materialRates.filter((a) => a.materialName == "Roofing Membrane")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);

      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matroofingMembraneActReq]);

  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      formData.job.matbattenCost = (
        formData.job.matbattenActReq *
        materialRates.filter(
          (a) => a.materialName == "25 x 50mm Batten (big & slate)"
        )[0].costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matbattenActReq]);
  // 7
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      obj.job.matbattenNailsCost = (
        obj.job.matbattenNailsActReq *
        materialRates.filter((a) => a.materialName == "Batten Nails")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matbattenNailsActReq]);
  // 8
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      obj.job.matdiscRivetsCost = (
        obj.job.matdiscRivetsActReq *
        materialRates.filter((a) => a.materialName == "Disc Rivetts")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matdiscRivetsActReq]);
  // 9
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      obj.job.matleadFlashingsCost = (
        materialRates.filter((a) => a.materialName == "Lead Flashings")[0]
          .costPerUnit * obj.job.matleadFlashingsActReq
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matleadFlashingsActReq]);
  // 10
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      obj.job.matleadValleysCost = (
        materialRates.filter((a) => a.materialName == "Lead Valleys")[0]
          .costPerUnit * obj.job.matleadValleysActReq
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matleadValleysActReq]);
  // 11
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      console.log(
        "matdryValleyActReq234",
        materialRates.filter((a) => a.materialName == "GRP Valley Tray")[0]
          .costPerUnit
      );
      obj.job.matdryValleyCost = (
        materialRates.filter((a) => a.materialName == "GRP Valley Tray")[0]
          .costPerUnit * obj.job.matdryValleyActReq
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matdryValleyActReq]);
  // 12
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      obj.job.matleadSlatecost = (
        obj.job.matleadSlateMaterialActReq *
        materialRates.filter((a) => a.materialName == "Lead Saddle")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matleadSlateMaterialActReq]);
  // 13
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      obj.job.matridgeCost = (
        obj.job.matridgeMaterialActReq *
        materialRates.filter((a) => a.materialName == "Concrete Ridge")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matridgeMaterialActReq]);
  // 14
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      obj.job.mathipsCost = (
        obj.job.mathipsMaterialActReq *
        materialRates.filter((a) => a.materialName == "Concrete Ridge")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.mathipsMaterialActReq]);
  // 15
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      obj.job.matcementCost = (
        obj.job.matcementActReq *
        materialRates.filter((a) => a.materialName == "Cement")[0].costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matcementActReq]);
  // 16
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      obj.job.matSealantsCost = (
        obj.job.matSealantsActReq *
        materialRates.filter((a) => a.materialName == "Sealants")[0].costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matSealantsActReq]);
  // 17
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      obj.job.matdryVergeCost = (
        obj.job.matdryVergeActReq *
        materialRates.filter(
          (a) => a.materialName == "Dry Verge Unit (Slate Only)"
        )[0].costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matdryVergeActReq]);
  // 18
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      obj.job.matUnderCloakCost = (
        obj.job.matUnderCloakActReq *
        materialRates.filter(
          (a) => a.materialName == "Cement Fibre Under Cloak"
        )[0].costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matUnderCloakActReq]);
  // 19
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      obj.job.matHipSupportTraysCost = (
        obj.job.matHipSupportTraysActReq *
        materialRates.filter((a) => a.materialName == "HIP Supoort Tray")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matHipSupportTraysActReq]);
  // 20
  useEffect(() => {
    if (calculatedOnce == true && formData.job.tileTypeId == 1) {
      const obj = { ...formData };
      obj.job.matboxGutterLeadCost = (
        obj.job.matboxGutterLeadActReq *
        materialRates.filter((a) => a.materialName == "Box Gutter Lead")[0]
          .costPerUnit
      ).toFixed(2);
      obj.job.materialCost = materialCosttileTypeId1(obj);
      obj.job.totalCost = calculateTotalCost(obj);
      obj.job.totalCostWithVat = (Number(obj.job.totalCost) * 1.2).toFixed(2);
      setFormData(obj);
    }
  }, [formData.job.matboxGutterLeadActReq]);
  // 21

  const materialCosttileTypeId3 = (obj) => {
    let materialCost = (
      Number(formData.job.matslateCost) +
      Number(formData.job.matleftHandVergeCost) +
      Number(formData.job.mateaveFeltCost) +
      Number(formData.job.eaveCombFillerCost) +
      Number(formData.job.matroofingMembraneCost) +
      Number(formData.job.matbattenCost) +
      Number(formData.job.matbattenNailsCost) +
      Number(formData.job.matslateTileNailsCost) +
      Number(formData.job.matleadFlashingsCost) +
      Number(formData.job.matleadValleysCost) +
      Number(formData.job.matdryValleyCost) +
      Number(formData.job.matridgeCost) +
      Number(formData.job.mathipsCost) +
      Number(formData.job.matsandCost) +
      Number(formData.job.matcementCost) +
      Number(formData.job.matSealantsCost) +
      Number(formData.job.matdryRidgeKitCost) +
      Number(formData.job.matHipSupportTraysCost) +
      Number(formData.job.matUnderCloakCost) +
      Number(formData.job.matdryVergeCost) +
      Number(formData.job.matventTilescostCost) +
      Number(formData.job.matleadSlatecost) +
      Number(formData.job.matboxGutterLeadCost) +
      Number(formData.job.matEaveTilesCost)
    ).toFixed(2);
    return materialCost;
  };
  const materialCosttileTypeId1 = (obj) => {
    let materialCost = (
      parseFloat(formData.job.matslateCost) +
      parseFloat(formData.job.matslatenHalfCost) +
      parseFloat(formData.job.mateaveFeltCost) +
      parseFloat(formData.job.matroofingMembraneCost) +
      parseFloat(formData.job.matbattenCost) +
      parseFloat(formData.job.matbattenNailsCost) +
      parseFloat(formData.job.matslateTileNailsCost) +
      parseFloat(formData.job.matdiscRivetsCost) +
      parseFloat(formData.job.matleadFlashingsCost) +
      parseFloat(formData.job.matleadValleysCost) +
      parseFloat(formData.job.matdryValleyCost) +
      parseFloat(formData.job.matridgeCost) +
      parseFloat(formData.job.mathipsCost) +
      parseFloat(formData.job.matsandCost) +
      parseFloat(formData.job.matcementCost) +
      parseFloat(formData.job.matSealantsCost) +
      parseFloat(formData.job.matdryRidgeKitCost) +
      parseFloat(formData.job.matHipSupportTraysCost) +
      parseFloat(formData.job.matUnderCloakCost) +
      parseFloat(formData.job.matdryVergeCost) +
      parseFloat(formData.job.matventTilescostCost) +
      parseFloat(formData.job.matleadSlatecost)
    ).toFixed(2);

    return materialCost;
  };

  const calculateTotalCost = (obj) => {
    return (
      Number(obj.job.materialCost) +
      Number(obj.job.scaffoldCost) +
      Number(obj.job.wasteCost) +
      Number(obj.job.labourCost)
    ).toFixed(2);
  };
  const materialCosttileTypeId2 = (obj) => {
    let materialCost = (
      parseFloat(obj.job.matslateCost) +
      parseFloat(obj.job.matslatenHalfCost) +
      parseFloat(obj.job.mateaveFeltCost) +
      parseFloat(obj.job.matroofingMembraneCost) +
      parseFloat(obj.job.matbattenCost) +
      parseFloat(obj.job.matbattenNailsCost) +
      parseFloat(obj.job.matslateTileNailsCost) +
      // parseFloat(obj.job.matdiscRivetsCost) +
      parseFloat(obj.job.matleadFlashingsCost) +
      parseFloat(obj.job.matleadValleysCost) +
      parseFloat(obj.job.matdryValleyCost) +
      parseFloat(obj.job.matridgeCost) +
      parseFloat(obj.job.mathipsCost) +
      parseFloat(obj.job.matsandCost) +
      parseFloat(obj.job.matcementCost) +
      parseFloat(obj.job.matSealantsCost) +
      parseFloat(obj.job.matdryRidgeKitCost) +
      parseFloat(obj.job.matHipSupportTraysCost) +
      parseFloat(obj.job.matUnderCloakCost) +
      parseFloat(obj.job.matdryVergeCost) +
      parseFloat(obj.job.matventTilescostCost) +
      parseFloat(obj.job.matleadSlatecost) +
      parseFloat(obj.job.matBonnetsCost) +
      parseFloat(obj.job.matValleyTilesValleysCost) +
      parseFloat(obj.job.matEaveTilesCost) +
      parseFloat(obj.job.matboxGutterLeadCost)
    ).toFixed(2);
    return materialCost;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/rates/averagerates/${
            JSON.parse(localStorage.getItem("userData")).userId
          }`
        );
        console.log("API Response:", response.data);
        response.data[0].userId = JSON.parse(
          localStorage.getItem("userData")
        ).userId;
        setRates(response.data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
        NotificationManager.error(
          "Error occured when fetching/saving data from database"
        );
        // NotificationManager.info("Error fetching rate data:", error);
      }
    };

    fetchData();

    const fetchMaterialData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/qoute/getMaterialRates`
        );

        setMaterialRates(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        NotificationManager.error(
          "Error occured when fetching/saving data from database"
        );
        // NotificationManager.info("Error fetching rate data:", error);
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/qoute/getNailsmmTable`
        );

        setNailsmm(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        NotificationManager.error(
          "Error occured when fetching/saving data from database"
        );
        // NotificationManager.info("Error fetching rate data:", error);
      }
    };

    fetchMaterialData();
  }, []);
  console.log("materialRates5", materialRates);
  useEffect(() => {
    if (tilesData.length !== 0) {
      setTileValues(
        tilesData.filter((a) => {
          return a.tileTypeId == formData.job.tileTypeId;
        })
      );
    }
  }, [formData.job.tileTypeId]);
  const handleInput = (e) => {
    let inputValue = e.target.value;

    // Remove non-numeric characters except for digits and a single dot
    inputValue = inputValue.replace(/[^0-9.]/g, "");

    // Ensure that there is at most one dot in the input
    const dotCount = (inputValue.match(/\./g) || []).length;
    if (dotCount > 1) {
      inputValue = inputValue.slice(0, inputValue.lastIndexOf("."));
    }

    // Update the state or perform any other necessary actions
    // For example, you can update the state using the handleChange function
    handleChange({
      target: {
        name: "area",
        value: inputValue,
      },
    });
  };

  const handleChangeClient = (e) => {
    const { name, value } = e.target;
    const arr = { ...formData };
    arr.client[name] = value;
    setFormData(arr);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const arr = { ...formData };
    const sanitizedValue = value.replace(/[^0-9.]/g, ""); // Remove characters that are not numbers or dot
    const dotCount = sanitizedValue.split(".").length - 1;

    // Allow only one dot
    if (dotCount > 1) {
      return;
    }
    arr.job[name] = sanitizedValue;
    setFormData(arr);
  };
  const convertObjectValuesToFloat = (obj) => {
    const newObj = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[key] = Number(obj[key]);
      }
    }

    return newObj;
  };
  function formatCostProperties(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && key.toLowerCase().includes("cost")) {
        obj[key] = parseFloat(obj[key]).toFixed(2);
      }
    }
  }

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   // Remove any non-numeric and non-dot characters, except the first dot
  //   const cleanedValue = value.replace(/[^0-9.]|(?<=\.\d*)\./g, "");

  //   const arr = { ...formData };
  //   arr.job[name] = Number(cleanedValue);
  //   setFormData(arr);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    formData.job = convertObjectValuesToFloat(formData.job);
    await setFormData(formData);

    if (formData.job.tileTypeId == 1 && formData.job.workType == 1) {
      formData.tilesRates = rates.slateNew;
    }
    if (formData.job.tileTypeId == 1 && formData.job.workType == 2) {
      formData.tilesRates = rates.slateRenew;
    }
    if (formData.job.tileTypeId == 2 && formData.job.workType == 1) {
      formData.tilesRates = rates.smallTileNew;
    }
    if (formData.job.tileTypeId == 2 && formData.job.workType == 2) {
      formData.tilesRates = rates.smallTileRenew;
    }
    if (formData.job.tileTypeId == 3 && formData.job.workType == 1) {
      formData.tilesRates = rates.bigTileNew;
    }
    if (formData.job.tileTypeId == 3 && formData.job.workType == 2) {
      formData.tilesRates = rates.bigTileRenew;
    }
    formData.job.areaLabour = Number(
      formData.tilesRates * formData.job.area
    ).toFixed(2);
    formData.job.hipsLabour = Number(rates.hips * formData.job.hips).toFixed(2);
    formData.job.ridgeLabour = Number(rates.ridge * formData.job.ridge).toFixed(
      2
    );
    formData.job.valleyLabour = Number(
      rates.valleys * formData.job.valley
    ).toFixed(2);
    formData.job.vergeLabour = Number(rates.verge * formData.job.verge).toFixed(
      2
    );
    formData.job.leadworklabour = Number(
      rates.leadWork * formData.job.leadWork
    ).toFixed(2);
    formData.job.roofWindowLabour = Number(
      rates.roofWindow * formData.job.roofWindow
    ).toFixed(2);
    formData.job.eaveCourseLabour = Number(
      rates.hips * formData.job.eaveCourse
    ).toFixed(2);
    formData.job.ventTilesLabour = Number(
      rates.ventTiles * formData.job.ventTiles
    ).toFixed(2);
    formData.job.leadSlateLabour = Number(
      rates.leadSlate * formData.job.leadSlate
    );
    formData.job.boxGutterLabour = Number(
      rates.boxGutter * formData.job.boxGutter
    ).toFixed(2);
    //khubuuu70
    if (formData.job.tileTypeId == 1) {
      // console.log(
      //   "costPerSlatenHalf6",
      //   materialRates.filter(
      //     (a) => a.materialName == "25 x 50mm Batten (big & slate)"
      //   )[0].costPerUnit
      // );
      formData.job.hipsSand = 0;
      if (formData.job.hipsDryFix === 0) {
        formData.job.hipsSand = formData.job.hips;
      } else {
        formData.job.hipsSand = 0;
      }
      if (formData.job.ridgeDryFix === 0) {
        formData.job.ridgeSand = formData.job.ridge;
      } else {
        formData.job.ridgeSand = 0;
      }
      if (formData.job.vergeDryFix === 0) {
        formData.job.vergeSand = formData.job.verge;
      } else {
        formData.job.vergeSand = 0;
      }
      formData.job.matslatenHalfQty = (
        ((formData.job.verge * 2.5 +
          formData.job.valley * 3.5 +
          formData.job.hips * 3.5) /
          100) *
          5 +
        (formData.job.verge * 2.5 +
          formData.job.valley * 3.5 +
          formData.job.hips * 3.5)
      ).toFixed(2);
      formData.job.matslatenHalfActReq = Math.ceil(
        formData.job.matslatenHalfQty
      ).toFixed(2);

      formData.job.matslatenHalfCost = (
        formData.job.matslatenHalfActReq *
        tilesData.filter((a) => a.tileId == formData.job.tileId)[0]
          .costPerSlatenHalf
      ).toFixed(2);

      // formData.job.matSlateQty = (
      //   (tilesData.filter((a) => a.tileId == formData.job.tileId)[0]
      //     .tilesPerMetre *
      //     formData.job.area -
      //     formData.job.matslatenHalfQty +
      //     formData.job.ridge * 8 +
      //     formData.job.eaveCourse * 4) *
      //     1.05
      // ).toFixed(2);

      // formData.job.matSlateQty = (
      //   (tilesData.filter((a) => a.tileId == formData.job.tileId)[0]
      //     .tilesPerMetre *
      //     formData.job.area -
      //     formData.job.matslatenHalfQty +
      //     formData.job.ridge * 8 +
      //     formData.job.eaveCourse * 4) *
      //   1.05
      // ).toFixed(2);
      const tileId = formData.job.tileId;
      const tilesDataFiltered = tilesData.filter((a) => a.tileId == tileId);
      const tilesPerMetre = tilesDataFiltered[0].tilesPerMetre;
      const area = formData.job.area;
      const matslatenHalfQty = formData.job.matslatenHalfQty;
      const ridge = formData.job.ridge;
      const eaveCourse = formData.job.eaveCourse;

      formData.job.matSlateQty = (
        (tilesPerMetre * area - matslatenHalfQty + ridge * 8 + eaveCourse * 4) *
        1.05
      ).toFixed(2);

      console.log("tileId:", tileId);
      console.log("tilesPerMetre:", tilesPerMetre);
      console.log("area:", area);
      console.log("matslatenHalfQty:", matslatenHalfQty);
      console.log("ridge:", ridge);
      console.log("eaveCourse:", eaveCourse);
      console.log("matSlateQty:", formData.job.matSlateQty);

      formData.job.matSlateActReq = Math.ceil(formData.job.matSlateQty).toFixed(
        2
      );
      formData.job.matslateCost = (
        formData.job.matSlateActReq *
        tilesData.filter((a) => a.tileId == formData.job.tileId)[0].costPerTile
      ).toFixed(2);
      formData.job.mateaveFeltQty = (
        formData.job.eaveCourse *
        materialRates.filter((a) => a.materialName == "Eave Felt")[0]
          .amountPerUnit
      ).toFixed(2);

      if (formData.job.mateaveFeltQty % 16 !== 0) {
        let var4 = Number(formData.job.mateaveFeltQty) + 16;

        formData.job.mateaveFeltActReq = Math.ceil(
          var4 - (Number(formData.job.mateaveFeltQty) % 16)
        ).toFixed(2);

        // formData.job.mateaveFeltQty + 16 - (formData.job.mateaveFeltQty % 16);
      } else {
        formData.job.mateaveFeltActReq = Math.ceil(
          Number(formData.job.mateaveFeltQty)
        ).toFixed(2);
      }
      formData.job.mateaveFeltCost = (
        formData.job.mateaveFeltActReq *
        materialRates.filter((a) => a.materialName == "Eave Felt")[0]
          .costPerUnit
      ).toFixed(2);
      formData.job.matroofingMembraneQty = (
        Number(formData.job.area) *
        Number(
          materialRates.filter((a) => a.materialName == "Roofing Membrane")[0]
            .amountPerUnit
        )
      ).toFixed(2);

      if (formData.job.matroofingMembraneQty % 50 !== 0) {
        formData.job.matroofingMembraneActReq = Math.ceil(
          Number(formData.job.matroofingMembraneQty) +
            50 -
            (Number(formData.job.matroofingMembraneQty) % 50)
        ).toFixed(2);
      } else {
        formData.job.matroofingMembraneActReq = Math.ceil(
          Number(formData.job.matroofingMembraneQty)
        ).toFixed(2);
      }
      formData.job.matroofingMembraneCost = (
        formData.job.matroofingMembraneActReq *
        materialRates.filter((a) => a.materialName == "Roofing Membrane")[0]
          .costPerUnit
      ).toFixed(2);
      if (
        tilesData.filter((a) => a.tileId == formData.job.tileId)[0]
          .battenPerMetre *
          formData.job.area ==
        0
      ) {
        formData.job.matbattenQty = formData.job.eaveCourse;
      } else {
        formData.job.matbattenQty =
          tilesData.filter((a) => a.tileId == formData.job.tileId)[0]
            .battenPerMetre *
            formData.job.area +
          formData.job.eaveCourse;
      }
      formData.job.matbattenActReq = Math.ceil(
        formData.job.matbattenQty
      ).toFixed(2);
      formData.job.matbattenCost = (
        formData.job.matbattenActReq *
        materialRates.filter(
          (a) => a.materialName == "25 x 50mm Batten (big & slate)"
        )[0].costPerUnit
      ).toFixed(2);
      formData.job.matslateTileNailsQty =
        formData.job.area *
        tilesData.filter((a) => a.tileId == formData.job.tileId)[0]
          .tileSlateNailsPM;

      let mmValue = tilesData.filter((a) => a.tileId == formData.job.tileId)[0]
        .nailSize;
      console.log(
        "mmValue",
        mmValue,
        nailsmm.filter((a) => a.nailSize == mmValue.replace("mm", "")),
        nailsmm
      );
      formData.job.matslateTileNailsActReq = Math.ceil(
        formData.job.matslateTileNailsQty
      ).toFixed(2);
      formData.job.matslateTileNailsCost = (
        nailsmm.filter((a) => a.nailSize == mmValue.replace("mm", ""))[0]
          .costPerUnit * formData.job.matslateTileNailsActReq
      ).toFixed(2);
      if (
        tilesData.filter((a) => a.tileId == formData.job.tileId)[0]
          .cementFibreSlate == "No"
      ) {
        formData.job.matdiscRivetsQty = 0;
      } else {
        formData.job.matdiscRivetsQty =
          Number(formData.job.matslatenHalfQty) +
          Number(formData.job.matSlateQty);
      }

      formData.job.matbattenNailsQty = (
        formData.job.area *
        tilesData.filter((a) => a.tileId == formData.job.tileId)[0]
          .battenNailsPSQM
      ).toFixed(2);
      formData.job.matbattenNailsActReq = Math.ceil(
        formData.job.matbattenNailsQty
      ).toFixed(2);
      formData.job.matbattenNailsCost = (
        formData.job.matbattenNailsActReq *
        materialRates.filter((a) => a.materialName == "Batten Nails")[0]
          .costPerUnit
      ).toFixed(2);

      formData.job.matdiscRivetsActReq = Math.ceil(
        formData.job.matdiscRivetsQty
      ).toFixed(2);
      formData.job.matdiscRivetsCost = (
        formData.job.matdiscRivetsActReq *
        materialRates.filter((a) => a.materialName == "Disc Rivetts")[0]
          .costPerUnit
      ).toFixed(2);

      // INSERT INTO roofpal.quotes
      // (clientId, userId, tileId, tileTypeId, workType, area, areaLabour, hips, hipsDryFix, hipsSand,
      //    hipsLabour, ridge, ridgeDryFix, ridgeSand, ridgeLabour, valley, valleyDryFix, valleyLabour,
      //     verge, vergeDryFix, vergeSand, vergeLabour, leadwork, leadworklabour, roofWindow,
      //      roofWindowLabour, eaveCourse, eaveCourseLabour, ventTiles, ventTilesLabour, leadSlate,
      //      leadSlateLabour, boxGutter, boxGutterLabour, matSlateQty, matslatenHalfQty, mattilesQty,
      //       mateaveFeltQty, matroofingMembraneQty, matbattenQty, matbattenNailsQty, matslateTileNailsQty,
      //        matdiscRivetsQty, matleadFlashingsQty, matleadValleysQty, matdryValleyQty, matridgeMaterialQty,
      //         mathipsMaterialQty, matsandQty, matcementQty, matSealantsQty, matdryRidgeKitQty,
      //         matHipSupportTraysQty, matUnderCloakQty, matdryVergeQty, matventTilesMaterialQty,
      //          matleadSlateMaterialQty, matboxGutterLeadQty, matBonnetsQty, matEaveTilesQty,
      //          mateaveCombFillerQty, matleftHandVergeTilesQty, matslateCost, matslatenHalfCost,
      //          mattilesCost, mateaveFeltCost, matroofingMembraneCost, matbattenCost, matbattenNailsCost,
      //          matslateTileNailsCost, matdiscRivetsCost, matleadFlashingsCost, matleadValleysCost,
      //          matdryValleyCost, matridgeCost, mathipsCost, matsandCost, matcementCost, matSealantsCost,
      //           matdryRidgeKitCost, matHipSupportTraysCost, matUnderCloakCost, matdryVergeCost,
      //            matventTilescostCost, matleadSlatecost, matboxGutterLeadCost, matBonnetsCost,
      //             matEaveTilesCost, mateaveCombFillerCost, matleftHandVergeTilesCost, labourCost,
      //              materialCost, scaffoldCost, wasteCost, otherCost, totalCost, totalCostWithVat,
      //              totalCost10PctPremiumVat, totalCost10PctPremium, totalCost20PctPremium,
      //              totalCost20PctPremiumVat, createdAt, updatedAt, matSlateActReq, matslatenHalfActReq,
      //               mattilesActReq, mateaveFeltActReq, matroofingMembraneActReq, matbattenActReq,
      //               matbattenNailsActReq, matslateTileNailsActReq, matdiscRivetsActReq,
      //               matleadFlashingsActReq, matleadValleysActReq, matdryValleyActReq, matridgeMaterialActReq,
      //                mathipsMaterialActReq, matsandActReq, matcementActReq, matSealantsActReq,
      //                 matdryRidgeKitActReq, matHipSupportTraysActReq, matUnderCloakActReq, matdryVergeActReq,
      //                  matventTilesMaterialActReq, matleadSlateMaterialActReq, matboxGutterLeadActReq,
      //                   matBonnetsActReq, matEaveTilesActReq, mateaveCombFillerActReq,
      //                    matleftHandVergeTilesActReq, tilesToRemoveHips, dryHipsRidgeKit, dryRidgesRidgeKit,
      //                     dryValleythreeM, valleyTilesHips, hipBonnet, matTilenHalfCost, leftHandVergeTiles,
      //                      matValleyTilesValleysActReq, tilesToRemoveValleys, tilesToRemoveVerge,
      //                       tilesNHalfToAddHips, tilesNHalfToAddValleys, tilesNHalfToAddVerge,
      //                        matValleyTilesValleysCost, matValleyTilesValleysQty, valleysSand)
      // VALUES(0, 0, 0, 0, '', 0, 0, 0, '', 0, 0, 0, '', 0, 0, 0, '', 0, 0, '', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, CURRENT_TIMESTAMP, '', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);

      formData.job.matleadFlashingsQty = Math.ceil(
        formData.job.leadWork *
          materialRates.filter((a) => a.materialName == "Lead Flashings")[0]
            .amountPerUnit
      );
      if (Number(formData.job.matleadFlashingsQty) % 3 == 0) {
        formData.job.matleadFlashingsActReq = Math.ceil(
          formData.job.matleadFlashingsQty
        ).toFixed(2);
      } else {
        let var1 = Number(formData.job.matleadFlashingsQty) + 3;
        console.log("var1", var1, formData.job.matleadFlashingsQty);
        formData.job.matleadFlashingsActReq = Math.ceil(
          var1 - (formData.job.matleadFlashingsQty % 3)
        ).toFixed(2);
      }
      formData.job.matleadFlashingsCost = (
        materialRates.filter((a) => a.materialName == "Lead Flashings")[0]
          .costPerUnit * formData.job.matleadFlashingsActReq
      ).toFixed(2);

      if (formData.job.valleyDryFix == 1) {
        formData.job.matleadValleysQty = 3;
      } else {
        formData.job.matleadValleysQty = Math.ceil(
          formData.job.valley *
            materialRates.filter((a) => a.materialName == "Lead Valleys")[0]
              .amountPerUnit
        ).toFixed(2);
      }
      if (Number(formData.job.matleadValleysQty) % 3 == 0) {
        formData.job.matleadValleysActReq = Math.ceil(
          formData.job.matleadValleysQty
        ).toFixed(2);
      } else {
        formData.job.matleadValleysActReq = Math.ceil(
          Number(formData.job.matleadValleysQty) +
            3 -
            (formData.job.matleadValleysQty % 3)
        ).toFixed(2);
      }
      formData.job.matleadValleysCost = (
        materialRates.filter((a) => a.materialName == "Lead Valleys")[0]
          .costPerUnit * formData.job.matleadValleysActReq
      ).toFixed(2);

      if (formData.job.vergeDryFix == 0) {
        formData.job.matdryValleyQty = 0;
      } else {
        formData.job.matdryValleyQty = (
          formData.job.valley *
          materialRates.filter((a) => a.materialName == "Lead Valleys")[0]
            .amountPerUnit
        ).toFixed(2);
      }
      if (formData.job.matdryValleyQty % 3 == 0) {
        formData.job.matdryValleyActReq = Math.ceil(
          formData.job.matdryValleyQty
        ).toFixed(2);
      } else {
        // formData.job.matdryValleyActReq = Math.ceil(
        //   formData.job.matdryValleyQty + 3 - (formData.job.matdryValleyQty % 3)

        let var2 = Number(formData.job.matdryValleyQty) + 3;
        formData.job.matdryValleyActReq = (
          var2 -
          (formData.job.matdryValleyQty % 3)
        ).toFixed(2);
      }
      formData.job.matdryValleyCost = (
        materialRates.filter((a) => a.materialName == "GRP Valley Tray")[0]
          .costPerUnit * formData.job.matdryValleyActReq
      ).toFixed(2);
      formData.job.matventTilesMaterialQty = formData.job.ventTiles.toFixed(2);
      formData.job.matventTilesMaterialActReq = Math.ceil(
        formData.job.matventTilesMaterialQty
      ).toFixed(2);

      formData.job.matleadSlateMaterialQty = formData.job.leadSlate.toFixed(2);
      formData.job.matleadSlateMaterialActReq = Math.ceil(
        formData.job.matleadSlateMaterialQty
      ).toFixed(2);
      formData.job.matleadSlatecost = (
        formData.job.matleadSlateMaterialActReq *
        materialRates.filter((a) => a.materialName == "Lead Saddle")[0]
          .costPerUnit
      ).toFixed(2);

      if (
        tilesData.filter((a) => a.tileId == formData.job.tileId)[0]
          .ridgeRequired == "Concrete"
      ) {
        formData.job.mathipsMaterialQty = (
          formData.job.hips *
          materialRates.filter((a) => a.materialName == "Concrete Ridge")[0]
            .amountPerUnit
        ).toFixed(2);
      }
      if (
        tilesData.filter((a) => a.tileId == formData.job.tileId)[0]
          .ridgeRequired == "Clay"
      ) {
        formData.job.mathipsMaterialQty = (
          formData.job.hips *
          materialRates.filter((a) => a.materialName == "Clay Ridge")[0]
            .amountPerUnit
        ).toFixed(2);
      }
      if (
        tilesData.filter((a) => a.tileId == formData.job.tileId)[0]
          .ridgeRequired == "Concrete"
      ) {
        formData.job.matridgeMaterialQty = (
          formData.job.ridge *
          materialRates.filter((a) => a.materialName == "Concrete Ridge")[0]
            .amountPerUnit
        ).toFixed(2);
      }
      if (
        tilesData.filter((a) => a.tileId == formData.job.tileId)[0]
          .ridgeRequired == "Clay"
      ) {
        formData.job.matridgeMaterialQty = (
          formData.job.ridge *
          materialRates.filter((a) => a.materialName == "Clay Ridge")[0]
            .amountPerUnit
        ).toFixed(2);
      }
      formData.job.mathipsMaterialActReq = Math.ceil(
        formData.job.mathipsMaterialQty
      ).toFixed(2);
      formData.job.matridgeMaterialActReq = Math.ceil(
        formData.job.matridgeMaterialQty
      ).toFixed(2);
      if (
        tilesData.filter((a) => a.tileId == formData.job.tileId)[0]
          .ridgeRequired == "Concrete"
      ) {
        formData.job.matridgeCost = (
          formData.job.matridgeMaterialActReq *
          materialRates.filter((a) => a.materialName == "Concrete Ridge")[0]
            .costPerUnit
        ).toFixed(2);
        formData.job.mathipsCost = (
          formData.job.mathipsMaterialActReq *
          materialRates.filter((a) => a.materialName == "Concrete Ridge")[0]
            .costPerUnit
        ).toFixed(2);
      } else {
        formData.job.matridgeCost = (
          formData.job.matridgeMaterialActReq *
          materialRates.filter((a) => a.materialName == "Clay Ridge")[0]
            .costPerUnit
        ).toFixed(2);
        formData.job.mathipsCost = (
          formData.job.mathipsMaterialActReq *
          materialRates.filter((a) => a.materialName == "Clay Ridge")[0]
            .costPerUnit
        ).toFixed(2);
      }
      formData.job.matsandQty =
        formData.job.hipsSand + formData.job.ridgeSand + formData.job.vergeSand;
      formData.job.matsandActReq = Math.ceil(formData.job.matsandQty).toFixed(
        2
      );
      formData.job.matsandCost = (
        formData.job.matsandActReq *
        materialRates.filter((a) => a.materialName == "Sand")[0].costPerUnit
      ).toFixed(2);
      formData.job.matcementQty = Math.ceil(formData.job.matsandQty * 0.34);
      formData.job.matcementActReq = Math.ceil(
        formData.job.matcementQty
      ).toFixed(2);

      formData.job.matcementCost = (
        formData.job.matcementActReq *
        materialRates.filter((a) => a.materialName == "Cement")[0].costPerUnit
      ).toFixed(2);

      formData.job.matSealantsQty = (
        formData.job.leadWork /
        materialRates.filter((a) => a.materialName == "Sealants")[0]
          .amountPerUnit
      ).toFixed(2);

      formData.job.matSealantsActReq = Math.ceil(
        formData.job.matSealantsQty
      ).toFixed(2);
      formData.job.matSealantsCost = (
        formData.job.matSealantsActReq *
        materialRates.filter((a) => a.materialName == "Sealants")[0].costPerUnit
      ).toFixed(2);
      if (formData.job.vergeDryFix == 0) {
        formData.job.matdryVergeQty = 0;
        formData.job.matUnderCloakQty = (
          formData.job.verge *
          materialRates.filter(
            (a) => a.materialName == "Cement Fibre Under Cloak"
          )[0].amountPerUnit
        ).toFixed(2);
      } else {
        formData.job.matdryVergeQty = (
          formData.job.verge *
          materialRates.filter(
            (a) => a.materialName == "Dry Verge Unit (Slate Only)"
          )[0].amountPerUnit
        ).toFixed(2);
        formData.job.matUnderCloakQty = 0;
      }
      if (Number(formData.job.matdryVergeQty) % 2 == 0) {
        formData.job.matdryVergeActReq = Math.ceil(
          formData.job.matdryVergeQty
        ).toFixed(2);
      } else {
        formData.job.matdryVergeActReq = (
          Number(formData.job.matdryVergeQty) +
          2 -
          (Number(formData.job.matdryVergeQty) % 2)
        ).toFixed(2);
      }
      formData.job.matdryVergeCost = (
        formData.job.matdryVergeActReq *
        materialRates.filter(
          (a) => a.materialName == "Dry Verge Unit (Slate Only)"
        )[0].costPerUnit
      ).toFixed(2);
      formData.job.matUnderCloakActReq = Math.ceil(
        formData.job.matUnderCloakQty
      ).toFixed(2);
      formData.job.matUnderCloakCost = (
        formData.job.matUnderCloakActReq *
        materialRates.filter(
          (a) => a.materialName == "Cement Fibre Under Cloak"
        )[0].costPerUnit
      ).toFixed(2);
      if (formData.job.vergeDryFix == 1) {
        formData.job.matdryRidgeKitQty = (
          formData.job.verge *
          materialRates.filter(
            (a) => a.materialName == "Dry Verge Unit (Slate Only)"
          )[0].amountPerUnit
        ).toFixed(2);
      } else {
        formData.job.matdryRidgeKitQty = 0;
      }

      if (formData.job.hipsDryFix == 1) {
        formData.job.dryHipsRidgeKit = formData.job.hips * 1.1;
      } else {
        formData.job.dryHipsRidgeKit = 0;
      }

      if (formData.job.ridgeDryFix == 1) {
        formData.job.dryRidgesRidgeKit = formData.job.ridge * 1.1;
      } else {
        formData.job.dryRidgesRidgeKit = 0;
      }

      formData.job.matdryRidgeKitQty = (
        formData.job.dryRidgesRidgeKit + formData.job.dryHipsRidgeKit
      ).toFixed(2);
      if (Number(formData.job.matdryRidgeKitQty) % 3 == 0) {
        formData.job.matdryRidgeKitActReq = Math.ceil(
          formData.job.matdryRidgeKitQty
        ).toFixed(2);
      } else {
        let var5 = Number(formData.job.matdryRidgeKitQty) + 3;
        console.log("var5", var5, formData.job.matdryRidgeKitQty);
        formData.job.matdryRidgeKitActReq = (
          var5 -
          (formData.job.matdryRidgeKitQty % 6)
        ).toFixed(2);
      }

      formData.job.matdryRidgeKitCost = (
        formData.job.matdryRidgeKitActReq *
        materialRates.filter((a) => a.materialName == "Dry Ridge Kit")[0]
          .costPerUnit
      ).toFixed(2);
      if (formData.job.hipsDryFix == 1) {
        formData.job.matHipSupportTraysQty = formData.job.hips * 1.1;
      } else {
        formData.job.matHipSupportTraysQty = 0;
      }
      if (Number(formData.job.matHipSupportTraysQty) % 3 == 0) {
        formData.job.matHipSupportTraysActReq = Math.ceil(
          formData.job.matHipSupportTraysQty
        ).toFixed(2);
      } else {
        let var3 = Number(formData.job.matHipSupportTraysQty) + 3;
        console.log("var3", var3, formData.job.matHipSupportTraysQty);
        formData.job.matHipSupportTraysActReq = (
          var3 -
          (formData.job.matHipSupportTraysQty % 3)
        ).toFixed(2);
      }

      formData.job.matHipSupportTraysCost = (
        formData.job.matHipSupportTraysActReq *
        materialRates.filter((a) => a.materialName == "HIP Supoort Tray")[0]
          .costPerUnit
      ).toFixed(2);

      formData.job.matboxGutterLeadQty = (
        formData.job.boxGutter *
        materialRates.filter((a) => a.materialName == "Box Gutter Lead")[0]
          .amountPerUnit
      ).toFixed(2);
      if (Number(formData.job.matboxGutterLeadQty) % 3 == 0) {
        formData.job.matboxGutterLeadActReq = Math.ceil(
          formData.job.matboxGutterLeadQty
        ).toFixed(2);
      } else {
        let var4 = Number(formData.job.matboxGutterLeadQty) + 3;

        formData.job.matboxGutterLeadActReq = (
          var4 -
          (Number(formData.job.matboxGutterLeadQty) % 3)
        ).toFixed(2);

        // formData.job.matboxGutterLeadActReq = Math.ceil(
        //   formData.job.matboxGutterLeadQty +
        //     3 -
        //     (formData.job.matboxGutterLeadQty % 3)
        // );
      }

      formData.job.matboxGutterLeadCost = (
        formData.job.matboxGutterLeadActReq *
        materialRates.filter((a) => a.materialName == "Box Gutter Lead")[0]
          .costPerUnit
      ).toFixed(2);
      formData.job.matventTilescostCost = (
        formData.job.matventTilesMaterialActReq *
        materialRates.filter((a) => a.materialName == "Vent Tile")[0]
          .costPerUnit
      ).toFixed(2);
      formData.job.materialCost = (
        parseFloat(formData.job.matslateCost) +
        parseFloat(formData.job.matslatenHalfCost) +
        parseFloat(formData.job.mateaveFeltCost) +
        parseFloat(formData.job.matroofingMembraneCost) +
        parseFloat(formData.job.matbattenCost) +
        parseFloat(formData.job.matbattenNailsCost) +
        parseFloat(formData.job.matslateTileNailsCost) +
        parseFloat(formData.job.matdiscRivetsCost) +
        parseFloat(formData.job.matleadFlashingsCost) +
        parseFloat(formData.job.matleadValleysCost) +
        parseFloat(formData.job.matdryValleyCost) +
        parseFloat(formData.job.matridgeCost) +
        parseFloat(formData.job.mathipsCost) +
        parseFloat(formData.job.matsandCost) +
        parseFloat(formData.job.matcementCost) +
        parseFloat(formData.job.matSealantsCost) +
        parseFloat(formData.job.matdryRidgeKitCost) +
        parseFloat(formData.job.matHipSupportTraysCost) +
        parseFloat(formData.job.matUnderCloakCost) +
        parseFloat(formData.job.matdryVergeCost) +
        parseFloat(formData.job.matventTilescostCost) +
        parseFloat(formData.job.matleadSlatecost)
      ).toFixed(2);
      console.log("matslateCost: 631", parseFloat(formData.job.matslateCost));
      console.log(
        "matslatenHalfCost: 631",
        parseFloat(formData.job.matslatenHalfCost)
      );
      console.log(
        "mateaveFeltCost: 631",
        parseFloat(formData.job.mateaveFeltCost)
      );
      console.log(
        "matroofingMembraneCost: 631",
        parseFloat(formData.job.matroofingMembraneCost)
      );
      console.log("matbattenCost: 631", parseFloat(formData.job.matbattenCost));
      console.log(
        "matbattenNailsCost: 631",
        parseFloat(formData.job.matbattenNailsCost)
      );
      console.log(
        "matslateTileNailsCost: 631",
        parseFloat(formData.job.matslateTileNailsCost)
      );
      console.log(
        "matdiscRivetsCost: 631",
        parseFloat(formData.job.matdiscRivetsCost)
      );
      console.log(
        "matleadFlashingsCost: 631",
        parseFloat(formData.job.matleadFlashingsCost)
      );
      console.log(
        "matleadValleysCost: 631",
        parseFloat(formData.job.matleadValleysCost)
      );
      console.log(
        "matdryValleyCost: 631",
        parseFloat(formData.job.matdryValleyCost)
      );
      console.log("matridgeCost: 631", parseFloat(formData.job.matridgeCost));
      console.log("mathipsCost: 631", parseFloat(formData.job.mathipsCost));
      console.log("matsandCost: 631", parseFloat(formData.job.matsandCost));
      console.log("matcementCost: 631", parseFloat(formData.job.matcementCost));
      console.log(
        "matSealantsCost: 631",
        parseFloat(formData.job.matSealantsCost)
      );
      console.log(
        "matdryRidgeKitCost: 631",
        parseFloat(formData.job.matdryRidgeKitCost)
      );
      console.log(
        "matHipSupportTraysCost: 631",
        parseFloat(formData.job.matHipSupportTraysCost)
      );
      console.log(
        "matUnderCloakCost: 631",
        parseFloat(formData.job.matUnderCloakCost)
      );
      console.log(
        "matdryVergeCost: 631",
        parseFloat(formData.job.matdryVergeCost)
      );
      console.log(
        "matventTilescostCost: 631",
        parseFloat(formData.job.matventTilescostCost)
      );
      console.log(
        "matleadSlatecost: 631",
        parseFloat(formData.job.matleadSlatecost)
      );

      // Calculate and log the total material cost

      formData.job.labourCost = (
        Number(formData.tilesRates * formData.job.area) +
        Number(rates.hips * formData.job.hips) +
        Number(rates.ridge * formData.job.ridge) +
        Number(rates.valleys * formData.job.valley) +
        Number(rates.verge * formData.job.verge) +
        Number(rates.leadWork * formData.job.leadWork) +
        Number(rates.roofWindow * formData.job.roofWindow) +
        Number(formData.job.ventTilesLabour) +
        Number(formData.job.leadSlateLabour) +
        Number(formData.job.boxGutterLabour)
      ).toFixed(2);
      console.log(
        "total Cost",
        formData.job.materialCost + parseFloat(formData.job.labourCost)
      );

      formData.job.totalCost = calculateTotalCost(formData);
      formData.job.totalCostWithVat = (
        Number(formData.job.totalCost) * 1.2
      ).toFixed(2);
    }
    //khubuuu69
    if (formData.job.tileTypeId == 2) {
      formData.job.hipsSand = 0;

      if (formData.job.hipsDryFix == 0 && formData.job.hipBonnet == 0) {
        formData.job.hipsSand = formData.job.hips;

        console.log("11", formData.job.hipsSand);
      }

      if (formData.job.hipsDryFix == 0 && formData.job.hipBonnet == 1) {
        formData.job.hipsSand = 0;

        console.log("12", formData.job.hipsSand);
      }

      if (formData.job.hipsDryFix == 1) {
        formData.job.hipsSand = formData.job.hips;

        console.log("13", formData.job.hipsSand);
      }
      formData.job.ridgeSand = 0;
      if (formData.job.ridgeDryFix == 0) {
        formData.job.ridgeSand = formData.job.ridge;
        console.log("14", formData.job.ridgeSand);
      }
      if (
        formData.job.valleyDryFix == 0 &&
        formData.job.valleyTilesValleys == 0
      ) {
        formData.job.valleysand = formData.job.valley;
        console.log("15", formData.job.valleysand);
      }

      if (
        formData.job.valleyDryFix == 0 &&
        formData.job.valleyTilesValleys == 1
      ) {
        formData.job.valleysand = 0;
        console.log("16", formData.job.valleysand);
      }
      if (formData.job.valleyDryFix == 1) {
        formData.job.valleysand = formData.job.valley;
        console.log("17", formData.job.valleysand);
      }

      if (formData.job.vergeDryFix == 0) {
        formData.job.vergeSand = formData.job.verge;
        console.log("18", formData.job.vergeSand);
      } else {
        formData.job.vergeSand = 0;
        console.log("19", formData.job.vergeSand);
      }
      // console.log(
      //   "costPerSlatenHalf6",
      //   materialRates.filter(
      //     (a) => a.materialName === "25 x 50mm Batten (big & slate)"
      //   )[0].costPerUnit
      // );

      formData.job.matslatenHalfQty = (
        (formData.job.verge * 5 +
          formData.job.valley * 6 +
          formData.job.hips * 6) *
        1.05
      ).toFixed(2);
      console.log(
        "tiles697",
        tilesData,
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
      );
      console.log(
        "tiles698",
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .costPerTilenHalf
      );
      formData.job.matslatenHalfActReq = Math.ceil(
        formData.job.matslatenHalfQty
      ).toFixed(2);
      formData.job.matslatenHalfCost = (
        formData.job.matslatenHalfActReq *
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .costPerTilenHalf
      ).toFixed(2);

      formData.job.matSlateQty = Number(
        (tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .tilesPerMetre *
          parseFloat(formData.job.area) -
          (parseFloat(formData.job.hips * 9) +
            parseFloat(formData.job.valley * 9) +
            parseFloat(formData.job.verge * 7.5))) *
          1.05
      );

      formData.job.matSlateActReq = Math.ceil(formData.job.matSlateQty).toFixed(
        2
      );
      console.log(
        "error117",
        formData.job.matSlateActReq,
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .tilesPerMetre,
        formData.job.area,
        formData.job.hips,
        formData.job.valley,
        formData.job.verge
      );
      formData.job.matslateCost = (
        formData.job.matSlateActReq *
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0].costPerTile
      ).toFixed(2);
      formData.job.mateaveFeltQty = (
        materialRates.filter((a) => a.materialName === "Eave Felt")[0]
          .amountPerUnit * formData.job.eaveCourse
      ).toFixed(2);

      if (formData.job.mateaveFeltQty % 16 !== 0) {
        let var4 = Number(formData.job.mateaveFeltQty) + 16;

        formData.job.mateaveFeltActReq = Math.ceil(
          var4 - (Number(formData.job.mateaveFeltQty) % 16)
        ).toFixed(2);

        // formData.job.mateaveFeltQty + 16 - (formData.job.mateaveFeltQty % 16);
      } else {
        formData.job.mateaveFeltActReq = Math.ceil(
          Number(formData.job.mateaveFeltQty)
        ).toFixed(2);
      }
      formData.job.mateaveFeltCost = (
        formData.job.mateaveFeltActReq *
        materialRates.filter((a) => a.materialName === "Eave Felt")[0]
          .costPerUnit
      ).toFixed(2);
      formData.job.matroofingMembraneQty = (
        Number(formData.job.area) *
        Number(
          materialRates.filter((a) => a.materialName == "Roofing Membrane")[0]
            .amountPerUnit
        )
      ).toFixed(2);

      if (formData.job.matroofingMembraneQty % 50 !== 0) {
        formData.job.matroofingMembraneActReq = Math.ceil(
          Number(formData.job.matroofingMembraneQty) +
            50 -
            (Number(formData.job.matroofingMembraneQty) % 50)
        ).toFixed(2);
      } else {
        formData.job.matroofingMembraneActReq = Math.ceil(
          Number(formData.job.matroofingMembraneQty)
        ).toFixed(2);
      }
      formData.job.matroofingMembraneCost = (
        formData.job.matroofingMembraneActReq *
        materialRates.filter((a) => a.materialName === "Roofing Membrane")[0]
          .costPerUnit
      ).toFixed(2);
      if (
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .battenPerMetre *
          formData.job.area ===
        0
      ) {
        formData.job.matbattenQty = formData.job.eaveCourse;
      } else {
        formData.job.matbattenQty =
          tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
            .battenPerMetre *
            formData.job.area +
          formData.job.eaveCourse;
      }
      formData.job.matbattenActReq = Math.ceil(
        formData.job.matbattenQty
      ).toFixed(2);
      formData.job.matbattenCost = (
        formData.job.matbattenActReq *
        materialRates.filter(
          (a) => a.materialName === "25 x 38mm Batten (Small tile)"
        )[0].costPerUnit
      ).toFixed(2);

      formData.job.matEaveTilesQty =
        formData.job.ridge * 12 + formData.job.eaveCourse * 6;

      formData.job.matEaveTilesActReq = Math.ceil(
        formData.job.matEaveTilesQty
      ).toFixed(2);
      console.log("UUU", formData.job.matEaveTilesActReq);
      formData.job.matEaveTilesCost = parseFloat(
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .costPerEaveTile * formData.job.matEaveTilesActReq
      ).toFixed(2);
      console.log("www", formData.job.matEaveTilesCost);

      formData.job.matventTilesQty = formData.job.ventTiles;

      formData.job.matventTilesActReq = Math.ceil(
        formData.job.matventTilesQty
      ).toFixed(2);

      formData.job.matventTilescost = (
        formData.job.matventTilesActReq *
        materialRates.filter((a) => a.materialName === "Vent Tile")[0]
          .costPerUnit
      ).toFixed(2);

      formData.job.matleadSlateQty = formData.job.leadSlate;

      formData.job.matleadslateActReq = Math.ceil(
        formData.job.matleadSlateQty
      ).toFixed(2);

      formData.job.matleadSlatecost = (
        formData.job.matleadslateActReq *
        materialRates.filter((a) => a.materialName === "Lead Saddle")[0]
          .costPerUnit
      ).toFixed(2);

      formData.job.matslateTileNailsQty = Number(
        parseFloat(formData.job.matSlateQty) +
          parseFloat(formData.job.matslatenHalfQty) +
          parseFloat(formData.job.matEaveTilesQty)
      );
      console.log("eee", formData.job.matslateTileNailsQty);
      formData.job.matslateTileNailsActReq = Math.ceil(
        Number(formData.job.matslateTileNailsQty)
      ).toFixed(2);
      console.log(
        "ffff",
        formData.job.matslateTileNailsActReq,
        formData.job.matslateTileNailsQty,
        formData.job.matSlateQty,
        formData.job.matslatenHalfQty,
        formData.job.matEaveTilesActReq
      );

      let mmmValue = tilesData.filter(
        (a) => a.tileId === formData.job.tileId
      )[0].nailSize;
      if (nailsmm.filter((a) => a.nailSize == mmmValue.replace("mm", ""))[0]) {
        formData.job.matslateTileNailsCost = Number(
          nailsmm.filter((a) => a.nailSize == mmmValue.replace("mm", ""))[0]
            .costPerUnit * Number(formData.job.matslateTileNailsActReq)
        ).toFixed(2);
      }
      console.log(
        "JJJJ",
        nailsmm,
        mmmValue,
        nailsmm.filter((a) => a.nailSize == mmmValue.replace("mm", ""))
      );

      formData.job.matbattenNailsQty = (
        formData.job.area *
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .battenNailsPSQM
      ).toFixed(2);
      formData.job.matbattenNailsActReq = Math.ceil(
        formData.job.matbattenNailsQty
      ).toFixed(2);
      console.log("CCC battennails", formData.job.matbattenNailsActReq);
      formData.job.matbattenNailsCost = (
        formData.job.matbattenNailsActReq *
        materialRates.filter((a) => a.materialName === "Batten Nails")[0]
          .costPerUnit
      ).toFixed(2);
      // formData.job.matbattenNailsActReq = Math.ceil(
      //   formData.job.matslateTileNailsQty
      // );
      formData.job.matdiscRivetsActReq = Math.ceil(
        formData.job.matdiscRivetsQty
      ).toFixed(2);
      formData.job.matdiscRivetsCost = (
        formData.job.matdiscRivetsActReq *
        materialRates.filter((a) => a.materialName === "Disc Rivetts")[0]
          .costPerUnit
      ).toFixed(2);

      formData.job.matleadFlashingsQty = Math.ceil(
        formData.job.leadWork *
          materialRates.filter((a) => a.materialName === "Roofing Membrane")[0]
            .amountPerUnit
      ).toFixed(2);

      if (Number(formData.job.matleadFlashingsQty) % 3 == 0) {
        formData.job.matleadFlashingsActReq = Math.ceil(
          formData.job.matleadFlashingsQty
        ).toFixed(2);
      } else {
        let var1 = Number(formData.job.matleadFlashingsQty) + 3;
        console.log("var1", var1, formData.job.matleadFlashingsQty);
        formData.job.matleadFlashingsActReq = Math.ceil(
          var1 - (formData.job.matleadFlashingsQty % 3)
        ).toFixed(2);
      }
      formData.job.matleadFlashingsCost = (
        materialRates.filter((a) => a.materialName === "Roofing Membrane")[0]
          .costPerUnit * formData.job.matleadFlashingsActReq
      ).toFixed(2);
      formData.job.dryValleythreeM = 0;

      if (formData.job.valleyDryFix === 1) {
        formData.job.dryValleythreeM = 3;
      }

      if (formData.job.valleyDryFix === 1) {
        formData.job.matleadValleysQty = 3;
        console.log("1 valleydryfix", formData.job.valleyDryFix === 1);
      } else if (
        formData.job.valleyTilesValleys === 1 &&
        formData.job.valleyDryFix === 0
      ) {
        formData.job.matleadValleysQty = 3;
      } else {
        formData.job.matleadValleysQty = (
          Number(formData.job.valley) *
            materialRates.filter((a) => a.materialName === "Lead Valleys")[0]
              .amountPerUnit +
          Number(formData.job.dryValleythreeM)
        ).toFixed(2);
      }
      if (Number(formData.job.matleadValleysQty) % 3 === 0) {
        formData.job.matleadValleysActReq = Math.ceil(
          formData.job.matleadValleysQty
        ).toFixed(2);
      } else {
        formData.job.matleadValleysActReq = Math.ceil(
          Number(formData.job.matleadValleysQty) +
            3 -
            (Number(formData.job.matleadValleysQty) % 3)
        ).toFixed(2);
      }
      formData.job.matleadValleysCost = (
        materialRates.filter((a) => a.materialName === "Lead Valleys")[0]
          .costPerUnit * formData.job.matleadValleysActReq
      ).toFixed(2);

      if (formData.job.valleyDryFix === 1) {
        formData.job.matdryValleyQty = (
          formData.job.valley *
          materialRates.filter((a) => a.materialName === "Lead Valleys")[0]
            .amountPerUnit
        ).toFixed(2);
      } else {
        formData.job.matdryValleyQty = 0;
      }
      if (formData.job.matdryValleyQty % 3 === 0) {
        formData.job.matdryValleyActReq = Math.ceil(
          formData.job.matdryValleyQty
        ).toFixed(2);
      } else {
        formData.job.matdryValleyActReq = (
          Number(formData.job.matdryValleyQty) +
          3 -
          Number(formData.job.matdryValleyQty % 3)
        ).toFixed(2);
      }
      //Continue
      formData.job.matdryValleyCost = Number(
        materialRates.filter((a) => a.materialName === "GRP Valley Tray")[0]
          .costPerUnit * formData.job.matdryValleyActReq
      ).toFixed(2);
      console.log(
        "GRP Valley Tray",
        formData.job.matdryValleyActReq,
        formData.job.matdryValleyCost
      );

      if (formData.job.valleyTilesValleys === 0) {
        formData.job.matValleyTilesValleysQty = 0;
      } else {
        formData.job.matValleyTilesValleysQty =
          tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
            .valleyTilesPerLM * formData.job.valley;
      }

      formData.job.matValleyTilesValleysActReq = Math.ceil(
        formData.job.matValleyTilesValleysQty
      ).toFixed(2);
      formData.job.matValleyTilesValleysCost = (
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .costPerValleyTile * formData.job.matValleyTilesValleysActReq
      ).toFixed(2);

      //Hips
      if (formData.job.hipBonnet == 1) {
        formData.job.mathipsMaterialQty = 0;

        console.log("Error1hip876", formData.job.mathipsMaterialQty);
      } else if (
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .ridgeRequired === "Concrete"
      ) {
        formData.job.mathipsMaterialQty = (
          formData.job.hips *
          materialRates.filter((a) => a.materialName === "Concrete Ridge")[0]
            .amountPerUnit
        ).toFixed(2);
        console.log("Error3hip", formData.job.mathipsMaterialQty);
      } else if (
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .ridgeRequired === "Clay"
      ) {
        formData.job.mathipsMaterialQty = (
          formData.job.hips *
          materialRates.filter((a) => a.materialName === "Clay Ridge")[0]
            .amountPerUnit
        ).toFixed(2);
      }
      formData.job.mathipsMaterialActReq = Math.ceil(
        formData.job.mathipsMaterialQty
      ).toFixed(2);

      if (
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .ridgeRequired === "Concrete"
      ) {
        formData.job.matridgeMaterialQty = (
          formData.job.ridge *
          materialRates.filter((a) => a.materialName === "Concrete Ridge")[0]
            .amountPerUnit
        ).toFixed(2);
      }
      if (
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .ridgeRequired === "Clay"
      ) {
        formData.job.matridgeMaterialQty = (
          formData.job.ridge *
          materialRates.filter((a) => a.materialName === "Clay Ridge")[0]
            .amountPerUnit
        ).toFixed(2);
      }

      formData.job.matridgeMaterialActReq = Math.ceil(
        formData.job.matridgeMaterialQty
      ).toFixed(2);
      if (
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .ridgeRequired === "Concrete"
      ) {
        formData.job.matridgeCost = (
          formData.job.matridgeMaterialActReq *
          materialRates.filter((a) => a.materialName === "Concrete Ridge")[0]
            .costPerUnit
        ).toFixed(2);
        formData.job.mathipsCost = (
          formData.job.mathipsMaterialActReq *
          materialRates.filter((a) => a.materialName === "Concrete Ridge")[0]
            .costPerUnit
        ).toFixed(2);
      } else {
        formData.job.matridgeCost = (
          formData.job.matridgeMaterialActReq *
          materialRates.filter((a) => a.materialName === "Clay Ridge")[0]
            .costPerUnit
        ).toFixed(2);
        formData.job.mathipsCost = (
          formData.job.mathipsMaterialActReq *
          materialRates.filter((a) => a.materialName === "Clay Ridge")[0]
            .costPerUnit
        ).toFixed(2);
      }
      console.log(
        "Actreq111",
        formData.job.mathipsMaterialQty,
        formData.job.mathipsMaterialActReq,
        Math.ceil(formData.job.matridgeMaterialQty)
      );

      //bonnets
      if (formData.job.hipBonnet == 0) {
        formData.job.matBonnetsQty = 0;
      } else if (formData.job.hipBonnet == 1) {
        formData.job.matBonnetsQty =
          formData.job.hips *
          tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
            .bonnetsPerLM;
      }

      formData.job.matBonnetsActReq = Math.ceil(
        formData.job.matBonnetsQty
      ).toFixed(2);
      formData.job.matBonnetsCost = (
        formData.job.matBonnetsActReq *
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .costPerBonnet
      ).toFixed(2);

      formData.job.matventTilesMaterialQty = formData.job.ventTiles.toFixed(2);
      formData.job.matventTilesMaterialActReq = Math.ceil(
        formData.job.matventTilesMaterialQty
      ).toFixed(2);
      formData.job.matventTilescostCost = (
        formData.job.matventTilesMaterialActReq *
        materialRates.filter((a) => a.materialName === "Vent Tile")[0]
          .costPerUnit
      ).toFixed(2);

      formData.job.matleadSlateMaterialQty = formData.job.leadSlate.toFixed(2);
      formData.job.matleadSlateMaterialActReq = Math.ceil(
        formData.job.matleadSlateMaterialQty
      ).toFixed(2);
      formData.job.matleadSlatecost = (
        formData.job.matleadSlateMaterialActReq *
        materialRates.filter((a) => a.materialName === "Lead Saddle")[0]
          .costPerUnit
      ).toFixed(2);
      //Sand
      formData.job.matsandQty =
        Number(formData.job.hipsSand) +
        Number(formData.job.ridgeSand) +
        Number(formData.job.vergeSand) +
        Number(formData.job.valleysand);

      formData.job.matsandActReq = Math.ceil(
        Number(formData.job.matsandQty)
      ).toFixed(2);
      console.log(
        "matsandQtyCheck",
        formData.job.hipsSand,
        formData.job.ridgeSand,
        formData.job.valleysand,
        formData.job.vergeSand
      );
      formData.job.matsandCost = (
        formData.job.matsandActReq *
        materialRates.filter((a) => a.materialName === "Sand")[0].costPerUnit
      ).toFixed(2);
      console.log("matsandQtyCheckCost", formData.job.matsandCost);

      // formData.job.matcementQty = Math.ceil(formData.job.matsandActReq * 0.34);
      formData.job.matcementQty = Math.ceil(formData.job.matsandQty * 0.34);
      formData.job.matcementActReq = Math.ceil(
        formData.job.matcementQty
      ).toFixed(2);

      formData.job.matcementCost = (
        formData.job.matcementActReq *
        materialRates.filter((a) => a.materialName === "Cement")[0].costPerUnit
      ).toFixed(2);

      formData.job.matSealantsQty = (
        formData.job.leadWork /
        materialRates.filter((a) => a.materialName === "Sealants")[0]
          .amountPerUnit
      ).toFixed(2);

      formData.job.matSealantsActReq = Math.ceil(
        formData.job.matSealantsQty
      ).toFixed(2);
      formData.job.matSealantsCost = (
        formData.job.matSealantsActReq *
        materialRates.filter((a) => a.materialName === "Sealants")[0]
          .costPerUnit
      ).toFixed(2);
      if (formData.job.vergeDryFix === 0) {
        formData.job.matUnderCloakQty = (
          formData.job.verge *
          materialRates.filter(
            (a) => a.materialName === "Cement Fibre Under Cloak"
          )[0].amountPerUnit
        ).toFixed(2);
      } else {
        // formData.job.matdryVergeQty = (
        //   formData.job.verge *
        //   materialRates.filter(
        //     (a) => a.materialName === "Dry Verge Unit (Slate Only)"
        //   )[0].amountPerUnit
        // ).toFixed(2);
        formData.job.matUnderCloakQty = 0;
      }
      // if (Number(formData.job.matdryVergeQty) % 2 === 0) {
      //   formData.job.matdryVergeActReq = Math.ceil(formData.job.matdryVergeQty);
      // } else {
      //   formData.job.matdryVergeActReq =
      //     Number(formData.job.matdryVergeQty) +
      //     2 -
      //     (Number(formData.job.matdryVergeQty) % 2);
      // }
      // formData.job.matdryVergeCost =
      //   formData.job.matdryVergeActReq *
      //   materialRates.filter(
      //     (a) => a.materialName === "Dry Verge Unit (Slate Only)"
      //   )[0].costPerUnit;

      if (formData.job.vergeDryFix === 1) {
        formData.job.matdryVergeQty = (
          formData.job.verge *
          materialRates.filter(
            (a) => a.materialName === "Dry Verge Unit (Tile Only)"
          )[0].amountPerUnit
        ).toFixed(2);
      } else {
        // formData.job.matdryVergeQty = (
        //   formData.job.verge *
        //   materialRates.filter(
        //     (a) => a.materialName === "Dry Verge Unit (Slate Only)"
        //   )[0].amountPerUnit
        // ).toFixed(2);
        formData.job.matdryVergeQty = 0;
      }
      formData.job.matdryVergeActReq = Math.ceil(
        Number(formData.job.matdryVergeQty)
      ).toFixed(2);
      console.log("YYYY4", formData.job.matdryVergeActReq);

      formData.job.matdryVergeCost = (
        Number(formData.job.matdryVergeActReq) *
        Number(
          materialRates.filter(
            (a) => a.materialName === "Dry Verge Unit (Tile Only)"
          )[0].costPerUnit
        )
      ).toFixed(2);

      formData.job.matUnderCloakActReq = Math.ceil(
        formData.job.matUnderCloakQty
      ).toFixed(2);
      formData.job.matUnderCloakCost = (
        formData.job.matUnderCloakActReq *
        materialRates.filter(
          (a) => a.materialName === "Cement Fibre Under Cloak"
        )[0].costPerUnit
      ).toFixed(2);

      //
      if (formData.job.hipsDryFix === 1) {
        formData.job.dryHipsRidgeKit = formData.job.hips * 1.1;
      } else {
        formData.job.dryHipsRidgeKit = 0;
      }

      //IF(E12="Yes",SUM(B12*1.1),0)

      if (formData.job.ridgeDryFix === 1) {
        formData.job.dryRidgesRidgeKit = formData.job.ridge * 1.1;
      } else {
        formData.job.dryRidgesRidgeKit = 0;
      }
      // Dry ridge kit
      formData.job.matdryRidgeKitQty = (
        formData.job.dryRidgesRidgeKit + formData.job.dryHipsRidgeKit
      ).toFixed(2);

      if (Number(formData.job.matdryRidgeKitQty) % 6 == 0) {
        formData.job.matdryRidgeKitActReq = Math.ceil(
          formData.job.matdryRidgeKitQty
        ).toFixed(2);
      } else {
        let var5 = Number(formData.job.matdryRidgeKitQty) + 6;
        console.log("var5", var5, formData.job.matdryRidgeKitQty);
        formData.job.matdryRidgeKitActReq = (
          var5 -
          (formData.job.matdryRidgeKitQty % 6)
        ).toFixed(2);
      }
      formData.job.matdryRidgeKitCost = (
        formData.job.matdryRidgeKitActReq *
        materialRates.filter((a) => a.materialName === "Dry Ridge Kit")[0]
          .costPerUnit
      ).toFixed(2);

      if (formData.job.hipsDryFix === 1) {
        formData.job.matHipSupportTraysQty = formData.job.hips * 1.1;
      } else {
        formData.job.matHipSupportTraysQty = 0;
      }

      formData.job.matHipSupportTraysActReq = Math.ceil(
        formData.job.matHipSupportTraysQty
      ).toFixed(2);
      if (Number(formData.job.matHipSupportTraysActReq) % 3 == 0) {
        formData.job.matHipSupportTraysActReq = Math.ceil(
          formData.job.matHipSupportTraysQty
        ).toFixed(2);
      } else {
        let var5 = Number(formData.job.matHipSupportTraysQty) + 3;
        console.log("var5", var5, formData.job.matHipSupportTraysQty);
        formData.job.matHipSupportTraysActReq = (
          var5 -
          (formData.job.matHipSupportTraysQty % 3)
        ).toFixed(2);
      }
      formData.job.matHipSupportTraysCost = (
        formData.job.matHipSupportTraysActReq *
        materialRates.filter((a) => a.materialName === "HIP Supoort Tray")[0]
          .costPerUnit
      ).toFixed(2);
      formData.job.matboxGutterLeadQty = (
        formData.job.boxGutter *
        materialRates.filter((a) => a.materialName == "Box Gutter Lead")[0]
          .amountPerUnit
      ).toFixed(2);
      if (Number(formData.job.matboxGutterLeadQty) % 3 == 0) {
        formData.job.matboxGutterLeadActReq = Math.ceil(
          formData.job.matboxGutterLeadQty
        ).toFixed(2);
      } else {
        let var4 = Number(formData.job.matboxGutterLeadQty) + 3;

        formData.job.matboxGutterLeadActReq = (
          var4 -
          (Number(formData.job.matboxGutterLeadQty) % 3)
        ).toFixed(2);
      }

      formData.job.matboxGutterLeadCost = (
        formData.job.matboxGutterLeadActReq *
        materialRates.filter((a) => a.materialName == "Box Gutter Lead")[0]
          .costPerUnit
      ).toFixed(2);

      formData.job.materialCost = (
        parseFloat(formData.job.matslateCost) +
        parseFloat(formData.job.matslatenHalfCost) +
        parseFloat(formData.job.mateaveFeltCost) +
        parseFloat(formData.job.matroofingMembraneCost) +
        parseFloat(formData.job.matbattenCost) +
        parseFloat(formData.job.matbattenNailsCost) +
        parseFloat(formData.job.matslateTileNailsCost) +
        // parseFloat(formData.job.matdiscRivetsCost) +
        parseFloat(formData.job.matleadFlashingsCost) +
        parseFloat(formData.job.matleadValleysCost) +
        parseFloat(formData.job.matdryValleyCost) +
        parseFloat(formData.job.matridgeCost) +
        parseFloat(formData.job.mathipsCost) +
        parseFloat(formData.job.matsandCost) +
        parseFloat(formData.job.matcementCost) +
        parseFloat(formData.job.matSealantsCost) +
        parseFloat(formData.job.matdryRidgeKitCost) +
        parseFloat(formData.job.matHipSupportTraysCost) +
        parseFloat(formData.job.matUnderCloakCost) +
        parseFloat(formData.job.matdryVergeCost) +
        parseFloat(formData.job.matventTilescostCost) +
        parseFloat(formData.job.matleadSlatecost) +
        parseFloat(formData.job.matBonnetsCost) +
        parseFloat(formData.job.matValleyTilesValleysCost) +
        parseFloat(formData.job.matEaveTilesCost) +
        parseFloat(formData.job.matboxGutterLeadCost)
      ).toFixed(2);

      // Log each variable to the console
      console.log("matslateCost:", formData.job.matslateCost);
      console.log("matslatenHalfCost:", formData.job.matslatenHalfCost);
      console.log("mateaveFeltCost:", formData.job.mateaveFeltCost);
      console.log(
        "matroofingMembraneCost:",
        formData.job.matroofingMembraneCost
      );
      console.log("matbattenCost:", formData.job.matbattenCost);
      console.log("matbattenNailsCost:", formData.job.matbattenNailsCost);
      console.log("matslateTileNailsCost:", formData.job.matslateTileNailsCost);
      console.log("matdiscRivetsCost:", formData.job.matdiscRivetsCost);
      console.log("matleadFlashingsCost:", formData.job.matleadFlashingsCost);
      console.log("matleadValleysCost:", formData.job.matleadValleysCost);
      console.log("matdryValleyCost:", formData.job.matdryValleyCost);
      console.log("matridgeCost:", formData.job.matridgeCost);
      console.log("mathipsCost:", formData.job.mathipsCost);
      console.log("matsandCost:", formData.job.matsandCost);
      console.log("matcementCost:", formData.job.matcementCost);
      console.log("matSealantsCost:", formData.job.matSealantsCost);
      console.log("matdryRidgeKitCost:", formData.job.matdryRidgeKitCost);
      console.log(
        "matHipSupportTraysCost:",
        formData.job.matHipSupportTraysCost
      );
      console.log("matUnderCloakCost:", formData.job.matUnderCloakCost);
      console.log("matdryVergeCost:", formData.job.matdryVergeCost);
      console.log("matventTilescostCost:", formData.job.matventTilescostCost);
      console.log("matleadSlatecost:", formData.job.matleadSlatecost);
      console.log("matBonnetsCost:", formData.job.matBonnetsCost);
      console.log(
        "matValleyTilesValleysCost:",
        formData.job.matValleyTilesValleysCost
      );
      console.log("matEaveTilesCost:", formData.job.matEaveTilesCost);
      console.log("matboxGutterLeadCost:", formData.job.matboxGutterLeadCost);

      formData.job.labourCost = (
        Number(formData.tilesRates * formData.job.area) +
        Number(rates.hips * formData.job.hips) +
        Number(rates.ridge * formData.job.ridge) +
        Number(rates.valleys * formData.job.valley) +
        Number(rates.verge * formData.job.verge) +
        Number(rates.leadWork * formData.job.leadWork) +
        Number(rates.roofWindow * formData.job.roofWindow) +
        Number(formData.job.ventTilesLabour) +
        Number(formData.job.leadSlateLabour) +
        Number(formData.job.boxGutterLabour)
      ).toFixed(2);

      formData.job.totalCost = calculateTotalCost(formData);
      formData.job.totalCostWithVat = (
        Number(formData.job.totalCost) * 1.2
      ).toFixed(2);
    }
    if (formData.job.tileTypeId == 3) {
      formData.job.hipsSand = 0;

      if (formData.job.hipsDryFix == 0 && formData.job.hipBonnet == 0) {
        formData.job.hipsSand = formData.job.hips;

        console.log("11", formData.job.hipsSand);
      }

      if (formData.job.hipsDryFix == 0 && formData.job.hipBonnet == 1) {
        formData.job.hipsSand = 0;

        console.log("12", formData.job.hipsSand);
      }

      if (formData.job.hipsDryFix == 1) {
        formData.job.hipsSand = formData.job.hips;

        console.log("13", formData.job.hipsSand);
      }
      formData.job.ridgeSand = 0;
      if (formData.job.ridgeDryFix == 0) {
        formData.job.ridgeSand = formData.job.ridge;
        console.log("14", formData.job.ridgeSand);
      }
      if (
        formData.job.valleyDryFix == 0 &&
        formData.job.valleyTilesValleys == 0
      ) {
        formData.job.valleysand = formData.job.valley;
        console.log("15", formData.job.valleysand);
      }

      if (
        formData.job.valleyDryFix == 0 &&
        formData.job.valleyTilesValleys == 1
      ) {
        formData.job.valleysand = 0;
        console.log("16", formData.job.valleysand);
      }
      if (formData.job.valleyDryFix == 1) {
        formData.job.valleysand = formData.job.valley;
        console.log("17", formData.job.valleysand);
      }

      if (formData.job.vergeDryFix == 0) {
        formData.job.vergeSand = formData.job.verge;
        console.log("18", formData.job.vergeSand);
      } else {
        formData.job.vergeSand = 0;
        console.log("19", formData.job.vergeSand);
      }

      formData.job.matleftHandVergeQty = (
        formData.job.leftHandVergeLm *
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .leftHandedTilePLM *
        1.05
      ).toFixed(2);

      formData.job.matleftHandVergeActReq = Math.ceil(
        formData.job.matleftHandVergeQty
      ).toFixed(2);
      formData.job.matleftHandVergeCost = (
        formData.job.matleftHandVergeActReq *
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .leftHandedTileCost
      ).toFixed(2);

      formData.job.matSlateQty = Number(
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .tilesPerMetre *
          parseFloat(formData.job.area) *
          1.05 -
          formData.job.matleftHandVergeQty
      ).toFixed(2);

      formData.job.matSlateActReq = Math.ceil(formData.job.matSlateQty);

      formData.job.matslateCost = (
        formData.job.matSlateActReq *
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0].costPerTile
      ).toFixed(2);

      if (
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0] &&
        tilesData
          .filter((a) => a.tileId === formData.job.tileId)[0]
          .eaveCombFiller.toLowerCase() != "yes"
      ) {
        formData.job.eaveCombFillerQty = 0;
      } else {
        formData.job.eaveCombFillerQty = (
          materialRates.filter((a) => a.materialName === "Eave Comb Filler")[0]
            .amountPerUnit * formData.job.eaveCourse
        ).toFixed(2);
      }

      formData.job.eaveCombFillerActReq = Math.ceil(
        formData.job.eaveCombFillerQty
      ).toFixed(2);

      formData.job.eaveCombFillerCost = (
        materialRates.filter((a) => a.materialName === "Eave Comb Filler")[0]
          .costPerUnit * formData.job.eaveCombFillerActReq
      ).toFixed(2);
      if (
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .battenPerMetre *
          formData.job.area ===
        0
      ) {
        formData.job.matbattenQty = formData.job.eaveCourse;
      } else {
        formData.job.matbattenQty =
          tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
            .battenPerMetre *
            formData.job.area +
          formData.job.eaveCourse;
      }
      formData.job.matbattenActReq = Math.ceil(
        formData.job.matbattenQty
      ).toFixed(2);
      formData.job.matbattenCost = (
        formData.job.matbattenActReq *
        materialRates.filter(
          (a) => a.materialName === "25 x 50mm Batten (big & slate)"
        )[0].costPerUnit
      ).toFixed(2);

      formData.job.matslateTileNailsQty = Number(formData.job.matSlateQty);

      formData.job.matslateTileNailsActReq = Math.ceil(
        Number(formData.job.matslateTileNailsQty)
      ).toFixed(2);

      let mmmValue = tilesData.filter(
        (a) => a.tileId === formData.job.tileId
      )[0].nailSize;
      if (nailsmm.filter((a) => a.nailSize == mmmValue.replace("mm", ""))[0]) {
        formData.job.matslateTileNailsCost = Number(
          nailsmm.filter((a) => a.nailSize == mmmValue.replace("mm", ""))[0]
            .costPerUnit * Number(formData.job.matslateTileNailsActReq)
        ).toFixed(2);
      }
      formData.job.matleadFlashingsQty = Math.ceil(
        formData.job.leadWork *
          materialRates.filter((a) => a.materialName === "Lead Flashings")[0]
            .amountPerUnit
      ).toFixed(2);

      if (Number(formData.job.matleadFlashingsQty) % 3 == 0) {
        formData.job.matleadFlashingsActReq = Math.ceil(
          formData.job.matleadFlashingsQty
        ).toFixed(2);
      } else {
        let var1 = Number(formData.job.matleadFlashingsQty) + 3;
        console.log("var1", var1, formData.job.matleadFlashingsQty);
        formData.job.matleadFlashingsActReq = Math.ceil(
          var1 - (formData.job.matleadFlashingsQty % 3)
        ).toFixed(2);
      }
      formData.job.matleadFlashingsCost = (
        materialRates.filter((a) => a.materialName === "Lead Flashings")[0]
          .costPerUnit * formData.job.matleadFlashingsActReq
      ).toFixed(2);
      formData.job.matsandQty =
        Number(formData.job.hipsSand) +
        Number(formData.job.ridgeSand) +
        Number(formData.job.vergeSand) +
        Number(formData.job.valleysand);

      formData.job.matsandActReq = Math.ceil(
        Number(formData.job.matsandQty)
      ).toFixed(2);

      formData.job.matsandCost = (
        formData.job.matsandActReq *
        materialRates.filter((a) => a.materialName === "Sand")[0].costPerUnit
      ).toFixed(2);

      if (formData.job.hipsDryFix === 1) {
        formData.job.dryHipsRidgeKit = formData.job.hips * 1.1;
      } else {
        formData.job.dryHipsRidgeKit = 0;
      }

      if (formData.job.ridgeDryFix === 1) {
        formData.job.dryRidgesRidgeKit = formData.job.ridge * 1.1;
      } else {
        formData.job.dryRidgesRidgeKit = 0;
      }
      // Dry ridge kit
      formData.job.matdryRidgeKitQty = (
        formData.job.dryRidgesRidgeKit + formData.job.dryHipsRidgeKit
      ).toFixed(2);

      if (Number(formData.job.matdryRidgeKitQty) % 6 == 0) {
        formData.job.matdryRidgeKitActReq = Math.ceil(
          formData.job.matdryRidgeKitQty
        ).toFixed(2);
      } else {
        let var5 = Number(formData.job.matdryRidgeKitQty) + 6;
        formData.job.matdryRidgeKitActReq = (
          var5 -
          (formData.job.matdryRidgeKitQty % 6)
        ).toFixed(2);
      }
      formData.job.matdryRidgeKitCost = (
        formData.job.matdryRidgeKitActReq *
        materialRates.filter((a) => a.materialName === "Dry Ridge Kit")[0]
          .costPerUnit
      ).toFixed(2);

      if (formData.job.vergeDryFix === 1) {
        formData.job.matdryVergeQty = (
          formData.job.verge *
          tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
            .battenPerMetre
        ).toFixed(2);
      } else {
        formData.job.matdryVergeQty = 0;
      }
      formData.job.matdryVergeActReq = Math.ceil(
        Number(formData.job.matdryVergeQty)
      ).toFixed(2);
      console.log("YYYY4", formData.job.matdryVergeActReq);

      formData.job.matdryVergeCost = (
        Number(formData.job.matdryVergeActReq) *
        Number(
          materialRates.filter(
            (a) => a.materialName === "Dry Verge Unit (Tile Only)"
          )[0].costPerUnit
        )
      ).toFixed(2);

      formData.job.matboxGutterLeadQty = (
        formData.job.boxGutter *
        materialRates.filter((a) => a.materialName == "Box Gutter Lead")[0]
          .amountPerUnit
      ).toFixed(2);
      if (Number(formData.job.matboxGutterLeadQty) % 3 == 0) {
        formData.job.matboxGutterLeadActReq = Math.ceil(
          formData.job.matboxGutterLeadQty
        ).toFixed(2);
      } else {
        let var4 = Number(formData.job.matboxGutterLeadQty) + 3;

        formData.job.matboxGutterLeadActReq = (
          var4 -
          (Number(formData.job.matboxGutterLeadQty) % 3)
        ).toFixed(2);
      }

      formData.job.matboxGutterLeadCost = (
        formData.job.matboxGutterLeadActReq *
        materialRates.filter((a) => a.materialName == "Box Gutter Lead")[0]
          .costPerUnit
      ).toFixed(2);

      //khub71

      formData.job.mateaveFeltQty = (
        materialRates.filter((a) => a.materialName === "Eave Felt")[0]
          .amountPerUnit * formData.job.eaveCourse
      ).toFixed(2);

      if (formData.job.mateaveFeltQty % 16 !== 0) {
        let var4 = Number(formData.job.mateaveFeltQty) + 16;

        formData.job.mateaveFeltActReq = Math.ceil(
          var4 - (Number(formData.job.mateaveFeltQty) % 16)
        ).toFixed(2);

        // formData.job.mateaveFeltQty + 16 - (formData.job.mateaveFeltQty % 16);
      } else {
        formData.job.mateaveFeltActReq = Math.ceil(
          Number(formData.job.mateaveFeltQty)
        ).toFixed(2);
      }
      formData.job.mateaveFeltCost = (
        formData.job.mateaveFeltActReq *
        materialRates.filter((a) => a.materialName === "Eave Felt")[0]
          .costPerUnit
      ).toFixed(2);
      formData.job.matroofingMembraneQty = (
        Number(formData.job.area) *
        Number(
          materialRates.filter((a) => a.materialName == "Roofing Membrane")[0]
            .amountPerUnit
        )
      ).toFixed(2);

      if (formData.job.matroofingMembraneQty % 50 !== 0) {
        formData.job.matroofingMembraneActReq = Math.ceil(
          Number(formData.job.matroofingMembraneQty) +
            50 -
            (Number(formData.job.matroofingMembraneQty) % 50)
        ).toFixed(2);
      } else {
        formData.job.matroofingMembraneActReq = Math.ceil(
          Number(formData.job.matroofingMembraneQty)
        ).toFixed(2);
      }
      formData.job.matroofingMembraneCost = (
        formData.job.matroofingMembraneActReq *
        materialRates.filter((a) => a.materialName === "Roofing Membrane")[0]
          .costPerUnit
      ).toFixed(2);

      formData.job.matEaveTilesQty =
        formData.job.ridge * 12 + formData.job.eaveCourse * 6;

      formData.job.matEaveTilesActReq = Math.ceil(
        formData.job.matEaveTilesQty
      ).toFixed(2);
      console.log("UUU", formData.job.matEaveTilesActReq);
      formData.job.matEaveTilesCost = parseFloat(
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .costPerEaveTile * formData.job.matEaveTilesActReq
      ).toFixed(2);
      console.log("www", formData.job.matEaveTilesCost);

      formData.job.matventTilesQty = formData.job.ventTiles;

      formData.job.matventTilesActReq = Math.ceil(
        formData.job.matventTilesQty
      ).toFixed(2);

      formData.job.matventTilescost = (
        formData.job.matventTilesActReq *
        materialRates.filter((a) => a.materialName === "Vent Tile")[0]
          .costPerUnit
      ).toFixed(2);

      formData.job.matleadSlateQty = formData.job.leadSlate;

      formData.job.matleadslateActReq = Math.ceil(
        formData.job.matleadSlateQty
      ).toFixed(2);

      formData.job.matleadSlatecost = (
        formData.job.matleadslateActReq *
        materialRates.filter((a) => a.materialName === "Lead Saddle")[0]
          .costPerUnit
      ).toFixed(2);

      formData.job.matbattenNailsQty = (
        formData.job.area *
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .battenNailsPSQM
      ).toFixed(2);
      formData.job.matbattenNailsActReq = Math.ceil(
        formData.job.matbattenNailsQty
      ).toFixed(2);
      console.log("CCC battennails", formData.job.matbattenNailsActReq);
      formData.job.matbattenNailsCost = (
        formData.job.matbattenNailsActReq *
        materialRates.filter((a) => a.materialName === "Batten Nails")[0]
          .costPerUnit
      ).toFixed(2);

      formData.job.matdiscRivetsActReq = Math.ceil(
        formData.job.matdiscRivetsQty
      ).toFixed(2);
      formData.job.matdiscRivetsCost = (
        formData.job.matdiscRivetsActReq *
        materialRates.filter((a) => a.materialName === "Disc Rivetts")[0]
          .costPerUnit
      ).toFixed(2);

      formData.job.dryValleythreeM = 0;

      if (formData.job.valleyDryFix === 1) {
        formData.job.dryValleythreeM = 3;
      }

      if (formData.job.valleyDryFix === 1) {
        formData.job.matleadValleysQty = 3;
        console.log("1 valleydryfix", formData.job.valleyDryFix === 1);
      } else if (
        formData.job.valleyTilesValleys === 1 &&
        formData.job.valleyDryFix === 0
      ) {
        formData.job.matleadValleysQty = 3;
      } else {
        formData.job.matleadValleysQty = (
          Number(formData.job.valley) *
            materialRates.filter((a) => a.materialName === "Lead Valleys")[0]
              .amountPerUnit +
          Number(formData.job.dryValleythreeM)
        ).toFixed(2);
      }
      if (Number(formData.job.matleadValleysQty) % 3 === 0) {
        formData.job.matleadValleysActReq = Math.ceil(
          formData.job.matleadValleysQty
        ).toFixed(2);
      } else {
        formData.job.matleadValleysActReq = Math.ceil(
          Number(formData.job.matleadValleysQty) +
            3 -
            (Number(formData.job.matleadValleysQty) % 3)
        ).toFixed(2);
      }
      formData.job.matleadValleysCost = (
        materialRates.filter((a) => a.materialName === "Lead Valleys")[0]
          .costPerUnit * formData.job.matleadValleysActReq
      ).toFixed(2);

      if (formData.job.valleyDryFix === 1) {
        formData.job.matdryValleyQty = (
          formData.job.valley *
          materialRates.filter((a) => a.materialName === "Lead Valleys")[0]
            .amountPerUnit
        ).toFixed(2);
      } else {
        formData.job.matdryValleyQty = 0;
      }
      if (formData.job.matdryValleyQty % 3 === 0) {
        formData.job.matdryValleyActReq = Math.ceil(
          formData.job.matdryValleyQty
        ).toFixed(2);
      } else {
        formData.job.matdryValleyActReq = (
          Number(formData.job.matdryValleyQty) +
          3 -
          Number(formData.job.matdryValleyQty % 3)
        ).toFixed(2);
      }
      //Continue
      formData.job.matdryValleyCost = Number(
        materialRates.filter((a) => a.materialName === "GRP Valley Tray")[0]
          .costPerUnit * formData.job.matdryValleyActReq
      ).toFixed(2);
      console.log(
        "GRP Valley Tray",
        formData.job.matdryValleyActReq,
        formData.job.matdryValleyCost
      );

      if (formData.job.valleyTilesValleys === 0) {
        formData.job.matValleyTilesValleysQty = 0;
      } else {
        formData.job.matValleyTilesValleysQty =
          tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
            .valleyTilesPerLM * formData.job.valley;
      }

      formData.job.matValleyTilesValleysActReq = Math.ceil(
        formData.job.matValleyTilesValleysQty
      ).toFixed(2);
      formData.job.matValleyTilesValleysCost = (
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .costPerValleyTile * formData.job.matValleyTilesValleysActReq
      ).toFixed(2);

      //Hips
      if (formData.job.hipBonnet == 1) {
        formData.job.mathipsMaterialQty = 0;

        console.log("Error1hip876", formData.job.mathipsMaterialQty);
      } else if (
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .ridgeRequired === "Concrete"
      ) {
        formData.job.mathipsMaterialQty = (
          formData.job.hips *
          materialRates.filter((a) => a.materialName === "Concrete Ridge")[0]
            .amountPerUnit
        ).toFixed(2);
        console.log("Error3hip", formData.job.mathipsMaterialQty);
      } else if (
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .ridgeRequired === "Clay"
      ) {
        formData.job.mathipsMaterialQty = (
          formData.job.hips *
          materialRates.filter((a) => a.materialName === "Clay Ridge")[0]
            .amountPerUnit
        ).toFixed(2);
      }
      formData.job.mathipsMaterialActReq = Math.ceil(
        formData.job.mathipsMaterialQty
      ).toFixed(2);

      if (
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .ridgeRequired === "Concrete"
      ) {
        formData.job.matridgeMaterialQty = (
          formData.job.ridge *
          materialRates.filter((a) => a.materialName === "Concrete Ridge")[0]
            .amountPerUnit
        ).toFixed(2);
      }
      if (
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .ridgeRequired === "Clay"
      ) {
        formData.job.matridgeMaterialQty = (
          formData.job.ridge *
          materialRates.filter((a) => a.materialName === "Clay Ridge")[0]
            .amountPerUnit
        ).toFixed(2);
      }

      formData.job.matridgeMaterialActReq = Math.ceil(
        formData.job.matridgeMaterialQty
      ).toFixed(2);
      if (
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .ridgeRequired === "Concrete"
      ) {
        formData.job.matridgeCost = (
          formData.job.matridgeMaterialActReq *
          materialRates.filter((a) => a.materialName === "Concrete Ridge")[0]
            .costPerUnit
        ).toFixed(2);
        formData.job.mathipsCost = (
          formData.job.mathipsMaterialActReq *
          materialRates.filter((a) => a.materialName === "Concrete Ridge")[0]
            .costPerUnit
        ).toFixed(2);
      } else {
        formData.job.matridgeCost = (
          formData.job.matridgeMaterialActReq *
          materialRates.filter((a) => a.materialName === "Clay Ridge")[0]
            .costPerUnit
        ).toFixed(2);
        formData.job.mathipsCost = (
          formData.job.mathipsMaterialActReq *
          materialRates.filter((a) => a.materialName === "Clay Ridge")[0]
            .costPerUnit
        ).toFixed(2);
      }

      //bonnets
      if (formData.job.hipBonnet == 0) {
        formData.job.matBonnetsQty = 0;
      } else if (formData.job.hipBonnet == 1) {
        formData.job.matBonnetsQty =
          formData.job.hips *
          tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
            .bonnetsPerLM;
      }

      formData.job.matBonnetsActReq = Math.ceil(
        formData.job.matBonnetsQty
      ).toFixed(2);
      formData.job.matBonnetsCost = (
        formData.job.matBonnetsActReq *
        tilesData.filter((a) => a.tileId === formData.job.tileId)[0]
          .costPerBonnet
      ).toFixed(2);

      formData.job.matventTilesMaterialQty = formData.job.ventTiles.toFixed(2);
      formData.job.matventTilesMaterialActReq = Math.ceil(
        formData.job.matventTilesMaterialQty
      ).toFixed(2);
      formData.job.matventTilescostCost = (
        formData.job.matventTilesMaterialActReq *
        materialRates.filter((a) => a.materialName === "Vent Tile")[0]
          .costPerUnit
      ).toFixed(2);

      formData.job.matleadSlateMaterialQty = formData.job.leadSlate.toFixed(2);
      formData.job.matleadSlateMaterialActReq = Math.ceil(
        formData.job.matleadSlateMaterialQty
      ).toFixed(2);
      formData.job.matleadSlatecost = (
        formData.job.matleadSlateMaterialActReq *
        materialRates.filter((a) => a.materialName === "Lead Saddle")[0]
          .costPerUnit
      ).toFixed(2);

      // formData.job.matcementQty = Math.ceil(formData.job.matsandActReq * 0.34);
      formData.job.matcementQty = Math.ceil(formData.job.matsandQty * 0.34);
      formData.job.matcementActReq = Math.ceil(
        formData.job.matcementQty
      ).toFixed(2);

      formData.job.matcementCost = (
        formData.job.matcementActReq *
        materialRates.filter((a) => a.materialName === "Cement")[0].costPerUnit
      ).toFixed(2);

      formData.job.matSealantsQty = (
        formData.job.leadWork /
        materialRates.filter((a) => a.materialName === "Sealants")[0]
          .amountPerUnit
      ).toFixed(2);

      formData.job.matSealantsActReq = Math.ceil(
        formData.job.matSealantsQty
      ).toFixed(2);
      formData.job.matSealantsCost = (
        formData.job.matSealantsActReq *
        materialRates.filter((a) => a.materialName === "Sealants")[0]
          .costPerUnit
      ).toFixed(2);
      if (formData.job.vergeDryFix === 0) {
        formData.job.matUnderCloakQty = (
          formData.job.verge *
          materialRates.filter(
            (a) => a.materialName === "Cement Fibre Under Cloak"
          )[0].amountPerUnit
        ).toFixed(2);
      } else {
        formData.job.matUnderCloakQty = 0;
      }

      formData.job.matUnderCloakActReq = Math.ceil(
        formData.job.matUnderCloakQty
      ).toFixed(2);
      formData.job.matUnderCloakCost = (
        formData.job.matUnderCloakActReq *
        materialRates.filter(
          (a) => a.materialName === "Cement Fibre Under Cloak"
        )[0].costPerUnit
      ).toFixed(2);

      //

      if (formData.job.hipsDryFix === 1) {
        formData.job.matHipSupportTraysQty = formData.job.hips * 1.1;
      } else {
        formData.job.matHipSupportTraysQty = 0;
      }

      formData.job.matHipSupportTraysActReq = Math.ceil(
        formData.job.matHipSupportTraysQty
      ).toFixed(2);
      if (Number(formData.job.matHipSupportTraysActReq) % 3 == 0) {
        formData.job.matHipSupportTraysActReq = Math.ceil(
          formData.job.matHipSupportTraysQty
        ).toFixed(2);
      } else {
        let var5 = Number(formData.job.matHipSupportTraysQty) + 3;
        console.log("var5", var5, formData.job.matHipSupportTraysQty);
        formData.job.matHipSupportTraysActReq = (
          var5 -
          (formData.job.matHipSupportTraysQty % 3)
        ).toFixed(2);
      }
      formData.job.matHipSupportTraysCost = (
        formData.job.matHipSupportTraysActReq *
        materialRates.filter((a) => a.materialName === "HIP Supoort Tray")[0]
          .costPerUnit
      ).toFixed(2);

      formData.job.materialCost = (
        Number(formData.job.matslateCost) +
        Number(formData.job.matleftHandVergeCost) +
        Number(formData.job.mateaveFeltCost) +
        Number(formData.job.eaveCombFillerCost) +
        Number(formData.job.matroofingMembraneCost) +
        Number(formData.job.matbattenCost) +
        Number(formData.job.matbattenNailsCost) +
        Number(formData.job.matslateTileNailsCost) +
        Number(formData.job.matleadFlashingsCost) +
        Number(formData.job.matleadValleysCost) +
        Number(formData.job.matdryValleyCost) +
        Number(formData.job.matridgeCost) +
        Number(formData.job.mathipsCost) +
        Number(formData.job.matsandCost) +
        Number(formData.job.matcementCost) +
        Number(formData.job.matSealantsCost) +
        Number(formData.job.matdryRidgeKitCost) +
        Number(formData.job.matHipSupportTraysCost) +
        Number(formData.job.matUnderCloakCost) +
        Number(formData.job.matdryVergeCost) +
        Number(formData.job.matventTilescostCost) +
        Number(formData.job.matleadSlatecost) +
        Number(formData.job.matboxGutterLeadCost) +
        Number(formData.job.matEaveTilesCost)
      ).toFixed(2);

      formData.job.labourCost = (
        Number(formData.tilesRates * formData.job.area) +
        Number(rates.hips * formData.job.hips) +
        Number(rates.ridge * formData.job.ridge) +
        Number(rates.valleys * formData.job.valley) +
        Number(rates.verge * formData.job.verge) +
        Number(rates.leadWork * formData.job.leadWork) +
        Number(rates.roofWindow * formData.job.roofWindow) +
        Number(formData.job.ventTilesLabour) +
        Number(formData.job.leadSlateLabour) +
        Number(formData.job.boxGutterLabour)
      ).toFixed(2);

      formData.job.totalCost = calculateTotalCost(formData);
      formData.job.totalCostWithVat = (
        Number(formData.job.totalCost) * 1.2
      ).toFixed(2);
    }

    let obj = { ...formData };

    await setFormData(obj);

    setSelectedTab(3);

    setCalculatedOnce(true);
  };
  // console.log(
  //   "nailsmm:",
  //   nailsmm,
  //   nailsmm.filter(
  //     (a) => a.nailSize == tilesData.filter((a) => a.tileId == 1)[0].nailSize
  //   )
  // );
  const currentYear = new Date().getFullYear();
  return (
    <>
      <Navbar toggleMobileMenu={props.toggleMobileMenu} />
      <div id="layoutSidenav">
        <SideBar
          setMobileMenuOpen={props.setMobileMenuOpen}
          isMobileMenuOpen={props.isMobileMenuOpen}
        />

        <div id="layoutSidenav_content">
          <main>
            <header
              className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10"
              id="dashMobile3"
            >
              <div className="container-xl px-4">
                <div className="page-header-content pt-4">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-auto mt-4">
                      <h1 className="page-header-title" />
                      <div className="page-header-icon">
                        <h1 className="page-header-title">
                          Create a quote&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp;
                        </h1>
                      </div>
                      <div className="page-header-subtitle">
                        From here you can create<br></br> a new quote for
                        materials which<br></br> can automatically be sent{" "}
                        <br></br>to local suppliers.
                      </div>
                    </div>
                  </div>
                  <nav className="mt-4 rounded" aria-label="breadcrumb">
                    <ol className="breadcrumb px-3 py-2 rounded mb-0">
                      <li className="breadcrumb-item">
                        <a>Home</a>
                      </li>
                      <li className="breadcrumb-item active">Create a quote</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </header>
            {/* Main page content*/}
            {/* Main page content*/}
            <div className="container-xl px-4 mt-n10">
              {/* Wizard card example with navigation*/}
              <div className="card">
                <div className="card-header border-bottom">
                  {/* Wizard navigation*/}
                  <div className="nav nav-pills nav-justified flex-column flex-xl-row nav-wizard custom-cursor-none">
                    {/* Wizard navigation item 1*/}
                    <a
                      className={`nav-item nav-link ${
                        selectedTab == 1 ? "active" : ""
                      }`}
                      id="wizard1-tab"
                    >
                      <div className="wizard-step-icon">1</div>
                      <div className="wizard-step-text">
                        <div className="wizard-step-text-name">
                          Client Details
                        </div>
                        {/* <div className="wizard-step-text-details">
                          Enter client details here
                        </div> */}
                      </div>
                    </a>
                    {/* Wizard navigation item 2*/}
                    <a
                      className={`nav-item nav-link ${
                        selectedTab == 2 ? "active" : ""
                      }`}
                      id="wizard2-tab"
                    >
                      <div className="wizard-step-icon">2</div>
                      <div className="wizard-step-text">
                        <div className="wizard-step-text-name">Job Details</div>
                        {/* <div className="wizard-step-text-details">
                          Click here to enter job details
                        </div> */}
                      </div>
                    </a>
                    {/* Wizard navigation item 3*/}
                    <a
                      className={`nav-item nav-link ${
                        selectedTab == 3 ? "active" : ""
                      }`}
                      id="wizard3-tab"
                    >
                      <div className="wizard-step-icon">3</div>
                      <div className="wizard-step-text">
                        <div className="wizard-step-text-name">Job Summary</div>
                        {/* <div className="wizard-step-text-details">
                          Click here to see a summary of materials &amp; labour
                          reqquired.
                        </div> */}
                      </div>
                    </a>
                    {/* Wizard navigation item 4*/}
                    <a
                      className={`nav-item nav-link ${
                        selectedTab == 4 ? "active" : ""
                      }`}
                    >
                      <div className="wizard-step-icon">4</div>
                      <div className="wizard-step-text">
                        <div className="wizard-step-text-name">Send Quotes</div>
                        {/* <div className="wizard-step-text-details">
                          Click here to 
                          get quotes
                        </div> */}
                      </div>
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div className="tab-content" id="cardTabContent">
                    {/* Wizard tab pane item 1*/}
                    {selectedTab == 1 && (
                      <div
                        className="tab-pane py-5 py-xl-10 fade show active"
                        id="wizard1"
                      >
                        <div className="row justify-content-center">
                          <div className="col-xxl-6 col-xl-8">
                            <h3 className="text-primary">Step 1</h3>
                            <h5 className="card-title mb-4">Client Details</h5>
                            <form
                              onSubmit={() => {
                                setSelectedTab(2);
                              }}
                            >
                              <div className="mb-3">
                                <label
                                  className="small mb-1"
                                  htmlFor="inputUsername"
                                >
                                  Client Name
                                </label>
                                <input
                                  required
                                  value={formData.client.clientName || ""}
                                  onChange={handleChangeClient}
                                  name="clientName"
                                  className="form-control"
                                  id="inputUsername"
                                />
                              </div>
                              <div className="mb-3">
                                <label
                                  className="small mb-1"
                                  htmlFor="inputEmailAddress"
                                >
                                  Client Email
                                </label>
                                <input
                                  required
                                  value={formData.client.clientEmail || ""}
                                  onChange={handleChangeClient}
                                  name="clientEmail"
                                  type="email"
                                  className="form-control"
                                  id="inputEmailAddress"
                                />
                              </div>

                              <div className="mb-3">
                                <label
                                  className="small mb-1"
                                  htmlFor="inputEmailAddress"
                                >
                                  Contact Number
                                </label>
                                <input
                                  required
                                  value={formData.client.contactNo || ""}
                                  onChange={handleChangeClient}
                                  name="contactNo"
                                  className="form-control"
                                  id="inputEmailAddress"
                                />
                              </div>
                              <div className="mb-3">
                                <label
                                  className="small mb-1"
                                  htmlFor="inputEmailAddress"
                                >
                                  Address
                                </label>
                                <textarea
                                  onChange={handleChangeClient}
                                  name="address"
                                  value={formData.client.address || ""}
                                  className="form-control "
                                  id="exampleFormControlTextarea1"
                                  rows={5}
                                />
                              </div>
                              <div className="mb-3">
                                <label
                                  className="small mb-1"
                                  htmlFor="PostCode"
                                >
                                  Post Code
                                </label>
                                <input
                                  required
                                  value={formData.client.postCode || ""}
                                  onChange={handleChangeClient}
                                  name="postCode"
                                  className="form-control"
                                  id="PostCode"
                                />
                              </div>
                              <div className="d-flex justify-content-between">
                                <button
                                  className="btn btn-light disabled"
                                  type="submit"
                                  disabled=""
                                  style={{
                                    visibility: "hidden",
                                    cursor: "default",
                                  }}
                                >
                                  Previous
                                </button>
                                <button
                                  className="btn btn-primary"
                                  type="submit"
                                >
                                  Next
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* Wizard tab pane item 2*/}
                    {selectedTab == 2 && (
                      <div
                        className="tab-pane py-5 py-xl-10 fade  show active"
                        id="wizard2"
                      >
                        <div className="row justify-content-center">
                          <div className="col-xxl-6 col-xl-8">
                            <h3 className="text-primary">Step 2</h3>
                            <h5 className="card-title mb-4">Roof Details</h5>
                            <form onSubmit={handleSubmit}>
                              <div className="mb-3">
                                <label htmlFor="TileType">
                                  Covering Selector
                                </label>
                                <select
                                  required
                                  value={"" + formData.job.tileTypeId}
                                  name="tileTypeId"
                                  onChange={handleChange}
                                  className="form-control"
                                  id="TileType"
                                >
                                  <option value="" disabled selected="">
                                    Please select...
                                  </option>

                                  <option value="1">Slate</option>

                                  <option value="2">Small Tile</option>
                                  <option value="3">Big Tile</option>
                                </select>
                              </div>
                              <div className="mb-3">
                                <label htmlFor="Tile">Pick your tile</label>
                                {tileValues.length !== 0 && (
                                  <select
                                    required
                                    value={"" + formData.job.tileId || ""}
                                    className="form-control"
                                    name="tileId"
                                    onChange={handleChange}
                                  >
                                    <option value="">
                                      Select Specific Tile/Slate
                                    </option>
                                    {tileValues.map((option) => (
                                      <option value={"" + option.tileId}>
                                        {option.tileName}
                                      </option>
                                    ))}
                                  </select>
                                )}
                              </div>
                              <div className="mb-3">
                                <label htmlFor="exampleFormControlSelect1">
                                  Work Type
                                </label>
                                <select
                                  rrequired
                                  value={formData.job.workType || ""}
                                  onChange={handleChange}
                                  name="workType"
                                  className="form-control"
                                  id="exampleFormControlSelect1"
                                >
                                  <option value="1">New Work</option>
                                  <option value="2">Strip &amp; Re-New</option>
                                </select>
                              </div>
                              <h5 className="card-title mb-4">
                                Meterage details
                              </h5>
                              <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1">
                                  Area (sqm)
                                </label>
                                <input
                                  required
                                  value={formData.job.area && formData.job.area}
                                  onChange={handleChange}
                                  name="area"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  type="text"
                                />
                              </div>
                              {(formData.job.tileTypeId == 1 ||
                                formData.job.tileTypeId == 2) && (
                                <div className="mb-3 row">
                                  <div className="col-md-6">
                                    <label htmlFor="exampleFormControlInput1">
                                      Verge (linear metres)
                                    </label>
                                    <input
                                      required
                                      value={
                                        formData.job.verge && formData.job.verge
                                      }
                                      onChange={handleChange}
                                      name="verge"
                                      className="form-control"
                                      id="exampleFormControlInput1"
                                      type="text"
                                    />
                                  </div>{" "}
                                  <div className="col-md-6">
                                    <label htmlFor="halfWidthInput">
                                      Dry Fix
                                    </label>
                                    <select
                                      required
                                      value={formData.job.vergeDryFix}
                                      name="vergeDryFix"
                                      onChange={handleChange}
                                      className="form-control"
                                    >
                                      <option value="" disabled selected="">
                                        Please select...
                                      </option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                    </select>
                                  </div>
                                </div>
                              )}

                              {formData.job.tileTypeId == 3 && (
                                <div className="mb-3 row">
                                  <div className="col-md-3">
                                    <label
                                      style={{ fontSize: "11px" }}
                                      htmlFor="exampleFormControlInput1"
                                    >
                                      Verge (linear metres)
                                    </label>
                                    <input
                                      required
                                      value={
                                        formData.job.verge && formData.job.verge
                                      }
                                      onChange={handleChange}
                                      name="verge"
                                      className="form-control"
                                      id="exampleFormControlInput1"
                                      type="text"
                                    />
                                  </div>{" "}
                                  <div className="col-md-3">
                                    <label
                                      style={{ fontSize: "11px" }}
                                      htmlFor="halfWidthInput"
                                    >
                                      Dry Fix
                                    </label>
                                    <select
                                      required
                                      value={formData.job.vergeDryFix}
                                      name="vergeDryFix"
                                      onChange={handleChange}
                                      className="form-control"
                                    >
                                      <option value="" disabled selected="">
                                        Please select...
                                      </option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                    </select>
                                  </div>{" "}
                                  <div className="col-md-3">
                                    <label
                                      style={{ fontSize: "11px" }}
                                      htmlFor="halfWidthInput"
                                    >
                                      LH Verge Tiles Required?
                                    </label>
                                    <select
                                      required
                                      value={
                                        formData.job.leftHandVergeTileRequired
                                      }
                                      name="leftHandVergeTileRequired"
                                      onChange={handleChange}
                                      className="form-control"
                                    >
                                      <option value="" disabled selected="">
                                        Please select...
                                      </option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                    </select>
                                  </div>
                                  <div className="col-md-3">
                                    <label
                                      style={{ fontSize: "11px" }}
                                      htmlFor="exampleFormControlInput1"
                                    >
                                      LH Verge (linear metres)
                                    </label>
                                    <input
                                      required
                                      value={
                                        formData.job.leftHandVergeLm &&
                                        formData.job.leftHandVergeLm
                                      }
                                      onChange={handleChange}
                                      name="leftHandVergeLm"
                                      className="form-control"
                                      id="exampleFormControlInput1"
                                      type="text"
                                    />
                                  </div>{" "}
                                </div>
                              )}

                              {(formData.job.tileTypeId == 1 ||
                                formData.job.tileTypeId == 3) && (
                                <>
                                  <div className="mb-3 row">
                                    <div className="col-md-6">
                                      <label htmlFor="exampleFormControlInput1">
                                        Valleys (linear metres)
                                      </label>
                                      <input
                                        required
                                        value={
                                          formData.job.valley &&
                                          formData.job.valley
                                        }
                                        onChange={handleChange}
                                        name="valley"
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                        type="text"
                                      />
                                    </div>{" "}
                                    <div className="col-md-6">
                                      <label htmlFor="halfWidthInput">
                                        Dry Fix
                                      </label>
                                      <select
                                        required
                                        value={formData.job.valleyDryFix}
                                        name="valleyDryFix"
                                        onChange={handleChange}
                                        className="form-control"
                                      >
                                        <option value="" disabled selected="">
                                          Please select...
                                        </option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="mb-3 row">
                                    <div className="col-md-6">
                                      <label htmlFor="exampleFormControlInput1">
                                        Hips (linear metres)
                                      </label>
                                      <input
                                        value={
                                          formData.job.hips && formData.job.hips
                                        }
                                        required
                                        onChange={handleChange}
                                        name="hips"
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                        type="text"
                                      />
                                    </div>{" "}
                                    <div className="col-md-6">
                                      <label htmlFor="halfWidthInput">
                                        Dry Fix
                                      </label>
                                      <select
                                        required
                                        value={formData.job.hipsDryFix}
                                        name="hipsDryFix"
                                        onChange={handleChange}
                                        className="form-control"
                                      >
                                        <option value="" disabled selected="">
                                          Please select...
                                        </option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                      </select>
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className="mb-3 row">
                                <div className="col-md-6">
                                  <label htmlFor="exampleFormControlInput1">
                                    Ridge (linear metres)
                                  </label>
                                  <input
                                    required
                                    value={
                                      formData.job.ridge && formData.job.ridge
                                    }
                                    onChange={handleChange}
                                    name="ridge"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    type="text"
                                  />
                                </div>{" "}
                                <div className="col-md-6">
                                  <label htmlFor="halfWidthInput">
                                    Dry Fix
                                  </label>
                                  <select
                                    required
                                    value={formData.job.ridgeDryFix}
                                    name="ridgeDryFix"
                                    onChange={handleChange}
                                    className="form-control"
                                  >
                                    <option value="" disabled selected="">
                                      Please select...
                                    </option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                  </select>
                                </div>
                              </div>
                              {formData.job.tileTypeId == 2 && (
                                <>
                                  <div className="mb-3 row">
                                    <div className="col-md-4">
                                      <label htmlFor="exampleFormControlInput1">
                                        Valleys (linear metres)
                                      </label>
                                      <input
                                        required
                                        value={
                                          formData.job.valley &&
                                          formData.job.valley
                                        }
                                        onChange={handleChange}
                                        name="valley"
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                        type="text"
                                      />
                                    </div>{" "}
                                    <div className="col-md-4">
                                      <label htmlFor="halfWidthInput">
                                        Dry Fix
                                      </label>
                                      <select
                                        required
                                        value={formData.job.valleyDryFix}
                                        name="valleyDryFix"
                                        onChange={handleChange}
                                        className="form-control"
                                      >
                                        <option value="" disabled selected="">
                                          Please select...
                                        </option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                      </select>
                                    </div>
                                    <div className="col-md-4">
                                      <label htmlFor="halfWidthInput">
                                        Valley Tiles
                                      </label>
                                      <select
                                        required
                                        value={formData.job.valleyTilesValleys}
                                        name="valleyTilesValleys"
                                        onChange={handleChange}
                                        className="form-control"
                                      >
                                        <option value="" disabled selected="">
                                          Please select...
                                        </option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="mb-3 row">
                                    <div className="col-md-4">
                                      <label htmlFor="exampleFormControlInput1">
                                        Hips (metres)
                                      </label>
                                      <input
                                        required
                                        value={
                                          formData.job.hips && formData.job.hips
                                        }
                                        onChange={handleChange}
                                        name="hips"
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                        type="text"
                                      />
                                    </div>{" "}
                                    <div className="col-md-4">
                                      <label htmlFor="halfWidthInput">
                                        Dry Fix
                                      </label>
                                      <select
                                        required
                                        value={formData.job.hipsDryFix}
                                        name="hipsDryFix"
                                        onChange={handleChange}
                                        className="form-control"
                                      >
                                        <option value="" disabled selected="">
                                          Please select...
                                        </option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                      </select>
                                    </div>
                                    {/* <div className="col-md-3">
                                      <label htmlFor="halfWidthInput">
                                        Hips ValleyTiles
                                      </label>
                                      <select
                                        required
                                        value={formData.job.valleyTilesHips}
                                        name="valleyTilesHips"
                                        onChange={handleChange}
                                        className="form-control"
                                      >
                                        <option value="" disabled selected="">
                                          Please select...
                                        </option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                      </select>
                                    </div> */}
                                    <div className="col-md-4">
                                      <label htmlFor="halfWidthInput">
                                        Hip Bonnet
                                      </label>
                                      <select
                                        required
                                        value={"" + formData.job.hipBonnet}
                                        name="hipBonnet"
                                        onChange={handleChange}
                                        className="form-control"
                                      >
                                        <option value="" disabled selected="">
                                          Please select...
                                        </option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                      </select>
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1">
                                  Leadwork (linear metres)
                                </label>
                                <input
                                  value={
                                    formData.job.leadWork &&
                                    formData.job.leadWork
                                  }
                                  required
                                  onChange={handleChange}
                                  name="leadWork"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  type="text"
                                />
                              </div>
                              <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1">
                                  Roof window
                                </label>
                                <input
                                  required
                                  value={
                                    formData.job.roofWindow &&
                                    formData.job.roofWindow
                                  }
                                  onChange={handleChange}
                                  name="roofWindow"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  type="text"
                                />
                              </div>
                              <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1">
                                  Eave Course (linear metres)
                                </label>
                                <input
                                  required
                                  value={
                                    formData.job.eaveCourse &&
                                    formData.job.eaveCourse
                                  }
                                  onChange={handleChange}
                                  name="eaveCourse"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  type="text"
                                />
                              </div>
                              <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1">
                                  Vent Tiles
                                </label>
                                <input
                                  required
                                  value={
                                    formData.job.ventTiles &&
                                    formData.job.ventTiles
                                  }
                                  onChange={handleChange}
                                  name="ventTiles"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  type="text"
                                />
                              </div>
                              <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1">
                                  Lead Slate
                                </label>
                                <input
                                  required
                                  value={
                                    formData.job.leadSlate &&
                                    formData.job.leadSlate
                                  }
                                  onChange={handleChange}
                                  name="leadSlate"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  type="text"
                                />
                              </div>
                              <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1">
                                  Box Gutter (sqm)
                                </label>
                                <input
                                  required
                                  value={
                                    formData.job.boxGutter &&
                                    formData.job.boxGutter
                                  }
                                  onChange={handleChange}
                                  name="boxGutter"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  type="text"
                                />
                              </div>
                              <h5 className="card-title mb-4">
                                Additional Costs
                              </h5>
                              <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1">
                                  Scaffold Costs
                                </label>
                                <input
                                  required
                                  value={formData.job.scaffoldCost || ""}
                                  onChange={handleChange}
                                  name="scaffoldCost"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  type="text"
                                />
                              </div>
                              <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1">
                                  Waste Costs
                                </label>
                                <input
                                  required
                                  value={formData.job.wasteCost || ""}
                                  onChange={handleChange}
                                  name="wasteCost"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  type="text"
                                />
                              </div>
                              <div className="d-flex justify-content-between">
                                <button
                                  onClick={() => {
                                    setSelectedTab(1);
                                  }}
                                  className="btn btn-light"
                                  type="submit"
                                >
                                  Previous
                                </button>
                                <button
                                  className="btn btn-primary"
                                  type="submit"
                                >
                                  Next
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* Wizard tab pane item 3*/}
                    {selectedTab == 3 && (
                      <div
                        className="tab-pane py-5 py-xl-10 fade  show active"
                        id="wizard3"
                      >
                        <div className="row justify-content-center">
                          <div className="col-xxl-6 col-xl-8">
                            <h2 className="text-primary">
                              Step 3 - Job Summary
                            </h2>
                            <h3 className="text-primary">Materials</h3>

                            {rates.userId && (
                              <>
                                <div></div>
                                {formData.job.tileTypeId == 1 && (
                                  <table id="">
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th></th>
                                        {/* <th>Calculated Amount</th> */}
                                        <th>Required Amount</th>
                                        <th style={{ paddingLeft: "5%" }}>
                                          Cost
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {parseInt(formData.job.matSlateActReq) !=
                                        0 && (
                                        <tr>
                                          <td>Slates</td>
                                          <td style={{ width: "30px" }}></td>
                                          {/* <td>{formData.job.matSlateQty}</td> */}
                                          <td>
                                            <input
                                              className="qouteInputs"
                                              onKeyDown={handleKeyDown}
                                              //
                                              value={
                                                formData.job.matSlateActReq
                                              }
                                              onChange={(e) => {
                                                const obj = { ...formData };
                                                zeroAcceptable = true;
                                                const sanitizedValue =
                                                  e.target.value.replace(
                                                    /[^0-9.]/g,
                                                    ""
                                                  );
                                                const dotCount =
                                                  sanitizedValue.split(".")
                                                    .length - 1;

                                                if (dotCount > 1) {
                                                  return;
                                                }
                                                // if (
                                                //   Number(sanitizedValue) == 0
                                                // ) {
                                                //   return;
                                                // }

                                                obj.job.matSlateActReq =
                                                  Number(sanitizedValue);
                                                setFormData(obj);
                                              }}
                                            />
                                          </td>
                                          <td>£ {formData.job.matslateCost}</td>
                                        </tr>
                                      )}
                                      {parseInt(
                                        formData.job.matslatenHalfActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Slate & Half</td>
                                            <td style={{ width: "30px" }}></td>
                                            {/* <td>{formData.job.matslatenHalfQty}</td> */}
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matslatenHalfActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matslatenHalfActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>
                                            <td className="JobSMob">
                                              £ {formData.job.matslatenHalfCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.mateaveFeltActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Eave Felt</td>
                                            <td style={{ width: "30px" }}></td>
                                            {/* <td>{formData.job.mateaveFeltQty}</td> */}
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job.mateaveFeltActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.mateaveFeltActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>
                                            <td className="JobSMob">
                                              £ {formData.job.mateaveFeltCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matroofingMembraneActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Roofing Membrane</td>
                                            <td style={{ width: "30px" }}></td>
                                            {/* <td>{formData.job.matroofingMembraneQty}</td> */}

                                            <input
                                              className="qouteInputs"
                                              onKeyDown={handleKeyDown}
                                              value={
                                                formData.job
                                                  .matroofingMembraneActReq
                                              }
                                              onChange={(e) => {
                                                const obj = { ...formData };
                                                const sanitizedValue =
                                                  e.target.value.replace(
                                                    /[^0-9.]/g,
                                                    ""
                                                  );
                                                const dotCount =
                                                  sanitizedValue.split(".")
                                                    .length - 1;
                                                if (dotCount > 1) {
                                                  return;
                                                }
                                                obj.job.matroofingMembraneActReq =
                                                  Number(sanitizedValue);
                                                setFormData(obj);
                                              }}
                                            />
                                            <td>
                                              £{" "}
                                              {
                                                formData.job
                                                  .matroofingMembraneCost
                                              }
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(formData.job.matbattenActReq) !=
                                        0 &&
                                        true && (
                                          <tr>
                                            <td>Batten</td>
                                            <td style={{ width: "30px" }}></td>

                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job.matbattenActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matbattenActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>
                                            <td className="JobSMob">
                                              £ {formData.job.matbattenCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matbattenNailsActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Batten Nails</td>
                                            <td style={{ width: "30px" }}></td>
                                            {/* <td>{formData.job.matbattenNailsQty}</td> */}
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matbattenNailsActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matbattenNailsActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>
                                            <td className="JobSMob">
                                              £{" "}
                                              {formData.job.matbattenNailsCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matslateTileNailsActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Slate Nails</td>
                                            <td style={{ width: "30px" }}></td>
                                            {/* <td>{formData.job.matslateTileNailsQty}</td> */}
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matslateTileNailsActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matslateTileNailsActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>
                                            <td className="JobSMob">
                                              £{" "}
                                              {
                                                formData.job
                                                  .matslateTileNailsCost
                                              }
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matdiscRivetsActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Disc Rivets</td>
                                            <td style={{ width: "30px" }}></td>
                                            {/* <td>{formData.job.matdiscRivetsQty}</td> */}
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matdiscRivetsActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matdiscRivetsActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>
                                            <td className="JobSMob">
                                              £ {formData.job.matdiscRivetsCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matleadFlashingsActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Lead Flashings</td>
                                            <td style={{ width: "30px" }}></td>
                                            {/* <td>{formData.job.matleadFlashingsQty}</td> */}
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matleadFlashingsActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matleadFlashingsActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>
                                            <td className="JobSMob">
                                              £{" "}
                                              {
                                                formData.job
                                                  .matleadFlashingsCost
                                              }
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matleadValleysActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Lead Valleys</td>
                                            <td style={{ width: "30px" }}></td>
                                            {/* <td>{formData.job.matleadValleysQty}</td> */}
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matleadValleysActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matleadValleysActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>
                                            <td className="JobSMob">
                                              £{" "}
                                              {formData.job.matleadValleysCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matdryValleyActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Dry Valley</td>
                                            <td style={{ width: "30px" }}></td>
                                            {/* <td>{formData.job.matdryValleyQty}</td> */}
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matdryValleyActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matdryValleyActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>
                                            <td className="JobSMob">
                                              £ {formData.job.matdryValleyCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matridgeMaterialActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Ridge</td>
                                            <td style={{ width: "30px" }}></td>
                                            {/* <td>{formData.job.matridgeMaterialQty}</td> */}
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matridgeMaterialActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matridgeMaterialActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>
                                            <td className="JobSMob">
                                              £ {formData.job.matridgeCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.mathipsMaterialActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Hips</td>
                                            <td style={{ width: "30px" }}></td>
                                            {/* <td>{formData.job.mathipsMaterialQty}</td> */}
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .mathipsMaterialActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.mathipsMaterialActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>
                                            <td className="JobSMob">
                                              £{" "}
                                              {Number(
                                                formData.job.mathipsCost
                                              ).toFixed(2)}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(formData.job.matsandActReq) !=
                                        0 &&
                                        true && (
                                          <tr>
                                            <td>Sand</td>
                                            <td style={{ width: "30px" }}></td>
                                            <input
                                              className="qouteInputs"
                                              onKeyDown={handleKeyDown}
                                              value={formData.job.matsandActReq}
                                              onChange={(e) => {
                                                const obj = { ...formData };
                                                const sanitizedValue =
                                                  e.target.value.replace(
                                                    /[^0-9.]/g,
                                                    ""
                                                  );
                                                const dotCount =
                                                  sanitizedValue.split(".")
                                                    .length - 1;
                                                if (dotCount > 1) {
                                                  return;
                                                }
                                                obj.job.matsandActReq =
                                                  Number(sanitizedValue);
                                                setFormData(obj);
                                              }}
                                            />

                                            {/* <td>{formData.job.matsandQty}</td> */}
                                            <td className="JobSMob">
                                              £ {formData.job.matsandCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(formData.job.matcementActReq) !=
                                        0 &&
                                        true && (
                                          <tr>
                                            <td>Cement</td>
                                            <td style={{ width: "30px" }}></td>
                                            <input
                                              className="qouteInputs"
                                              onKeyDown={handleKeyDown}
                                              value={
                                                formData.job.matcementActReq
                                              }
                                              onChange={(e) => {
                                                const obj = { ...formData };
                                                const sanitizedValue =
                                                  e.target.value.replace(
                                                    /[^0-9.]/g,
                                                    ""
                                                  );
                                                const dotCount =
                                                  sanitizedValue.split(".")
                                                    .length - 1;
                                                if (dotCount > 1) {
                                                  return;
                                                }
                                                obj.job.matcementActReq =
                                                  Number(sanitizedValue);
                                                setFormData(obj);
                                              }}
                                            />

                                            {/* <td>{formData.job.matcementQty}</td> */}
                                            <td className="JobSMob">
                                              £ {formData.job.matcementCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matSealantsActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Sealants</td>
                                            <td style={{ width: "30px" }}></td>
                                            {/* <td>{formData.job.matSealantsQty}</td> */}
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job.matSealantsActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matSealantsActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>
                                            <td className="JobSMob">
                                              £ {formData.job.matSealantsCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matdryRidgeKitActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Dry ridge kit</td>
                                            <td style={{ width: "30px" }}></td>
                                            {/* <td>{formData.job.matdryRidgeKitQty}</td> */}
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matdryRidgeKitActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matdryRidgeKitActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>
                                            <td className="JobSMob">
                                              £{" "}
                                              {formData.job.matdryRidgeKitCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matHipSupportTraysActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>HIP support trays</td>
                                            <td style={{ width: "30px" }}></td>
                                            {/* <td>{formData.job.matHipSupportTraysQty}</td> */}

                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matHipSupportTraysActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matHipSupportTraysActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>
                                            <td className="JobSMob">
                                              £{" "}
                                              {
                                                formData.job
                                                  .matHipSupportTraysCost
                                              }
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matUnderCloakActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Under Cloak</td>
                                            <td style={{ width: "30px" }}></td>
                                            {/* <td>{formData.job.matUnderCloakQty}</td> */}
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matUnderCloakActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matUnderCloakActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>
                                            <td className="JobSMob">
                                              £ {formData.job.matUnderCloakCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matdryVergeActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Dry Verge</td>
                                            <td style={{ width: "30px" }}></td>
                                            {/* <td>{formData.job.matdryVergeQty}</td> */}
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job.matdryVergeActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matdryVergeActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>
                                            <td className="JobSMob">
                                              £ {formData.job.matdryVergeCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matventTilesMaterialActReq
                                      ) != 0 && (
                                        <tr>
                                          <td>Vent tiles</td>
                                          <td style={{ width: "30px" }}></td>
                                          {/* <td>{formData.job.matventTilesMaterialQty}</td> */}
                                          <td>
                                            <input
                                              className="qouteInputs"
                                              onKeyDown={handleKeyDown}
                                              value={
                                                formData.job
                                                  .matventTilesMaterialActReq
                                              }
                                              onChange={(e) => {
                                                zeroAcceptable = true;
                                                const obj = { ...formData };
                                                const sanitizedValue =
                                                  e.target.value.replace(
                                                    /[^0-9.]/g,
                                                    ""
                                                  );
                                                const dotCount =
                                                  sanitizedValue.split(".")
                                                    .length - 1;
                                                if (dotCount > 1) {
                                                  return;
                                                }

                                                obj.job.matventTilesMaterialActReq =
                                                  Number(sanitizedValue);
                                                setFormData(obj);
                                              }}
                                            />
                                          </td>
                                          <td className="JobSMob">
                                            £{" "}
                                            {formData.job.matventTilescostCost}
                                          </td>
                                        </tr>
                                      )}
                                      {parseInt(
                                        formData.job.matleadSlateMaterialActReq
                                      ) && (
                                        <tr>
                                          <td>Lead Slate</td>
                                          <td style={{ width: "30px" }}></td>
                                          {/* <td>{formData.job.matleadSlateMaterialQty}</td> */}
                                          <td>
                                            <input
                                              className="qouteInputs"
                                              onKeyDown={handleKeyDown}
                                              //
                                              value={
                                                formData.job
                                                  .matleadSlateMaterialActReq
                                              }
                                              onChange={(e) => {
                                                zeroAcceptable = true;
                                                const obj = { ...formData };
                                                const sanitizedValue =
                                                  e.target.value.replace(
                                                    /[^0-9.]/g,
                                                    ""
                                                  );
                                                const dotCount =
                                                  sanitizedValue.split(".")
                                                    .length - 1;
                                                if (dotCount > 1) {
                                                  return;
                                                }

                                                obj.job.matleadSlateMaterialActReq =
                                                  Number(sanitizedValue);
                                                setFormData(obj);
                                              }}
                                            />
                                          </td>
                                          <td className="JobSMob">
                                            £ {formData.job.matleadSlatecost}
                                          </td>
                                        </tr>
                                      )}
                                      {parseInt(
                                        formData.job.matboxGutterLeadActReq
                                      ) != 0 && (
                                        <tr>
                                          <td>Box Gutter Lead</td>
                                          <td style={{ width: "30px" }}></td>
                                          {/* <td>{formData.job.matboxGutterLeadQty}</td> */}
                                          <td>
                                            <input
                                              className="qouteInputs"
                                              onKeyDown={handleKeyDown}
                                              //
                                              value={
                                                formData.job
                                                  .matboxGutterLeadActReq
                                              }
                                              onChange={(e) => {
                                                zeroAcceptable = true;
                                                const obj = { ...formData };
                                                const sanitizedValue =
                                                  e.target.value.replace(
                                                    /[^0-9.]/g,
                                                    ""
                                                  );
                                                const dotCount =
                                                  sanitizedValue.split(".")
                                                    .length - 1;
                                                if (dotCount > 1) {
                                                  return;
                                                }
                                                // if (
                                                //   Number(sanitizedValue) == 0
                                                // ) {
                                                //   return;
                                                // }

                                                obj.job.matboxGutterLeadActReq =
                                                  Number(sanitizedValue);
                                                setFormData(obj);
                                              }}
                                            />
                                          </td>
                                          <td className="JobSMob">
                                            £{" "}
                                            {formData.job.matboxGutterLeadCost}
                                          </td>
                                        </tr>
                                      )}

                                      <tr>
                                        <td>
                                          <strong>Material Cost</strong>
                                        </td>
                                        <td style={{ width: "30px" }}></td>
                                        <td></td>

                                        <td className="JobSMob">
                                          <strong>
                                            £ {formData.job.materialCost}
                                          </strong>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                )}
                                {formData.job.tileTypeId == 2 && (
                                  <table id="datatablesSimple">
                                    <thead>
                                      <tr>
                                        <th width="22%">Material</th>
                                        <th width="200px"></th>
                                        <th width="200px">Required Amount</th>
                                        <th width="400px">Estimated Cost</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {parseInt(formData.job.matSlateActReq) !=
                                        0 &&
                                        true && (
                                          <tr>
                                            <td>Tiles</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job.matSlateActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matSlateActReq =
                                                    sanitizedValue;
                                                  console.log(
                                                    "Number(sanitizedValue)",
                                                    Number(sanitizedValue)
                                                  );
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>
                                            <td className="JobSMob">
                                              £ {formData.job.matslateCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matslatenHalfActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Tiles & Half</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matslatenHalfActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matslatenHalfActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>
                                            <td className="JobSMob">
                                              £ {formData.job.matslatenHalfCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.mateaveFeltActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Eave Felt</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job.mateaveFeltActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.mateaveFeltActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>
                                            <td className="JobSMob">
                                              £ {formData.job.mateaveFeltCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(formData.job.matbattenActReq) !=
                                        0 &&
                                        true && (
                                          <tr>
                                            <td>Batten</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job.matbattenActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matbattenActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £ {formData.job.matbattenCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matroofingMembraneActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Roofing Membrane</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matroofingMembraneActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matroofingMembraneActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>
                                            <td className="JobSMob">
                                              £{" "}
                                              {
                                                formData.job
                                                  .matroofingMembraneCost
                                              }
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matbattenNailsActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Batten Nails</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matbattenNailsActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matbattenNailsActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £{" "}
                                              {formData.job.matbattenNailsCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matslateTileNailsActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Tile Nails</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matslateTileNailsActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matslateTileNailsActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £{" "}
                                              {
                                                formData.job
                                                  .matslateTileNailsCost
                                              }
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matleadFlashingsActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Lead Flashings</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matleadFlashingsActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matleadFlashingsActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £{" "}
                                              {
                                                formData.job
                                                  .matleadFlashingsCost
                                              }
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matleadValleysActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Lead Valleys</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matleadValleysActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matleadValleysActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £{" "}
                                              {formData.job.matleadValleysCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matdryValleyActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Dry Valley</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matdryValleyActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matdryValleyActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £ {formData.job.matdryValleyCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matValleyTilesValleysActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Valley Tiles</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matValleyTilesValleysActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matValleyTilesValleysActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £{" "}
                                              {
                                                formData.job
                                                  .matValleyTilesValleysCost
                                              }
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matridgeMaterialActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Ridge</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matridgeMaterialActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matridgeMaterialActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £ {formData.job.matridgeCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.mathipsMaterialActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Hips</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .mathipsMaterialActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.mathipsMaterialActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £ {formData.job.mathipsCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matBonnetsActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Bonnets</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job.matBonnetsActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matBonnetsActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £ {formData.job.matBonnetsCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(formData.job.matsandActReq) !=
                                        0 &&
                                        true && (
                                          <tr>
                                            <td>Sand</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job.matsandActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matsandActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £ {formData.job.matsandCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(formData.job.matcementActReq) !=
                                        0 &&
                                        true && (
                                          <tr>
                                            <td>Cement</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job.matcementActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matcementActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £ {formData.job.matcementCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matSealantsActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Sealants</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job.matSealantsActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matSealantsActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £ {formData.job.matSealantsCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matdryRidgeKitActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Dry ridge kit</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matdryRidgeKitActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matdryRidgeKitActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £{" "}
                                              {formData.job.matdryRidgeKitCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matHipSupportTraysActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>HIP support trays</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matHipSupportTraysActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matHipSupportTraysActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £{" "}
                                              {
                                                formData.job
                                                  .matHipSupportTraysCost
                                              }
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matUnderCloakActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Under Cloak</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matUnderCloakActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matUnderCloakActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £ {formData.job.matUnderCloakCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matdryVergeActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Dry Verge</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job.matdryVergeActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matdryVergeActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £ {formData.job.matdryVergeCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matEaveTilesActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Eave Tiles</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matEaveTilesActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matEaveTilesActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £ {formData.job.matEaveTilesCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matventTilesMaterialActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Vent tiles</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matventTilesMaterialActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matventTilesMaterialActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £{" "}
                                              {
                                                formData.job
                                                  .matventTilescostCost
                                              }
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matleadSlateMaterialActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Lead Slate</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matleadSlateMaterialActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matleadSlateMaterialActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £ {formData.job.matleadSlatecost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matboxGutterLeadActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Box Gutter Lead</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matboxGutterLeadActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matboxGutterLeadActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td className="JobSMob">
                                              £{" "}
                                              {
                                                formData.job
                                                  .matboxGutterLeadCost
                                              }
                                            </td>
                                          </tr>
                                        )}

                                      <tr>
                                        <td>
                                          <strong>Material Cost</strong>
                                        </td>
                                        <td></td>

                                        <td></td>
                                        <td>£ {formData.job.materialCost}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                )}

                                {formData.job.tileTypeId == 3 && (
                                  <table id="datatablesSimple">
                                    <thead>
                                      <tr>
                                        <th width="28%">Material</th>
                                        <th width="22%"></th>
                                        <th width="25%">Required Amount</th>
                                        <th width="400px">Estimated Cost</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {parseInt(formData.job.matSlateActReq) !=
                                        0 &&
                                        true && (
                                          <tr>
                                            <td>Tiles</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job.matSlateActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matSlateActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £ {formData.job.matslateCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matleftHandVergeActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Left Hand Verge Tiles</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matleftHandVergeActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matleftHandVergeActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £{" "}
                                              {
                                                formData.job
                                                  .matleftHandVergeCost
                                              }
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.mateaveFeltActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Eave Felt</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job.mateaveFeltActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.mateaveFeltActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £ {formData.job.mateaveFeltCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.eaveCombFillerActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Eave Comb Filler</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .eaveCombFillerActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.eaveCombFillerActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £{" "}
                                              {formData.job.eaveCombFillerCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matroofingMembraneActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Roofing Membrane</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matroofingMembraneActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matroofingMembraneActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £{" "}
                                              {
                                                formData.job
                                                  .matroofingMembraneCost
                                              }
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(formData.job.matbattenActReq) !=
                                        0 &&
                                        true && (
                                          <tr>
                                            <td>Batten</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job.matbattenActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matbattenActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £ {formData.job.matbattenCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matbattenNailsActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Batten Nails</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matbattenNailsActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matbattenNailsActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £{" "}
                                              {formData.job.matbattenNailsCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matslateTileNailsActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Tiles Nails</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matslateTileNailsActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matslateTileNailsActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £{" "}
                                              {
                                                formData.job
                                                  .matslateTileNailsCost
                                              }
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matleadFlashingsActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Lead Flashings</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matleadFlashingsActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matleadFlashingsActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £{" "}
                                              {
                                                formData.job
                                                  .matleadFlashingsCost
                                              }
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matleadValleysActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Lead Valleys</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matleadValleysActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matleadValleysActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £{" "}
                                              {formData.job.matleadValleysCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matdryValleyActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Dry Valley</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matdryValleyActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matdryValleyActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £ {formData.job.matdryValleyCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matridgeMaterialActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Ridge</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matridgeMaterialActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matridgeMaterialActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £ {formData.job.matridgeCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.mathipsMaterialActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Hips</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .mathipsMaterialActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.mathipsMaterialActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £{" "}
                                              {Number(
                                                formData.job.mathipsCost
                                              ).toFixed(2)}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(formData.job.matsandActReq) !=
                                        0 &&
                                        true && (
                                          <tr>
                                            <td>Sand</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job.matsandActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matsandActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £ {formData.job.matsandCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(formData.job.matcementActReq) !=
                                        0 &&
                                        true && (
                                          <tr>
                                            <td>Cement</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job.matcementActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matcementActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £ {formData.job.matcementCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matSealantsActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Sealants</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job.matSealantsActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matSealantsActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £ {formData.job.matSealantsCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matdryRidgeKitActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Dry ridge kit</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matdryRidgeKitActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matdryRidgeKitActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £{" "}
                                              {formData.job.matdryRidgeKitCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matHipSupportTraysActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>HIP support trays</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matHipSupportTraysActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matHipSupportTraysActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £{" "}
                                              {
                                                formData.job
                                                  .matHipSupportTraysCost
                                              }
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matUnderCloakActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Under Cloak</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matUnderCloakActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matUnderCloakActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £ {formData.job.matUnderCloakCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matdryVergeActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Dry Verge</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job.matdryVergeActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matdryVergeActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £ {formData.job.matdryVergeCost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matventTilesMaterialActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Vent Tiles</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matventTilesMaterialActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matventTilesMaterialActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £{" "}
                                              {
                                                formData.job
                                                  .matventTilescostCost
                                              }
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matleadSlateMaterialActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Lead Slate</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matleadslateActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matleadslateActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £ {formData.job.matleadSlatecost}
                                            </td>
                                          </tr>
                                        )}
                                      {parseInt(
                                        formData.job.matboxGutterLeadActReq
                                      ) != 0 &&
                                        true && (
                                          <tr>
                                            <td>Box Gutter Lead</td>
                                            <td></td>
                                            <td>
                                              <input
                                                className="qouteInputs"
                                                onKeyDown={handleKeyDown}
                                                value={
                                                  formData.job
                                                    .matboxGutterLeadActReq
                                                }
                                                onChange={(e) => {
                                                  const obj = { ...formData };
                                                  const sanitizedValue =
                                                    e.target.value.replace(
                                                      /[^0-9.]/g,
                                                      ""
                                                    );
                                                  const dotCount =
                                                    sanitizedValue.split(".")
                                                      .length - 1;
                                                  if (dotCount > 1) {
                                                    return;
                                                  }

                                                  obj.job.matboxGutterLeadActReq =
                                                    Number(sanitizedValue);
                                                  setFormData(obj);
                                                }}
                                              />
                                            </td>

                                            <td>
                                              £{" "}
                                              {
                                                formData.job
                                                  .matboxGutterLeadCost
                                              }
                                            </td>
                                          </tr>
                                        )}

                                      <tr>
                                        <td>
                                          <strong>Material Cost</strong>
                                        </td>
                                        <td></td>

                                        <td></td>

                                        <td>£ {formData.job.materialCost}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                )}
                                <br />
                                <h3 className="text-primary">Labour</h3>
                                <table id="datatablesSimple">
                                  <thead>
                                    <tr>
                                      <th style={{ width: "37%" }}></th>
                                      <th style={{ width: "28%" }}>Total</th>
                                      <th width="400px">Labour Cost</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {parseInt(formData.job.area) != 0 && (
                                      <tr>
                                        <td style={{ paddingRight: "106px" }}>
                                          Area (sqm)
                                        </td>
                                        <td>{formData.job.area}</td>
                                        <td>£{formData.job.areaLabour}</td>
                                      </tr>
                                    )}
                                    {parseInt(formData.job.hips) != 0 && (
                                      <tr>
                                        <td>Hips (linear metres)</td>
                                        <td>{formData.job.hips}</td>
                                        <td>
                                          £{" "}
                                          {Number(
                                            formData.job.hipsLabour
                                          ).toFixed(2)}
                                        </td>
                                      </tr>
                                    )}
                                    {parseInt(formData.job.ridge) != 0 && (
                                      <tr>
                                        <td>Ridge (linear metres)</td>
                                        <td>{formData.job.ridge}</td>
                                        <td>£ {formData.job.ridgeLabour}</td>
                                      </tr>
                                    )}
                                    {parseInt(formData.job.valley) != 0 && (
                                      <tr>
                                        <td>Valleys (linear metres)</td>
                                        <td>{formData.job.valley}</td>
                                        <td>£ {formData.job.valleyLabour}</td>
                                      </tr>
                                    )}
                                    {parseInt(formData.job.verge) != 0 && (
                                      <tr>
                                        <td>Verge (linear metres)</td>
                                        <td>{formData.job.verge}</td>
                                        <td>£ {formData.job.vergeLabour}</td>
                                      </tr>
                                    )}
                                    {parseInt(formData.job.leadWork) != 0 && (
                                      <tr>
                                        <td>Leadwork (linear metres)</td>
                                        <td>{formData.job.leadWork}</td>
                                        <td>£{formData.job.leadworklabour}</td>
                                      </tr>
                                    )}
                                    {parseInt(formData.job.roofWindow) != 0 && (
                                      <tr>
                                        <td>Roof Window</td>
                                        <td>{formData.job.roofWindow}</td>
                                        <td>
                                          £ {formData.job.roofWindowLabour}
                                        </td>
                                      </tr>
                                    )}
                                    {parseInt(formData.job.eaveCourse) != 0 && (
                                      <tr>
                                        <td>Eave Course</td>
                                        <td>{formData.job.eaveCourse}</td>
                                        <td></td>
                                        <td />
                                      </tr>
                                    )}
                                    {parseInt(formData.job.ventTiles) != 0 && (
                                      <tr>
                                        <td>Vent Tiles</td>
                                        <td>{formData.job.ventTiles}</td>
                                        <td>
                                          £ {formData.job.ventTilesLabour}
                                        </td>
                                      </tr>
                                    )}
                                    {parseInt(formData.job.leadSlate) != 0 && (
                                      <tr>
                                        <td>Lead Slate</td>
                                        <td>{formData.job.leadSlate}</td>
                                        <td>
                                          £ {formData.job.leadSlateLabour}
                                        </td>
                                      </tr>
                                    )}

                                    {parseInt(formData.job.boxGutter) != 0 && (
                                      <tr>
                                        <td>Box Gutter (sqm)</td>
                                        <td>{formData.job.boxGutter}</td>
                                        <td>£{formData.job.boxGutterLabour}</td>
                                      </tr>
                                    )}

                                    {parseInt(formData.job.labourCost) != 0 && (
                                      <tr>
                                        <td>
                                          <strong>Labour Cost</strong>
                                        </td>
                                        <td />
                                        <td>
                                          <strong>
                                            £&nbsp;
                                            {formData.job.labourCost}
                                          </strong>
                                        </td>
                                      </tr>
                                    )}

                                    <tr>
                                      <br />
                                    </tr>
                                    {parseInt(formData.job.scaffoldCost) !=
                                      0 && (
                                      <tr>
                                        <strong>
                                          <td>Scaffold Costs</td>
                                        </strong>
                                        <td></td>
                                        <strong>
                                          <td>
                                            £{" "}
                                            {formData.job.scaffoldCost.toFixed(
                                              2
                                            )}
                                          </td>
                                        </strong>
                                      </tr>
                                    )}
                                    {parseInt(formData.job.wasteCost) != 0 && (
                                      <tr>
                                        <strong>
                                          <td>Waste Costs</td>
                                        </strong>
                                        <td></td>
                                        <strong>
                                          <td>
                                            £{" "}
                                            {formData.job.wasteCost.toFixed(2)}
                                          </td>
                                        </strong>
                                      </tr>
                                    )}
                                    <tr>
                                      <td>
                                        <strong>Total Cost</strong>
                                      </td>
                                      <td />
                                      <td>
                                        <strong>
                                          £&nbsp; {formData.job.totalCost}
                                        </strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Plus VAT</strong>
                                      </td>
                                      <td />
                                      <td>
                                        <strong>
                                          £&nbsp;
                                          {(
                                            formData.job.totalCost * 0.2
                                          ).toFixed(2)}
                                        </strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>TOTAL Plus VAT</strong>
                                      </td>
                                      <td />
                                      <td>
                                        <strong>
                                          £&nbsp;
                                          {formData.job.totalCostWithVat}
                                        </strong>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </>
                            )}

                            <br />
                            <div className="mb-3">
                              <label
                                className="small mb-1"
                                htmlFor="exampleFormControlTextarea1"
                              >
                                Supplier Comment
                              </label>
                              <textarea
                                name="supplierComment"
                                className="form-control commentsTextarea"
                                id="comments"
                                rows={5}
                                value={formData.client.supplierComment || ""}
                                onChange={handleChangeClient}
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                className="small mb-1"
                                htmlFor="exampleFormControlTextarea1"
                              >
                                Client Comment
                              </label>
                              <textarea
                                name="clientComment"
                                className="form-control commentsTextarea"
                                id="comments"
                                rows={5}
                                value={formData.client.clientComment || ""}
                                onChange={handleChangeClient}
                              />
                            </div>
                            <div className="d-flex justify-content-between">
                              <button
                                onClick={async () => {
                                  await setCalculatedOnce(false);
                                  zeroAcceptable = false;
                                  setSelectedTab(2);
                                }}
                                className="btn btn-light"
                                type="submit"
                              >
                                Previous
                              </button>
                              <button
                                onClick={() => {
                                  saveInDB();
                                  // confirmAlert({
                                  //   title: "Confirm to submit",
                                  //   message: "Are you sure to do this.",
                                  //   buttons: [
                                  //     {
                                  //       label: "Yes",
                                  //       onClick: () => saveInDB(),
                                  //     },
                                  //     {
                                  //       label: "No",
                                  //       onClick: () => console.log("Click No"),
                                  //     },
                                  //   ],
                                  // });
                                  // setSelectedTab(4);
                                }}
                                className="btn btn-primary"
                                type="submit"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* Wizard tab pane item 4*/}
                    {selectedTab == 4 && (
                      <div
                        className="tab-pane py-5 py-xl-10 fade  show active"
                        id="wizard4"
                      >
                        <div className="row justify-content-center">
                          <div className="col-xxl-6 col-xl-8">
                            <h3 className="text-primary">
                              Step 4 - Material Quotes
                            </h3>
                            <div className="container-xl px-4 mt-4">
                              <div className="">
                                <div className="card-body">
                                  <table id="" style={{ width: "64%" }}>
                                    <thead>
                                      <tr>
                                        <th style={{ margin: "17px" }}></th>
                                        <th style={{ margin: "17px" }}> </th>
                                        <th style={{ margin: "17px" }}></th>
                                      </tr>
                                    </thead>
                                    <tfoot></tfoot>
                                    <tbody>
                                      <tr>
                                        <td style={{ margin: "17px" }}>
                                          <div className="d-flex align-items-center">
                                            Client Quote
                                          </div>
                                        </td>
                                        <td style={{ margin: "17px" }}>
                                          {clientView === false && (
                                            <button
                                              className="btn btn-primary p-2"
                                              onClick={() => {
                                                setClientView(true);
                                                console.log("write export 120");
                                                const base =
                                                  "Customer_Quotation.pdf?P_quoteid=" +
                                                  insertId;
                                                console.log(
                                                  "InsertID24",
                                                  insertId
                                                );
                                                const jasper_username =
                                                  process.env
                                                    .REACT_APP_JASPER_USERNAME;
                                                const jasper_password =
                                                  process.env
                                                    .REACT_APP_JASPER_PASSWORD;
                                                const jasper_url =
                                                  process.env
                                                    .REACT_APP_JASPER_URL;
                                                console.log(
                                                  "JasperURL: ",
                                                  jasper_url
                                                );

                                                return new Promise(
                                                  (resolve, reject) => {
                                                    axios
                                                      .get(
                                                        `${jasper_url}${base}`,
                                                        {
                                                          responseType: "blob",
                                                          headers: {
                                                            Accept:
                                                              "application/pdf",
                                                          },
                                                          auth: {
                                                            username:
                                                              jasper_username,
                                                            password:
                                                              jasper_password,
                                                          },
                                                        }
                                                      )
                                                      .then((response) => {
                                                        resolve(response);
                                                        const blob = new Blob(
                                                          [response.data],
                                                          {
                                                            type: "application/pdf",
                                                          }
                                                        );
                                                        const objectUrl =
                                                          window.URL.createObjectURL(
                                                            blob
                                                          );
                                                        var link =
                                                          document.createElement(
                                                            "a"
                                                          );
                                                        link.href = objectUrl;
                                                        setClientView(false);
                                                        NotificationManager.success(
                                                          `View Reports in Browser Downloads`
                                                        );
                                                        link.download = `clientQuote ${insertId}.pdf`;

                                                        link.click();

                                                        setTimeout(function () {
                                                          // For Firefox it is necessary to delay revoking the ObjectURL
                                                          window.URL.revokeObjectURL(
                                                            objectUrl
                                                          );
                                                        }, 100);
                                                      })
                                                      .catch((error) => {
                                                        setClientView(false);
                                                        NotificationManager.error(
                                                          `Report Download Failed! `
                                                        );
                                                        reject(error);
                                                      });
                                                  }
                                                );
                                              }}
                                            >
                                              View PDF
                                            </button>
                                          )}
                                          {clientView === true && (
                                            <button
                                              className="btn btn-primary p-2"
                                              disabled
                                            >
                                              <span class="spinner-border spinner-border-sm"></span>
                                              View PDF
                                            </button>
                                          )}
                                        </td>
                                        <td style={{ margin: "17px" }}>
                                          {clientSend === false && (
                                            <button
                                              className="btn btn-danger p-2"
                                              Client
                                              Quote
                                              onClick={() => {
                                                setClientSend(true);
                                                console.log("write export 120");
                                                const base =
                                                  "Customer_Quotation.pdf?P_quoteid=" +
                                                  insertId;
                                                console.log(
                                                  "InsertID25",
                                                  insertId
                                                );
                                                const jasper_username =
                                                  process.env
                                                    .REACT_APP_JASPER_USERNAME;
                                                const jasper_password =
                                                  process.env
                                                    .REACT_APP_JASPER_PASSWORD;
                                                const jasper_url =
                                                  process.env
                                                    .REACT_APP_JASPER_URL;
                                                console.log(
                                                  "JasperURL: ",
                                                  jasper_url
                                                );

                                                return new Promise(
                                                  (resolve, reject) => {
                                                    axios
                                                      .get(
                                                        `${jasper_url}${base}`,
                                                        {
                                                          responseType: "blob",
                                                          headers: {
                                                            Accept:
                                                              "application/pdf",
                                                          },
                                                          auth: {
                                                            username:
                                                              jasper_username,
                                                            password:
                                                              jasper_password,
                                                          },
                                                        }
                                                      )
                                                      .then((response) => {
                                                        resolve(response);
                                                        const blob = new Blob(
                                                          [response.data],
                                                          {
                                                            type: "application/pdf",
                                                          }
                                                        );
                                                        const objectUrl =
                                                          window.URL.createObjectURL(
                                                            blob
                                                          );
                                                        var link =
                                                          document.createElement(
                                                            "a"
                                                          );
                                                        link.href = objectUrl;

                                                        sendQoutesViaEmail(
                                                          link,
                                                          "Client"
                                                        );

                                                        // link.click();
                                                        setTimeout(function () {
                                                          setClientSend(false);
                                                          // For Firefox it is necessary to delay revoking the ObjectURL
                                                          window.URL.revokeObjectURL(
                                                            objectUrl
                                                          );
                                                        }, 100);
                                                      })
                                                      .catch((error) => {
                                                        setClientSend(false);
                                                        NotificationManager.error(
                                                          `Email Sending Failed! `
                                                        );
                                                        reject(error);
                                                      });
                                                  }
                                                );
                                              }}
                                            >
                                              Send Quote
                                            </button>
                                          )}
                                          {clientSend === true && (
                                            <button
                                              className="btn btn-danger p-2"
                                              disabled
                                            >
                                              <span class="spinner-border spinner-border-sm"></span>
                                              Send Quote
                                            </button>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            Supplier Quote
                                          </div>
                                        </td>
                                        <td>
                                          {supplierView === false && (
                                            <button
                                              className="btn btn-primary p-2"
                                              // target="_blank"
                                              onClick={() => {
                                                setSupplierView(true);
                                                console.log(
                                                  "write export code here"
                                                );
                                                const base =
                                                  "Materials_Quotation.pdf?quoteid=" +
                                                  insertId;
                                                console.log(
                                                  "InsertID22",
                                                  insertId
                                                );
                                                const jasper_username =
                                                  process.env
                                                    .REACT_APP_JASPER_USERNAME;
                                                const jasper_password =
                                                  process.env
                                                    .REACT_APP_JASPER_PASSWORD;
                                                const jasper_url =
                                                  process.env
                                                    .REACT_APP_JASPER_URL;
                                                console.log(
                                                  "JasperURL: ",
                                                  jasper_url
                                                );

                                                return new Promise(
                                                  (resolve, reject) => {
                                                    axios
                                                      .get(
                                                        `${jasper_url}${base}`,
                                                        {
                                                          responseType: "blob",
                                                          headers: {
                                                            Accept:
                                                              "application/pdf",
                                                          },
                                                          auth: {
                                                            username:
                                                              jasper_username,
                                                            password:
                                                              jasper_password,
                                                          },
                                                        }
                                                      )
                                                      .then((response) => {
                                                        resolve(response);
                                                        const blob = new Blob(
                                                          [response.data],
                                                          {
                                                            type: "application/pdf",
                                                          }
                                                        );
                                                        const objectUrl =
                                                          window.URL.createObjectURL(
                                                            blob
                                                          );
                                                        var link =
                                                          document.createElement(
                                                            "a"
                                                          );
                                                        link.href = objectUrl;

                                                        NotificationManager.success(
                                                          `View Reports in Browser Downloads`
                                                        );
                                                        setSupplierView(false);
                                                        link.download = `supplierQuote ${insertId}.pdf`;
                                                        link.click();

                                                        setTimeout(function () {
                                                          // For Firefox it is necessary to delay revoking the ObjectURL
                                                          window.URL.revokeObjectURL(
                                                            objectUrl
                                                          );
                                                        }, 100);
                                                      })
                                                      .catch((error) => {
                                                        setSupplierView(false);
                                                        NotificationManager.error(
                                                          `Report Download Failed! `
                                                        );
                                                        reject(error);
                                                      });
                                                  }
                                                );
                                              }}
                                            >
                                              View PDF
                                            </button>
                                          )}
                                          {supplierView === true && (
                                            <button
                                              className="btn btn-primary p-2"
                                              disabled
                                            >
                                              <span class="spinner-border spinner-border-sm"></span>
                                              View Quote
                                            </button>
                                          )}
                                        </td>
                                        <td>
                                          {" "}
                                          {supplierSend === false && (
                                            <button
                                              className="btn btn-danger p-2"
                                              onClick={() => {
                                                setSupplierSend(true);
                                                console.log(
                                                  "write export code here"
                                                );
                                                const base =
                                                  "Materials_Quotation.pdf?quoteid=" +
                                                  insertId;
                                                console.log(
                                                  "InsertID23",
                                                  insertId
                                                );
                                                const jasper_username =
                                                  process.env
                                                    .REACT_APP_JASPER_USERNAME;
                                                const jasper_password =
                                                  process.env
                                                    .REACT_APP_JASPER_PASSWORD;
                                                const jasper_url =
                                                  process.env
                                                    .REACT_APP_JASPER_URL;
                                                console.log(
                                                  "JasperURL: ",
                                                  jasper_url
                                                );

                                                return new Promise(
                                                  (resolve, reject) => {
                                                    axios
                                                      .get(
                                                        `${jasper_url}${base}`,
                                                        {
                                                          responseType: "blob",
                                                          headers: {
                                                            Accept:
                                                              "application/pdf",
                                                          },
                                                          auth: {
                                                            username:
                                                              jasper_username,
                                                            password:
                                                              jasper_password,
                                                          },
                                                        }
                                                      )
                                                      .then((response) => {
                                                        resolve(response);
                                                        const blob = new Blob(
                                                          [response.data],
                                                          {
                                                            type: "application/pdf",
                                                          }
                                                        );
                                                        const objectUrl =
                                                          window.URL.createObjectURL(
                                                            blob
                                                          );
                                                        var link =
                                                          document.createElement(
                                                            "a"
                                                          );
                                                        link.href = objectUrl;

                                                        sendQoutesViaEmail(
                                                          link,
                                                          "Supplier"
                                                        );
                                                        // link.click();
                                                        setTimeout(function () {
                                                          // For Firefox it is necessary to delay revoking the ObjectURL
                                                          window.URL.revokeObjectURL(
                                                            objectUrl
                                                          );
                                                        }, 100);
                                                      })
                                                      .catch((error) => {
                                                        setSupplierSend(false);
                                                        NotificationManager.error(
                                                          `Email sending failed! `
                                                        );
                                                        reject(error);
                                                      });
                                                  }
                                                );
                                              }}
                                            >
                                              Send Quote
                                            </button>
                                          )}
                                          {supplierSend === true && (
                                            <button
                                              className="btn btn-danger p-2"
                                              disabled
                                            >
                                              <span class="spinner-border spinner-border-sm"></span>
                                              Send Quote
                                            </button>
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <br /> <br /> <br /> <br /> <br /> <br />
                                <button
                                  style={{
                                    marginLeft: "27%",
                                    color: "white",
                                    // opacity: "0",
                                    backgroundColor: "#0052ce",
                                    cursor: "default",
                                  }}
                                  onClick={() => {
                                    setSelectedTab(3);
                                  }}
                                  className="btn btn-light"
                                  type="submit"
                                >
                                  Previous
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {selectedTab == 5 && (
                      <div
                        className="tab-pane py-5 py-xl-10 fade  show active"
                        id="wizard4"
                      >
                        <div className="row justify-content-center">
                          <div className="col-xxl-6 col-xl-8">
                            <h3 className="text-primary">
                              Step 4 - Material Quotes
                            </h3>
                            <h5 className="card-title mb-4">
                              Confirm your post code and press submit, this will
                              send your requirements to local suppliers.{" "}
                            </h5>
                            <div className="mb-3">
                              <label className="small mb-1" htmlFor="postcode">
                                Post code
                              </label>
                              <input
                                required
                                value={formData.job.postCode || ""}
                                onChange={handleChange}
                                name="postCode"
                                className="form-control"
                                id="postcode"
                                type="text"
                              />
                            </div>
                            <div className="d-flex justify-content-between">
                              <button className="btn btn-primary" type="submit">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </main>
          <footer className="footer-admin mt-auto footer-light">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">
                  Copyright © Roof Pal {currentYear}
                </div>
                <div className="col-md-6 text-md-end small"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Quote;
