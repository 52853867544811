import "../PagesCss/Default.css";
import React, { useState } from "react";
import favicon from "../assets/img/favicon.png";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import Swal from "sweetalert2";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
const EditMaterials = (props) => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    nailSize: "",
    costPerUnit: "",
  });
  const navigate = useNavigate();

  console.log("Editformdata:", formData);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/material/get/${id}`
        );
        console.log("API Response:", response);

        const materialData = response.data;
        console.log("material Data:", materialData);

        if (materialData) {
          // Assuming the response structure is { nailSize, costPerUnit }
          setFormData(materialData.data);
        } else {
          console.error("Invalid response or empty data.");
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form data:", formData);

    const isConfirmed = await Swal.fire({
      title: "Caution",
      text: "Editing this information may affect calculations. Verify changes to ensure accuracy.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, submit it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    });

    if (isConfirmed.isConfirmed) {
      try {
        // Assuming you have the correct endpoint for updating data
        const response = axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/api/material/put`,
          formData
        );
        NotificationManager.success("Material updated successfully", "Info");
        console.log("Update Response:", response);
        setFormData({
          materialName: "",
          amountPerUnit: "",
          costPerUnit: "",
        });
        // You can handle the response as needed
        // For example, you can show a success message or navigate back to the list page
      } catch (error) {
        console.error("Error updating data:", error.message);
        NotificationManager.error("Material Is Not Updating ", "error");
        // You can handle errors, for example, display an error message to the user
      }
    }
  };
  const currentYear = new Date().getFullYear();
  return (
    <>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="description" content="" />
      <meta name="author" content="" />
      <title>Edit Material</title>
      <link
        href="https://cdn.jsdelivr.net/npm/simple-datatables@latest/dist/style.css"
        rel="stylesheet"
      />
      <link href="css/styles.css" rel="stylesheet" />
      <link rel="icon" type="image/x-icon" href="assets/img/favicon.png" />
      <Navbar toggleMobileMenu={props.toggleMobileMenu} />
      <div id="layoutSidenav">
        <SideBar
          setMobileMenuOpen={props.setMobileMenuOpen}
          isMobileMenuOpen={props.isMobileMenuOpen}
        />
        <div id="layoutSidenav_content">
          <main>
            <header className="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
              <div className="container-xl px-4">
                <div className="page-header-content">
                  <div className="row align-items-center justify-content-between pt-3">
                    <div className="col-auto mb-3">
                      <h1 className="page-header-title">
                        <div className="page-header-icon">
                          <i data-feather="user" />
                        </div>
                        Edit Material&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                      </h1>
                    </div>
                    <div className="col-12 col-xl-auto mb-3">
                      <a
                        onClick={() => {
                          navigate("/materials");
                        }}
                        className="btn btn-sm btn-light text-primary"
                      >
                        <i className="me-1" data-feather="arrow-left" />
                        Back to Materials
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            {/* Main page content*/}
            <div className="container-xl px-4 mt-4">
              <div className="row">
                <div className="col-xl-12">
                  {/* Account details card*/}
                  <div className="card mb-4">
                    <div className="card-header">Material Details</div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        {/* Form Row*/}
                        <div className="row gx-3 mb-3">
                          {/* Form Group (first name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              Material name
                            </label>
                            <input
                              className="form-control"
                              id="materialName"
                              type="text"
                              placeholder="Enter material name"
                              value={formData.materialName}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          {/* Form Group (last name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputLastName"
                            >
                              Amount Per Unit&nbsp;(sqm or linear meter)
                            </label>
                            <input
                              className="form-control"
                              id="amountPerUnit"
                              type="text"
                              placeholder="Enter amount per unit"
                              value={formData.amountPerUnit}
                              onChange={handleChange}
                            />
                          </div>
                          {/* <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputLastName"
                            >
                              Amount Per Unit&nbsp;(sqm or linear meter){" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              id="amountPerUnit"
                              type="text"
                              placeholder="Enter amount per unit"
                              value={formData.amountPerUnit}
                              onChange={handleChange}
                            />
                            {formData.amountPerUnit === "" && (
                              <div style={{ color: "red" }}>
                                This field is required.
                              </div>
                            )}
                          </div> */}
                        </div>
                        <div className="row gx-3 mb-3">
                          {/* Form Group (first name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              Cost Per Unit
                            </label>
                            <input
                              className="form-control"
                              id="costPerUnit"
                              type="text"
                              placeholder="Enter cost per unit"
                              value={formData.costPerUnit}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        {/* Submit button*/}
                        <button className="btn btn-primary" type="submit">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <footer className="footer-admin mt-auto footer-light">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">
                  Copyright © Roof Pal {currentYear}
                </div>
                <div className="col-md-6 text-md-end small"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default EditMaterials;
