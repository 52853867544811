import "../PagesCss/Default.css";
import React, { useState } from "react";
import favicon from "../assets/img/favicon.png";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import Swal from "sweetalert2";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
const EditTile = (props) => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    tileName: "",
    tileTypeId: "",
    costPerTile: "",
    tilesPerMetre: "",
    tileSlateNailsPM: "",
    battenPerMetre: "",
    battenNailsPSQM: "",
    battenRequired: "",
    nailSize: "",
    cementFibreSlate: "",
    eaveCombFiller: "",
    leftHandedTilePLM: "",
    leftHandedTileCost: "",
    ridgeRequired: "",
    costPerSlatenHalf: "",
    eaveTilesPerLM: "",
    costPerEaveTile: "",
    valleyTilesPerLM: "",
    costPerValleyTile: "",
    tilenHalfPerLM: "",
    costPerTilenHalf: "",
    bonnetsPerLM: "",
    costPerBonnet: "",
  });
  const navigate = useNavigate();

  console.log("Editformdata:", formData);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/tiles/get/${id}`
        );
        console.log("API Response:", response);

        const tilesData = response.data;
        console.log("tiles Data:", tilesData);

        if (tilesData) {
          // Assuming the response structure is { nailSize, costPerUnit }
          setFormData(tilesData.data);
        } else {
          console.error("Invalid response or empty data.");
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form data:", formData);
    const isConfirmed = await Swal.fire({
      title: "Caution",
      text: "Editing this information may affect calculations. Verify changes to ensure accuracy.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, submit it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    });

    if (isConfirmed.isConfirmed) {
      try {
        // Assuming you have the correct endpoint for updating data
        const response = axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/api/tiles/put`,
          formData
        );
        NotificationManager.success("Tiles updated successfully", "Info");
        console.log("Update Response:", response);
        setFormData({
          tileName: "",
          tileTypeId: "",
          costPerTile: "",
          tilesPerMetre: "",
          tileSlateNailsPM: "",
          battenPerMetre: "",
          battenNailsPSQM: "",
          battenRequired: "",
          nailSize: "",
          cementFibreSlate: "",
          eaveCombFiller: "",
          leftHandedTilePLM: "",
          leftHandedTileCost: "",
          ridgeRequired: "",
          costPerSlatenHalf: "",
          eaveTilesPerLM: "",
          costPerEaveTile: "",
          valleyTilesPerLM: "",
          costPerValleyTile: "",
          tilenHalfPerLM: "",
          costPerTilenHalf: "",
          bonnetsPerLM: "",
          costPerBonnet: "",
        });

        // You can handle the response as needed
        // For example, you can show a success message or navigate back to the list page
      } catch (error) {
        console.error("Error updating data:", error.message);
        NotificationManager.error("Tiles Is Not Updating ", "error");
        // You can handle errors, for example, display an error message to the user
      }
    }
  };
  const currentYear = new Date().getFullYear();
  return (
    <>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="description" content="" />
      <meta name="author" content="" />
      <title>Edit Tile</title>
      <link
        href="https://cdn.jsdelivr.net/npm/simple-datatables@latest/dist/style.css"
        rel="stylesheet"
      />
      <link href="css/styles.css" rel="stylesheet" />
      <link rel="icon" type="image/x-icon" href="assets/img/favicon.png" />
      <nav
        className="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white"
        id="sidenavAccordion"
      >
        {/* Sidenav Toggle Button*/}
        <button
          className="btn btn-icon btn-transparent-dark order-1 order-lg-0 me-2 ms-lg-2 me-lg-0"
          id="sidebarToggle"
        >
          <i data-feather="menu" />
        </button>
        {/* Navbar Brand*/}
        {/* * * Tip * * You can use text or an image for your navbar brand.*/}
        {/* * * * * * * When using an image, we recommend the SVG format.*/}
        {/* * * * * * * Dimensions: Maximum height: 32px, maximum width: 240px*/}
        <a className="navbar-brand pe-3 ps-4 ps-lg-2" href="index.html">
          Roof Pal
        </a>
        {/* Navbar Items*/}
        <ul className="navbar-nav align-items-center ms-auto">
          {/* Documentation Dropdown*/}
          <li className="nav-item dropdown no-caret d-none d-md-block me-3">
            <div
              className="dropdown-menu dropdown-menu-end py-0 me-sm-n15 me-lg-0 o-hidden animated--fade-in-up"
              aria-labelledby="navbarDropdownDocs"
            >
              <a
                className="dropdown-item py-3"
                href="https://docs.startbootstrap.com/sb-admin-pro"
                target="_blank"
              >
                <div className="icon-stack bg-primary-soft text-primary me-4">
                  <i data-feather="book" />
                </div>
                <div>
                  <div className="small text-gray-500">Documentation</div>
                  Usage instructions and reference
                </div>
              </a>
              <div className="dropdown-divider m-0" />
              <a
                className="dropdown-item py-3"
                href="https://docs.startbootstrap.com/sb-admin-pro/components"
                target="_blank"
              >
                <div className="icon-stack bg-primary-soft text-primary me-4">
                  <i data-feather="code" />
                </div>
                <div>
                  <div className="small text-gray-500">Components</div>
                  Code snippets and reference
                </div>
              </a>
              <div className="dropdown-divider m-0" />
              <a
                className="dropdown-item py-3"
                href="https://docs.startbootstrap.com/sb-admin-pro/changelog"
                target="_blank"
              >
                <div className="icon-stack bg-primary-soft text-primary me-4">
                  <i data-feather="file-text" />
                </div>
                <div>
                  <div className="small text-gray-500">Changelog</div>
                  Updates and changes
                </div>
              </a>
            </div>
          </li>
          {/* User Dropdown
          <li class="nav-item dropdown no-caret dropdown-user me-3 me-lg-4">
              <a class="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownUserImage" href="javascript:void(0);" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img class="img-fluid" src="assets/img/illustrations/profiles/profile-1.png" /></a>
              <div class="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownUserImage">
                  <h6 class="dropdown-header d-flex align-items-center">
                      <img class="dropdown-user-img" src="assets/img/illustrations/profiles/profile-1.png" />
                      <div class="dropdown-user-details">
                          <div class="dropdown-user-details-name">Valerie Luna</div>
                          <div class="dropdown-user-details-email">vluna@aol.com</div>
                      </div>
                  </h6>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#!">
                      <div class="dropdown-item-icon"><i data-feather="settings"></i></div>
                      Account
                  </a>
                  <a class="dropdown-item" href="#!">
                      <div class="dropdown-item-icon"><i data-feather="log-out"></i></div>
                      Logout
                  </a>
              </div>
          </li>
*/}
        </ul>
      </nav>
      <div id="layoutSidenav">
        <SideBar
          setMobileMenuOpen={props.setMobileMenuOpen}
          isMobileMenuOpen={props.isMobileMenuOpen}
        />

        <div id="layoutSidenav_content">
          <main>
            <header className="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
              <div className="container-xl px-4">
                <div className="page-header-content">
                  <div className="row align-items-center justify-content-between pt-3">
                    <div className="col-auto mb-3">
                      <h1 className="page-header-title">
                        <div className="page-header-icon">
                          <i data-feather="user" />
                        </div>
                        Edit Tile
                      </h1>
                    </div>
                    <div className="col-12 col-xl-auto mb-3">
                      <a
                        onClick={() => {
                          navigate("/tiles");
                        }}
                        className="btn btn-sm btn-light text-primary"
                      >
                        <i className="me-1" data-feather="arrow-left" />
                        Back to Tiles
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            {/* Main page content*/}
            <div className="container-xl px-4 mt-4">
              <div className="row">
                <div className="col-xl-12">
                  {/* Account details card*/}
                  <div className="card mb-4">
                    <div className="card-header">Tile Details</div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        {/* Form Row*/}
                        <div className="row gx-3 mb-3">
                          {/* Form Group (first name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              Tile name
                            </label>
                            <input
                              className="form-control"
                              id="tileName"
                              type="text"
                              placeholder="Enter tile name"
                              value={formData.tileName}
                              onChange={handleChange}
                            />
                          </div>
                          {/* Form Group (last name)*/}
                          <div className="col-md-6">
                            <label className="small mb-1">Tile Type</label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              id="tileTypeId"
                              value={formData.tileTypeId}
                              onChange={handleChange}
                            >
                              <option disabled>Select a Title:</option>
                              <option value="1"> Slate</option>
                              <option value="2">Small Tiles</option>
                              <option value="3">Big Tiles</option>
                            </select>
                          </div>
                        </div>

                        <div className="row gx-3 mb-3">
                          {/* Form Group (first name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              Cost Per Tile&nbsp;
                            </label>
                            <input
                              className="form-control"
                              id="costPerTile"
                              type="number"
                              value={formData.costPerTile}
                              onChange={handleChange}
                            />
                          </div>
                          {/* Form Group (last name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputLastName"
                            >
                              Tiles Per Metre&nbsp;
                            </label>
                            <input
                              className="form-control"
                              id="tilesPerMetre"
                              type="number"
                              value={formData.tilesPerMetre}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="row gx-3 mb-3">
                          {/* Form Group (first name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              Batten Per Metre&nbsp;
                            </label>
                            <input
                              className="form-control"
                              id="battenPerMetre"
                              type="number"
                              value={formData.battenPerMetre}
                              onChange={handleChange}
                            />
                          </div>
                          {/* Form Group (last name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputLastName"
                            >
                              Batten Nails Per SQM&nbsp;
                            </label>
                            <input
                              className="form-control"
                              id="battenNailsPSQM"
                              type="number"
                              value={formData.battenNailsPSQM}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="row gx-3 mb-3">
                          {/* Form Group (first name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              Tile Slate Nails Per Meter&nbsp;
                            </label>
                            <input
                              className="form-control"
                              id="tileSlateNailsPM"
                              type="number"
                              value={formData.tileSlateNailsPM}
                              onChange={handleChange}
                            />
                          </div>
                          {/* Form Group (last name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              Batten Required&nbsp;
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              id="battenRequired"
                              value={formData.battenRequired}
                              onChange={handleChange}
                            >
                              <option disabled>Select Batten:</option>
                              <option value="25x50mm"> 25x50mm</option>
                              <option value="25x38mm">25x38mm</option>
                            </select>
                          </div>
                        </div>

                        <div className="row gx-3 mb-3">
                          {/* Form Group (first name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputLastName"
                            >
                              Nail Size&nbsp;
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              id="nailSize"
                              value={formData.nailSize}
                              onChange={handleChange}
                            >
                              <option disabled>Select NailSize:</option>
                              <option value="30mm"> 30mm</option>
                              <option value="35mm">35mm</option>
                              <option value="38mm"> 38mm</option>
                              <option value="40mm">40mm</option>
                              <option value="45mm"> 45mm</option>
                              <option value="50mm">50mm</option>
                              <option value="55mm"> 55mm</option>
                              <option value="60mm">60mm</option>
                              <option value="65mm"> 65mm</option>
                              <option value="70mm">70mm</option>
                              <option value="75mm"> 75mm</option>
                              <option value="N/A">N/A</option>
                            </select>
                          </div>
                          {/* Form Group (last name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              Cement Fibre Slate&nbsp;
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              id="cementFibreSlate"
                              value={formData.cementFibreSlate}
                              onChange={handleChange}
                            >
                              <option value="Yes"> Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>

                        <div className="row gx-3 mb-3">
                          {/* Form Group (first name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputLastName"
                            >
                              Eave Comb Filler&nbsp;
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              id="eaveCombFiller"
                              value={formData.eaveCombFiller}
                              onChange={handleChange}
                            >
                              <option value="Yes"> Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                          {/* Form Group (last name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              Left Handed Tile Per Linear Meter&nbsp;
                            </label>
                            <input
                              className="form-control"
                              id="leftHandedTilePLM"
                              type="number"
                              value={formData.leftHandedTilePLM}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="row gx-3 mb-3">
                          {/* Form Group (first name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputLastName"
                            >
                              Left Hand Tile Cost &nbsp;
                            </label>
                            <input
                              className="form-control"
                              id="leftHandedTileCost"
                              type="number"
                              value={formData.leftHandedTileCost}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="col-md-6">
                            <label className="small mb-1">Ridge Required</label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              id="ridgeRequired"
                              value={formData.ridgeRequired}
                              onChange={handleChange}
                            >
                              <option disabled>Select a Ridge:</option>
                              <option value="Concrete">Concrete</option>
                              <option value="Clay">Clay</option>
                            </select>
                          </div>
                        </div>
                        {formData.tileTypeId == 1 && (
                          <div className="row gx-3 mb-3">
                            <div className="col-md-12">
                              <label
                                className="small mb-1"
                                htmlFor="inputFirstName"
                              >
                                Cost Per Slate & Half&nbsp;
                              </label>
                              <input
                                className="form-control"
                                id="costPerSlatenHalf"
                                type="text"
                                value={formData.costPerSlatenHalf}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        )}
                        {formData.tileTypeId == 2 && (
                          <div className="row gx-3 mb-3">
                            {/* Form Group (first name)*/}

                            {/* Form Group (last name)*/}
                            <div className="col-md-12">
                              <label
                                className="small mb-1"
                                htmlFor="inputLastName"
                              >
                                Eave Tiles Per LM&nbsp;
                              </label>
                              <input
                                className="form-control"
                                id="eaveTilesPerLM"
                                type="number"
                                value={formData.eaveTilesPerLM}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        )}
                        {formData.tileTypeId == 2 && (
                          <div className="row gx-3 mb-3">
                            {/* Form Group (first name)*/}
                            <div className="col-md-6">
                              <label
                                className="small mb-1"
                                htmlFor="inputFirstName"
                              >
                                Cost Per Eave Tile&nbsp;
                              </label>
                              <input
                                className="form-control"
                                id="costPerEaveTile"
                                type="number"
                                value={formData.costPerEaveTile}
                                onChange={handleChange}
                              />
                            </div>
                            {/* Form Group (last name)*/}
                            <div className="col-md-6">
                              <label
                                className="small mb-1"
                                htmlFor="inputLastName"
                              >
                                Valley Tiles Per LM&nbsp;
                              </label>
                              <input
                                className="form-control"
                                id="valleyTilesPerLM"
                                type="number"
                                value={formData.valleyTilesPerLM}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        )}
                        {formData.tileTypeId == 2 && (
                          <div className="row gx-3 mb-3">
                            {/* Form Group (first name)*/}
                            <div className="col-md-6">
                              <label
                                className="small mb-1"
                                htmlFor="inputFirstName"
                              >
                                Cost Per Valley Tile&nbsp;
                              </label>
                              <input
                                className="form-control"
                                id="costPerValleyTile"
                                type="number"
                                value={formData.costPerValleyTile}
                                onChange={handleChange}
                              />
                            </div>
                            {/* Form Group (last name)*/}
                            <div className="col-md-6">
                              <label
                                className="small mb-1"
                                htmlFor="inputLastName"
                              >
                                Cost Per Tile & Half&nbsp;
                              </label>
                              <input
                                className="form-control"
                                id="costPerTilenHalf"
                                type="number"
                                value={formData.costPerTilenHalf}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        )}
                        {formData.tileTypeId == 2 && (
                          <div className="row gx-3 mb-3">
                            {/* Form Group (first name)*/}
                            <div className="col-md-6">
                              <label
                                className="small mb-1"
                                htmlFor="inputFirstName"
                              >
                                Bonnets Per LM&nbsp;
                              </label>
                              <input
                                className="form-control"
                                id="bonnetsPerLM"
                                type="number"
                                value={formData.bonnetsPerLM}
                                onChange={handleChange}
                              />
                            </div>
                            {/* Form Group (last name)*/}
                            <div className="col-md-6">
                              <label
                                className="small mb-1"
                                htmlFor="inputLastName"
                              >
                                Cost Per Bonnet&nbsp;
                              </label>
                              <input
                                className="form-control"
                                id="costPerBonnet"
                                type="number"
                                value={formData.costPerBonnet}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        )}
                        {formData.tileTypeId == 2 && (
                          <div className="row gx-3 mb-3">
                            {/* Form Group (first name)*/}
                            <div className="col-md-6">
                              <label
                                className="small mb-1"
                                htmlFor="inputFirstName"
                              >
                                Tile & Half Per LM&nbsp;
                              </label>
                              <input
                                className="form-control"
                                id="tilenHalfPerLM"
                                type="text"
                                value={formData.tilenHalfPerLM}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        )}

                        <button className="btn btn-primary" type="submit">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <footer className="footer-admin mt-auto footer-light">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">
                  Copyright © Roof Pal {currentYear}
                </div>
                <div className="col-md-6 text-md-end small"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default EditTile;
