import React, { useEffect, useState } from "react";
import logo from "../assets/img/logo/logo2.png";
import { useNavigate } from "react-router-dom";
const Navbar = (props) => {
  const navigate = useNavigate();

  // const [userData, setUserData] = useState(null);

  // useEffect(() => {
  //   // Retrieve data from local storage when the component mounts
  //   const storedUserData = localStorage.getItem('userData');

  //   if (storedUserData) {
  //     // Parse the JSON string to get back the original object
  //     const parsedUserData = JSON.parse(storedUserData);

  //     // Set the user data in the component state
  //     setUserData(parsedUserData);
  //   } else {
  //     console.log('No user data found in local storage.');
  //   }
  // }, []);
  // const handleLinkClick = (props) => {
  //   // Display an alert message when the link is clicked
  //   //window.alert('"Stay Tuned – Page Under Construction"');
  //   navigate('/under-construction');

  // };

  return (
    <nav
      className="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white"
      id="sidenavAccordion"
    >
      {/* Sidenav Toggle Button */}
      <button
        className="btn btn-icon btn-trsidebarToggleansparent-dark order-1 order-lg-0 me-2 ms-lg-2 me-lg-0"
        id="sidebarToggle"
        onClick={props.toggleMobileMenu}
      >
        <i className="fas fa-bars" />{" "}
        {/* Use the appropriate Font Awesome class for the hamburger menu */}
      </button>

      {/* Navbar Brand */}
      <a
        className="navbar-brand pe-3 ps-4 ps-lg-2"
        onClick={() => navigate("/Home")}
        style={{ cursor: "pointer" }}
      >
        <img
          src={logo}
          alt="logo"
          style={{
            width: "17rem",
            height: "5rem",
            // marginTop: "-85px",
            objectFit: "cover",
            marginLeft: "-20px",
          }}
        />
      </a>

      {/* Navbar Items */}
      <ul
        className={`navbar-nav align-items-center ms-auto ${
          props.isMobileMenuOpen ? "show" : ""
        }`}
      >
        {/* Documentation Dropdown */}
        <li className="nav-item dropdown no-caret d-none d-md-block me-3">
          {/* Dropdown Content */}
        </li>
        {/* Add more navbar items as needed */}
      </ul>
    </nav>
  );
};

export default Navbar;
