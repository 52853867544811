import React from 'react'
import pic from '../assets/img/backgrounds/Counstruction.jpg';
// import '../assets/css/UnderConstruction.css';
import '../construction/UnderConstruction.css';

const UnderConstruction = (props) => {
  return (
    <div className="under-construction-container">
   
    </div>
  );
};

export default UnderConstruction
