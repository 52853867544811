import "../PagesCss/Default.css";
import React, { Profiler, useState, useEffect } from "react";
import SideBar from "./SideBar";
import axios from "axios";
import "../construction/UnderConstruction.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Navbar from "./Navbar";
const Rates = (props) => {
  const [formData, setFormData] = useState({
    userId: JSON.parse(localStorage.getItem("userData")).userId || 0,
  });
  console.log(formData);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/rates/averagerates/${
            JSON.parse(localStorage.getItem("userData")).userId
          }`
        );
        console.log("API Response:", response.data);
        response.data[0].userId = JSON.parse(
          localStorage.getItem("userData")
        ).userId;
        setFormData(response.data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
        NotificationManager.info("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: Number(value),
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/rates/`,
        formData
      );
      NotificationManager.info("Rates Amended");
      console.log("API Response:", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      NotificationManager.info("Error submitting data:", error);
    }
  };
  const currentYear = new Date().getFullYear();
  return (
    <>
      <Navbar toggleMobileMenu={props.toggleMobileMenu} />
      <div id="layoutSidenav">
        <SideBar
          setMobileMenuOpen={props.setMobileMenuOpen}
          isMobileMenuOpen={props.isMobileMenuOpen}
        />

        <div id="layoutSidenav_content">
          <main>
            <header
              className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10"
              id="dashMobile2"
            >
              <div className="container-xl px-4">
                <div className="page-header-content pt-4">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-auto mt-4">
                      <h1 className="page-header-title" />
                      <div className="page-header-icon">
                        <h1 className="page-header-title">
                          Set your rates&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp;
                        </h1>
                      </div>
                      <div className="page-header-subtitle">
                        Break costs into Linear and<br></br> Square Metres. If
                        you are unsure <br></br>what rates to set, try our
                        current<br></br> averages as a starting point.<br></br>{" "}
                        You can adjust rates whenever<br></br> required.
                      </div>
                    </div>
                  </div>
                  <nav className="mt-4 rounded" aria-label="breadcrumb">
                    <ol className="breadcrumb px-3 py-2 rounded mb-0">
                      <li className="breadcrumb-item">
                        <a>Home</a>
                      </li>
                      <li className="breadcrumb-item active">Set your rates</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </header>
            <>
              {/* {formData.bigTileNew && ( */}
              <div className="container-xl px-4 mt-n10">
                <div className="row">
                  {/* Solid Form Controls*/}
                  <div className="solid">
                    <div className="card mb-4">
                      <div className="card-header" id="leftspace">
                        Square Metre Works
                      </div>
                      <div className="card-body">
                        <form onSubmit={handleSubmit}>
                          {/* Form Row*/}
                          <div className="row gx-3">
                            <h6 className="fw-800">
                              Big Tiles Area (including Felt, Batten and
                              Laying).
                            </h6>
                            <div className="col-md-6">
                              {/* Form Group (first name)*/}
                              <div className="mb-3">
                                <label
                                  className="small mb-1"
                                  htmlFor="inputFirstName"
                                >
                                  New Work&nbsp;
                                </label>{" "}
                                <input
                                  className="form-control"
                                  id="bigTileNew"
                                  type="text"
                                  value={formData.bigTileNew}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              {/* Form Group (last name)*/}
                              <div className="mb-3">
                                <label
                                  className="small mb-1"
                                  htmlFor="inputLastName"
                                >
                                  Strip &amp; Re-New&nbsp;
                                </label>{" "}
                                <input
                                  className="form-control"
                                  id="bigTileRenew"
                                  type="text"
                                  value={formData.bigTileRenew}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          {/* Form Row*/}
                          <div className="row gx-3">
                            <h6 className="fw-800">
                              Small Tile Area (including Felt, Batten and
                              Laying).
                            </h6>
                            <div className="col-md-6">
                              {/* Form Group (first name)*/}
                              <div className="mb-3">
                                <label
                                  className="small mb-1"
                                  htmlFor="inputFirstName"
                                >
                                  New Work&nbsp;
                                </label>{" "}
                                <input
                                  className="form-control"
                                  id="smallTileNew"
                                  type="text"
                                  value={formData.smallTileNew}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              {/* Form Group (last name)*/}
                              <div className="mb-3">
                                <label
                                  className="small mb-1"
                                  htmlFor="inputLastName"
                                >
                                  Strip &amp; Re-New&nbsp;
                                </label>{" "}
                                <input
                                  className="form-control"
                                  id="smallTileRenew"
                                  type="text"
                                  value={formData.smallTileRenew}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          {/* Form Row*/}
                          <div className="row gx-3">
                            <h6 className="fw-800">
                              Slate Area (including Felt, Batten and Laying).
                            </h6>
                            <div className="col-md-6">
                              {/* Form Group (first name)*/}
                              <div className="mb-3">
                                <label
                                  className="small mb-1"
                                  htmlFor="inputFirstName"
                                >
                                  New Work&nbsp;
                                </label>{" "}
                                <input
                                  className="form-control"
                                  id="slateNew"
                                  type="text"
                                  value={formData.slateNew}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              {/* Form Group (last name)*/}
                              <div className="mb-3">
                                <label
                                  className="small mb-1"
                                  htmlFor="inputLastName"
                                >
                                  Strip &amp; Re-New&nbsp;
                                </label>{" "}
                                <input
                                  className="form-control"
                                  id="slateRenew"
                                  type="text"
                                  value={formData.slateRenew}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          {/* Form Row*/}
                          <div className="row gx-3">
                            <h6 className="fw-800">Box Gutters</h6>
                            <div className="col-md-6">
                              {/* Form Group (first name)*/}
                              <div className="mb-3">
                                <label
                                  className="small mb-1"
                                  htmlFor="inputFirstName"
                                >
                                  New Work&nbsp;
                                </label>{" "}
                                <input
                                  className="form-control"
                                  id="boxGutter"
                                  type="text"
                                  value={formData.boxGutter}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              {/* Form Group (last name)*/}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <hr />
                  {/* Solid Form Controls*/}
                  <div className="solid2">
                    <div className="card mb-4">
                      <div className="card-header" id="leftspace">
                        Linear Metres Works
                      </div>
                      <div className="card-body">
                        <form onSubmit={handleSubmit}>
                          {/* Form Row*/}
                          <div className="row gx-3">
                            <h6 className="fw-800">Hips</h6>
                            <div className="col-md-12">
                              {/* Form Group (first name)*/}
                              <div className="mb-3">
                                <label
                                  className="small mb-1"
                                  htmlFor="inputFirstName"
                                >
                                  Cutting, Fixing &amp; Laying
                                </label>{" "}
                                <input
                                  className="form-control"
                                  id="hips"
                                  type="text"
                                  value={formData.hips}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          {/* Form Row*/}
                          <div className="row gx-3">
                            <h6 className="fw-800">Ridge</h6>
                            <div className="col-md-12">
                              {/* Form Group (first name)*/}
                              <div className="mb-3">
                                <label
                                  className="small mb-1"
                                  htmlFor="inputFirstName"
                                >
                                  Cutting, Fixing &amp; Laying
                                </label>{" "}
                                <input
                                  className="form-control"
                                  id="ridge"
                                  type="text"
                                  value={formData.ridge}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          {/* Form Row*/}
                          <div className="row gx-3">
                            <h6 className="fw-800">Valleys</h6>
                            <div className="col-md-12">
                              {/* Form Group (first name)*/}
                              <div className="mb-3">
                                <label
                                  className="small mb-1"
                                  htmlFor="inputFirstName"
                                >
                                  Cutting, Fixing &amp; Laying
                                </label>{" "}
                                <input
                                  className="form-control"
                                  id="valleys"
                                  type="text"
                                  value={formData.valleys}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          {/* Form Row*/}
                          <div className="row gx-3">
                            <h6 className="fw-800">Verge</h6>
                            <div className="col-md-12">
                              {/* Form Group (first name)*/}
                              <div className="mb-3">
                                <label
                                  className="small mb-1"
                                  htmlFor="inputFirstName"
                                >
                                  Cutting, Fixing &amp; Laying
                                </label>{" "}
                                <input
                                  className="form-control"
                                  id="verge"
                                  type="text"
                                  value={formData.verge}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          {/* Form Row*/}
                          <div className="row gx-3">
                            <h6 className="fw-800">Leadwork</h6>
                            <div className="col-md-12">
                              {/* Form Group (first name)*/}
                              <div className="mb-3">
                                <label
                                  className="small mb-1"
                                  htmlFor="inputFirstName"
                                >
                                  Including Step &amp; Cover Flashing
                                </label>{" "}
                                <input
                                  className="form-control"
                                  id="leadWork"
                                  type="text"
                                  value={formData.leadWork}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <hr />
                  {/* Solid Form Controls*/}
                  <div className="solid3">
                    <div className="card mb-4">
                      <div className="card-header" id="leftspace">
                        Individual Item Works
                      </div>
                      <div className="card-body">
                        <form onSubmit={handleSubmit}>
                          {/* Form Row*/}
                          <div className="row gx-3">
                            <h6 className="fw-800">Roof Window</h6>
                            <div className="col-md-12">
                              {/* Form Group (first name)*/}
                              <div className="mb-3">
                                <input
                                  className="form-control"
                                  id="roofWindow"
                                  type="text"
                                  value={formData.roofWindow}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          {/* Form Row*/}
                          <div className="row gx-3">
                            <h6 className="fw-800">Lead Slate</h6>
                            <div className="col-md-12">
                              {/* Form Group (first name)*/}
                              <div className="mb-3">
                                <input
                                  className="form-control"
                                  id="leadSlate"
                                  type="text"
                                  value={formData.leadSlate}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          {/* Form Row*/}
                          <div className="row gx-3">
                            <h6 className="fw-800">Vent Tiles</h6>
                            <div className="col-md-12">
                              {/* Form Group (first name)*/}
                              <div className="mb-3">
                                <input
                                  className="form-control"
                                  id="ventTiles"
                                  type="text"
                                  value={formData.ventTiles}
                                  onChange={handleChange}
                                />
                              </div>
                              <button className="btn btn-primary" type="submit">
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </main>
          <footer className="footer-admin mt-auto footer-light">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">
                  Copyright © Roof Pal {currentYear}
                </div>
                <div className="col-md-6 text-md-end small"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Rates;
