import "../PagesCss/Default.css";
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
const TileSuggestion = (props) => {
  const [formData, setFormData] = useState({
    suggestion: "",
  });
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form data:", formData);
    formData.userName = JSON.parse(localStorage.getItem("userData")).firstName;
    try {
      // Make a POST request to your backend API
      // const response = await axios.post('http://localhost:8801/api/nails/add', formData);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/suggestion/add`,
        formData
      );
      setSuccessMessage("Data submitted successfully!");
      // Handle the response, e.g., show a success message
      console.log("Server response:", response.data);

      // Optionally, you can reset the form data after a successful submission
      setFormData({
        suggestion: "",
      });
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error("Error submitting data:", error.message);
    }
  };
  const currentYear = new Date().getFullYear();
  return (
    <>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="description" content="" />
      <meta name="author" content="" />
      <title>Dashboard</title>
      <link
        href="https://cdn.jsdelivr.net/npm/simple-datatables@latest/dist/style.css"
        rel="stylesheet"
      />
      <link href="css/styles.css" rel="stylesheet" />
      <link rel="icon" type="image/x-icon" href="assets/img/favicon.png" />
      <Navbar toggleMobileMenu={props.toggleMobileMenu} />
      <div id="layoutSidenav">
        <SideBar
          setMobileMenuOpen={props.setMobileMenuOpen}
          isMobileMenuOpen={props.isMobileMenuOpen}
        />

        <div id="layoutSidenav_content">
          <main>
            <header className="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
              <div className="container-xl px-4">
                <div className="page-header-content">
                  <div className="row align-items-center justify-content-between pt-3">
                    <div className="col-auto mb-3">
                      <ol className="breadcrumb px-3 py-2 rounded mb-0">
                        <li className="breadcrumb-item">
                          <a href="dashboard.html">Home</a>
                        </li>
                        <li className="breadcrumb-item active">
                          Tile Suggestion
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            {/* Main page content*/}
            {/* Main page content*/}
            <div className="container-xl px-4 mt-4">
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              <div className="container-xl">
                <div className="card">
                  <div className="card-header">Tile Suggestion</div>
                  <div className="card-body">
                    <p>
                      Any tiles missing from our site? enter the name(s) of them
                      below and we will look to add them.
                    </p>
                    <form onSubmit={handleSubmit}>
                      {/* Form Group comments            */}
                      <div className="mb-3">
                        <label
                          className="small mb-1"
                          htmlFor="exampleFormControlTextarea1"
                        >
                          Suggested Tiles
                        </label>
                        <textarea
                          className="form-control"
                          id="suggestion"
                          rows={5}
                          value={formData.suggestion}
                          onChange={handleChange}
                        />
                      </div>
                      <button className="btn btn-primary" type="submit">
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <footer className="footer-admin mt-auto footer-light">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">
                  Copyright © Roof Pal {currentYear}
                </div>
                <div className="col-md-6 text-md-end small"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};
export default TileSuggestion;
