import React from 'react'
import UnderConstruction from './UnderConstruction'

const DummyHome = (props) => {
  return (
    <div>
      <UnderConstruction/>
    </div>
  )
}

export default DummyHome
