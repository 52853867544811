import "../PagesCss/Default.css";
import React, { useState } from "react";
import favicon from "../assets/img/favicon.png";
import SideBar from "./SideBar";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Navbar from "./Navbar";
const EditUser = (props) => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    password: "",
    email: "",
    Role: "",
  });
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState(null);
  console.log("Editformdata:", formData);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/get/${id}`
        );
        console.log("API Response:", response);

        const userData = response.data;
        console.log("user Data :", userData);

        if (userData) {
          // Assuming the response structure is { nailSize, costPerUnit }
          setFormData(userData.data);
        } else {
          console.error("Invalid response or empty data.");
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data:", formData);
    setFormData({
      firstName: "",
      lastName: "",
      password: "",
      email: "",
      Role: "",
    });
    setTimeout(() => {
      setSuccessMessage(null);
    }, 3000);
    try {
      // Assuming you have the correct endpoint for updating data
      const response = axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/put`,
        formData
      );
      setSuccessMessage("Data Updated successfully!");
      console.log("Update Response:", response);

      // You can handle the response as needed
      // For example, you can show a success message or navigate back to the list page
    } catch (error) {
      console.error("Error updating data:", error.message);
      // You can handle errors, for example, display an error message to the user
    }
  };
  const currentYear = new Date().getFullYear();
  return (
    <>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="description" content="" />
      <meta name="author" content="" />
      <title>Users List</title>
      <link
        href="https://cdn.jsdelivr.net/npm/simple-datatables@latest/dist/style.css"
        rel="stylesheet"
      />
      <link href="css/styles.css" rel="stylesheet" />
      <link rel="icon" type="image/x-icon" href="assets/img/favicon.png" />
      <Navbar toggleMobileMenu={props.toggleMobileMenu} />
      <div id="layoutSidenav">
        <SideBar
          setMobileMenuOpen={props.setMobileMenuOpen}
          isMobileMenuOpen={props.isMobileMenuOpen}
        />

        <div id="layoutSidenav_content">
          <main>
            <header className="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
              <div className="container-xl px-4">
                <div className="page-header-content">
                  <div className="row align-items-center justify-content-between pt-3">
                    <div className="col-auto mb-3">
                      <h1 className="page-header-title">
                        <div className="page-header-icon">
                          <i data-feather="user" />
                        </div>
                        Edit User
                      </h1>
                    </div>
                    <div className="col-12 col-xl-auto mb-3">
                      <a
                        onClick={() => {
                          navigate("/users");
                        }}
                        className="btn btn-sm btn-light text-primary"
                      >
                        <i className="me-1" data-feather="arrow-left" />
                        Back to Users List
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            {/* Main page content*/}
            <div className="container-xl px-4 mt-4">
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              <div className="row">
                <div className="col-xl-12">
                  {/* Account details card*/}
                  <div className="card mb-4">
                    <div className="card-header">Account Details</div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        {/* Form Row*/}
                        <div className="row gx-3 mb-3">
                          {/* Form Group (first name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              First name
                            </label>
                            <input
                              className="form-control"
                              id="firstName"
                              type="text"
                              placeholder="Enter your first name"
                              value={formData.firstName}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          {/* Form Group (last name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputLastName"
                            >
                              Last name
                            </label>
                            <input
                              className="form-control"
                              id="lastName"
                              type="text"
                              placeholder="Enter your last name"
                              value={formData.lastName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          {/* Form Group (first name)*/}
                          <div className="row gx-3 mb-3">
                            <div className="col-md-6">
                              <label
                                className="small mb-1"
                                htmlFor="inputFirstName"
                              >
                                Password
                              </label>
                              <input
                                className="form-control"
                                id="password"
                                type="password"
                                placeholder="Enter your first name"
                                value={formData.password}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="col-md-6">
                              <label
                                className="small mb-1"
                                htmlFor="inputFirstName"
                              >
                                Phone
                              </label>
                              <input
                                className="form-control"
                                id="phone"
                                type="text"
                                placeholder="Enter phone"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                              />
                            </div>{" "}
                          </div>
                        </div>
                        {/* Form Group (email address)*/}
                        <div className="mb-3">
                          <label
                            className="small mb-1"
                            htmlFor="inputEmailAddress"
                          >
                            Email address
                          </label>
                          <input
                            className="form-control"
                            id="email"
                            type="email"
                            placeholder="Enter your email address"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        {/* Form Group (Group Selection Checkboxes)*/}
                        {/* Form Group (Roles)*/}
                        <div className="mb-3">
                          <label className="small mb-1">Role</label>
                          <select
                            required
                            className="form-select"
                            aria-label="Default select example"
                            id="role"
                            value={formData.role}
                            onChange={handleChange}
                          >
                            <option disabled>Select a role:</option>
                            <option value="administrator">Administrator</option>
                            <option value="supplier">Supplier</option>
                            <option value="user">User</option>
                          </select>
                        </div>
                        {/* Submit button*/}
                        <button className="btn btn-primary" type="submit">
                          Save changes
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <footer className="footer-admin mt-auto footer-light">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">
                  Copyright © Roof Pal {currentYear}
                </div>
                <div className="col-md-6 text-md-end small"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default EditUser;
