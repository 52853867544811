// import logo from './logo.svg';
import "./App.css";
import React, { useEffect, useState } from "react";
import Rates from "./Pages/Rates";
import { NotificationContainer } from "react-notifications";

import Nail from "./Pages/Nail";
import logo from "./logo.svg";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
// import './App.css';
import Quote from "./Pages/Quote";
import Login from "./Pages/Login";
import AddNails from "./Pages/AddNails";
import Register from "./Pages/Register";
import Dashboard from "./Pages/Dashboard";
import Contact from "./Pages/Contact";
import { ToastContainer } from "react-toastify";
import EditNail from "./Pages/EditNail";
import AddMaterials from "./Pages/AddMaterials";
import AddUser from "./Pages/AddUser";
import Material from "./Pages/Materials";
import Settings from "./Pages/Settings";
import EditMaterials from "./Pages/EditMaterials";
import TileSuggestion from "./Pages/TileSuggestion";
import User from "./Pages/User";
import UnderConstruction from "./Pages/UnderConstruction";
import DummyHome from "./Pages/DummyHome";
import EditUser from "./Pages/EditUser";
import Navbar from "./Pages/Navbar";
import AddTile from "./Pages/AddTile";
import Tiles from "./Pages/Tiles";
import EditTile from "./Pages/EditTile";
import Profile from "./Pages/profile";
import Security from "./Pages/Security";
import VerificationCode from "./Pages/VerificationCode";
import PasswordRecovery from "./Pages/PasswordRecovery";
import NewPassword from "./Pages/NewPassword";
import Admindashboard from "./Pages/Admindashboard";
import MaterialQoute from "./Pages/MaterialQoute";
// import Routes from "./Pages/Routes";
import "react-notifications/lib/notifications.css";
import SavedQuotes from "./Pages/SavedQuotes";
import Layout from "./Pages/Layout";
// import { Security } from "@mui/icons-material";
// import ForgotPassword from "./Pages/ForgotPassword";
function App() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  console.log("isMobileMenuOpen", isMobileMenuOpen);
  const toggleMobileMenu = (props) => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const isMobileScreen = window.innerWidth <= 768;

    // Add a class to the body based on whether it's a mobile screen
    if (isMobileMenuOpen === true) {
      document.body.classList.add("sidenav-toggled");
    } else {
      document.body.classList.remove("sidenav-toggled");
    }

    // return () => {
    //   // Clean up if needed
    //   // For example, remove the class when the component is unmounted
    //   if (isMobileScreen) {
    //     document.body.classList.remove('sidenav-toggled');
    //   }
    // };
  }, [isMobileMenuOpen]); // Empty dependency array means this effect runs once after the initial render

  return (
    <>
      {" "}
      <NotificationContainer />
      <div>
        <BrowserRouter>
          <Layout>
            <Routes>
              {" "}
              <Route
                path="/register"
                element={
                  <Register
                    setMobileMenuOpen={setMobileMenuOpen}
                    isMobileMenuOpen={isMobileMenuOpen}
                    toggleMobileMenu={toggleMobileMenu}
                  />
                }
              />
              <Route
                path="/login"
                element={
                  <Login
                    setMobileMenuOpen={setMobileMenuOpen}
                    isMobileMenuOpen={isMobileMenuOpen}
                    toggleMobileMenu={toggleMobileMenu}
                  />
                }
              />
              <Route
                path="/PasswordRecovery"
                element={
                  <PasswordRecovery
                    setMobileMenuOpen={setMobileMenuOpen}
                    isMobileMenuOpen={isMobileMenuOpen}
                    toggleMobileMenu={toggleMobileMenu}
                  />
                }
              />
              <Route
                path="/VerificationCode"
                element={
                  <VerificationCode
                    setMobileMenuOpen={setMobileMenuOpen}
                    isMobileMenuOpen={isMobileMenuOpen}
                    toggleMobileMenu={toggleMobileMenu}
                  />
                }
              />
              <Route
                path="/NewPassword"
                element={
                  <NewPassword
                    setMobileMenuOpen={setMobileMenuOpen}
                    isMobileMenuOpen={isMobileMenuOpen}
                    toggleMobileMenu={toggleMobileMenu}
                  />
                }
              />
              {/* <header> */}
              <>
                {JSON.parse(localStorage.getItem("userData")) && (
                  // {true && (
                  <>
                    <Route
                      path="/customerquotes"
                      element={
                        <SavedQuotes
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/home"
                      element={
                        <Dashboard
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/nails"
                      element={
                        <Nail
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/AddNails"
                      element={
                        <AddNails
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/contactus"
                      element={
                        <Contact
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />

                    <Route
                      path="/EditNail/:id"
                      element={
                        <EditNail
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/AddMaterials"
                      element={
                        <AddMaterials
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/materials"
                      element={
                        <Material
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/Settings"
                      element={
                        <Settings
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/EditMaterials/:id"
                      element={
                        <EditMaterials
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/tilesuggestion"
                      element={
                        <TileSuggestion
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/AddUser"
                      element={
                        <AddUser
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/Users"
                      element={
                        <User
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/homes"
                      element={
                        <DummyHome
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/materialquotes"
                      element={
                        <MaterialQoute
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />

                    <Route
                      path="/under-construction"
                      element={
                        <UnderConstruction
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/EditUser/:id"
                      element={
                        <EditUser
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/Navbar"
                      element={
                        <Navbar
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    {/* <Route path="/forgotpassword" element={<ForgotPassword setMobileMenuOpen={setMobileMenuOpen}  isMobileMenuOpen={isMobileMenuOpen} toggleMobileMenu={toggleMobileMenu}  />} /> */}
                    <Route
                      path="/setyourrates"
                      element={
                        <Rates
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/createaquote"
                      element={
                        <Quote
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/tiles"
                      element={
                        <Tiles
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/AddTile"
                      element={
                        <AddTile
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/EditTile/:id"
                      element={
                        <EditTile
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/manageaccount"
                      element={
                        <Profile
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/Security"
                      element={
                        <Security
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                    <Route
                      path="/Admindashboard"
                      element={
                        <Admindashboard
                          setMobileMenuOpen={setMobileMenuOpen}
                          isMobileMenuOpen={isMobileMenuOpen}
                          toggleMobileMenu={toggleMobileMenu}
                        />
                      }
                    />
                  </>
                )}
                <Route
                  path="*"
                  element={
                    <Navigate
                      to="/login"
                      replace
                      setMobileMenuOpen={setMobileMenuOpen}
                      isMobileMenuOpen={isMobileMenuOpen}
                      toggleMobileMenu={toggleMobileMenu}
                    />
                  }
                />
              </>
            </Routes>
          </Layout>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
